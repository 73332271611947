import React from 'react'
import { Typography ,Grid} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

const clientPrograms = () => {
  return (
    <div>clientPrograms</div>
  )
}
const containerStyle = {
    maxHeight: 500, // Set the maximum height as needed
    overflowY: 'auto',
    padding: '16px',  
  };

  const codeStyle = {
    fontFamily: 'monospace',
    whiteSpace: 'pre-wrap',
    color:'lightgreen'
  };
const Client1Prog=()=>{
  const isMobile = useMediaQuery('(max-width:600px)');
    const transmiter=`
#include <Arduino.h>
#include <SPI.h>
#include <DallasTemperature.h>
#include <OneWire.h>
#include <LoRa.h>
#include <SoftwareSerial.h>
#include <LoRa.h>
#include <ArduinoJson.h>
#include <TaskScheduler.h>
#include <PZEM004Tv30.h>
#include <HardwareSerial.h>
#include <EEPROM.h>
#define PZEM_RX_PIN 10
#define PZEM_TX_PIN 11

#define ONE_WIRE_BUS 4

SoftwareSerial gsmSerial(12, 13); // RX, TX pins for the SIM900 module
//SoftwareSerial pzemSerial(PZEM_RX_PIN, PZEM_TX_PIN);
PZEM004Tv30 pzem(Serial1);
OneWire oneWire(ONE_WIRE_BUS);
DallasTemperature sensors(&oneWire);

int counter = 0;
int trigPin = 5;    // Trigger
int echoPin = 6;    // Echo
long duration, cm, inches;
int Scr_1=25;
int Scr_2=26;
int GsmPower=27;
//init variable
bool IssendMsg=false;
float Voltage;
float Current;
float temperature;
float oilVolume=100;
int BuzzStatus=0;
float CurrentLvl;
int voltageLvl=240;
float TempLvl;
float VolumeLvl;
int addr_status=0;
int addr_buzzer=1;
int addr_voltage=3;
int addr_current=4;
int addr_temp=5;
int addr_volume=6;

String bossNum="";
int minVoltagePercentageLvl_1=1;
int maxVoltagePercentageLvl_1=1.05;
int minVoltagePercentageLvl_2=1.05;
int maxVoltagePercentageLvl_2=1.10;
int maxVoltagePercentageLvl_3=1.10;
int minCurrentPercentageLvl_1=1;
int maxCurrentPercentageLvl_1=1.05;
int minCurrentPercentageLvl_2=1.05;
int maxCurrentPercentageLvl_2=1.10;
int maxCurrentPercentageLvl_3=1.10;

String recievedNumber="";
String ArrayNumber[10];
String message="TestNumber";
String textMessage;
int recordedStartindexPhoneNum=10;
int addr_phoneNum;
bool isAllDigit;
String State="Home";
//Initializing Funtion
 void SendMessage();
void checkBalance();
void labelSensor(); //Function init
void tempSensor();
void LoRaSendMsg();
int readVariableFromEEPROM(int address);
void Pzemread();
void SendArrMsg();
void TripSCR(String Scr_state);
void MakeTrue(){
  IssendMsg=true;
}
void SetValue(String Rmessage);
bool isNumber(const String& str) ;
bool isNotNumber(const String& str) ;
////////////////////////////
Task sendLora(1000,TASK_FOREVER,&LoRaSendMsg);
//Task READZEM(100,TASK_FOREVER,&Pzemread);
Task IssendingMesage(15000,TASK_FOREVER,&MakeTrue);
Scheduler runner;
//Define Pin

//_____________________________________________
void setup() {
  Serial.begin(9600);
  gsmSerial.begin(9600);
  pinMode(GsmPower,OUTPUT);
  
  digitalWrite(GsmPower,HIGH);
  delay(1000);
  digitalWrite(GsmPower,LOW);
  //pzemSerial.begin(9600);
  EEPROM.begin();
  sensors.begin();
  //Define inputs and outputs
  pinMode(trigPin, OUTPUT);
  pinMode(echoPin, INPUT);
  pinMode(Scr_1,OUTPUT);
  pinMode(Scr_2,OUTPUT);
 
  //begin temperature
  // if (pzem.voltage()<voltageLvl)
  // {
    digitalWrite(Scr_1,HIGH);
    digitalWrite(Scr_2,HIGH);
  // }
  
  if (!LoRa.begin(433E6)) {
    Serial.println("Starting LoRa failed!");
    while (1);
  }
  // Initialize the SIM900 module
  delay(1000);
  gsmSerial.print("AT+CMGF=1\r"); 
  delay(100);
  // Set module to send SMS data to serial out upon receipt 
  gsmSerial.print("AT+CNMI=2,2,0,0,0\r");

runner.addTask(sendLora);
//runner.addTask(READZEM);
runner.addTask(IssendingMesage);
sendLora.enable();
//READZEM.enable();
IssendingMesage.enable();
  delay(1000);
  gsmSerial.println("AT+CMGD=1,4");
  while (gsmSerial.available()) {
    char c = gsmSerial.read();
    Serial.print(c);
  }
  Allcommand();
  ReadDatas();
}

void loop() {
  
  if (Serial.available()>0)
   switch(Serial.read())
  {
    case 's':
      SendMessage();
      break;
   
  }
  if(gsmSerial.available()>0){
    textMessage =gsmSerial.readString();
    Serial.print(textMessage);
     //char convertedInt[20];
    //String Rmessage="";
    // Extract sender number from SMS notification
    if (textMessage.indexOf("+CMT:") >= 0) {
      recievedNumber = textMessage.substring(startIndex, endIndex);
      Serial.print("Sender Number: ");
      Serial.println(recievedNumber);
      //GetThe message
      int messageStartIndex = textMessage.indexOf("\n", endIndex) + 1;
      String Rmessage = textMessage.substring(messageStartIndex);
      Serial.print("Message: ");
      Serial.println(Rmessage);
      Rmessage.trim();
      //Rmessage.toCharArray(convertedInt, Rmessage.length() + 1);
      Serial.println(State); 
     SetValue(Rmessage);
      
      
    }  
  }else if(textMessage.indexOf("SVT")>=0){
    // Turn on relay and save current state
    State="Set_Voltage_Level";
    Serial.println(State);  
    message="NOTICE:\nVoltage Threshold must not exceed Over 280V! \n\n Please Enter Threshold Voltage Value Only:\n (eg. 240)";
    SendMessage(); 
    return; 
  }
  else if(textMessage.indexOf("SCT")>=0){
    // Turn on relay and save current state
    State="Set_Current_Level";
    Serial.println(State);  
    message="NOTICE:\nCurrent Threshold must not exceed Over 100 A! \n\nPlease Enter Threshold Current Value Only:\n(eg. 20)";
    SendMessage();  
    return; 
  }
 else if(textMessage.indexOf("STT")>=0){
    // Turn on relay and save current state
    State="Set_Temperature_Level";
    Serial.println(State);  
    message="NOTICE:\nPlease Enter Temperature Threshold:";
    SendMessage();
    return;   
  }
 else if(textMessage.indexOf("SOT")>=0){
    // Turn on relay and save current state
    State="Set_Oil_Level";
    Serial.println(State);  
    message="NOTICE:\nPlease Enter Oil Threshold:";
    SendMessage();  
    return; 
  }
 else  if(textMessage.indexOf("Bal")>=0){
    Serial.println("CheckBal");
    checkBalance();
    return; 
   }
 else  if(textMessage.indexOf("DFT")>=0){
    ///defaukt value here
    return; 
   }
  else if (textMessage.indexOf("PNUM")>=0)
  {
    State="Add_Phone_Number";
    Serial.println("pass on num");
    return; 
  }
  else if(textMessage.indexOf("ON")>=0){
       digitalWrite(Scr_1,HIGH);
       digitalWrite(Scr_2,HIGH);
      message="NOTICE:\nSCR IS ACTIVATED.";
      SendMessage();
      return;
  }
 
  
   
  Pzemread();
  labelSensor();
  tempSensor();
  runner.execute();
}

String number="+639152575489";
 void SendMessage()
{
  Serial.println ("Sending Message");
  gsmSerial.println("AT+CMGF=1");
  delay(1000);
  number.trim();
  Serial.println ("Set SMS Number");
  
  delay(1000);
  Serial.print(number);
  delay(500);
  Serial.println ("Set SMS Content");
  gsmSerial.print(message);
  delay(100);
  Serial.println ("Finish");
  gsmSerial.println((char)26);
  delay(1000);
  Serial.println ("Message has been sent");
   delay(100);
      gsmSerial.println("AT+CMGD=1,4");
  //IssendMsg=true;
}
void SendArrMsg(){
  for (unsigned  i = 0; i < sizeof(ArrayNumber); i++)
  {
    number=ArrayNumber[i];
    Serial.println ("Sending Message");
    gsmSerial.println("AT+CMGF=1");
    delay(100);
    number.trim();
    Serial.println ("Set SMS Number");
   
    delay(100);
    Serial.print(number);
    delay(500);
    Serial.println ("Set SMS Content");
    gsmSerial.print(message);
    delay(100);
    Serial.println ("Finish");
    gsmSerial.println((char)26);
    delay(1000);
    Serial.println ("Message has been sent");
    IssendMsg=true;
     delay(1000);
  }
  
}
float txOilLvlConst=15;
void labelSensor(){
  digitalWrite(trigPin, LOW);
  delayMicroseconds(5);
  digitalWrite(trigPin, HIGH);
  delayMicroseconds(10);
  digitalWrite(trigPin, LOW);
 
  pinMode(echoPin, INPUT);
  duration = pulseIn(echoPin, HIGH);
 
  // Convert the time into a distance
  cm = (duration/2) / 29.1;     // Divide by 29.1 or multiply by 0.0343
  inches = (duration/2) / 74;   // Divide by 74 or multiply by 0.0135
  oilVolume=txOilLvlConst-inches;
  //Serial.print(inches);
  // Serial.print("in, ");
  // Serial.print(cm);
  // Serial.print("cm");
  // Serial.println();

}
void tempSensor(){
  sensors.requestTemperatures(); 
  // Serial.print("Celsius temperature: ");
  // Why "byIndex"? You can have more than one IC on the same bus. 0 refers to the first IC on the wire
  temperature=sensors.getTempCByIndex(0);
  // Serial.print(sensors.getTempCByIndex(0)); 
  // Serial.print(" - Fahrenheit temperature: ");
  // Serial.println(sensors.getTempFByIndex(0));
  
}
//put to task scheuler
float Readvoltage;
float Readcurrent;
void Pzemread(){
 
    float Readvoltage = pzem.voltage();
    float Readcurrent = pzem.current();
    Voltage=Readvoltage;
    Current=Readcurrent ;
    Serial.println(Readvoltage);
   
      Serial.println("Pass voltage");
      if (200>voltageLvl*minVoltagePercentageLvl_1&&Voltage<voltageLvl*maxVoltagePercentageLvl_1)
      {
        
        String alert="Alert Level 1:\n Voltage Of Transformer is at"+String(Voltage) +"           "+"\nVoltage rating:"+String(voltageLvl);
        if (IssendMsg)
        {
           message=alert;
           SendMessage();
        }
        
      }if (Voltage>voltageLvl*minVoltagePercentageLvl_2&&Voltage<voltageLvl*maxVoltagePercentageLvl_2)
      {
        
        String alert="Alert Level 2: \nVoltage Of Transformer is at"+String(Voltage) +"           "+"\nVoltage rating:"+String(voltageLvl);
        if (IssendMsg)
        {
           message=alert;
           SendMessage();
        }
        
      }if (Voltage>voltageLvl*maxVoltagePercentageLvl_3)
      {
        TripSCR("Off");
        String alert="Alert Level 3:\n STATUS:\nTRIP OFF ACTIVATED \nVoltage Of Transformer is at"+ String(Voltage) +"           "+"Voltage rating:"+String(voltageLvl);
        if (IssendMsg)
        {
           message=alert;
           SendMessage();
        }
        
      }
      
   
      Current=Readcurrent;
      //Level 1s
      if (Current>CurrentLvl*minCurrentPercentageLvl_1&&Current<CurrentLvl*maxCurrentPercentageLvl_1){
        String alert="Alert Level 1: Current Of Transformer is at"+String(Current) +"           "+"Current rating:"+String(CurrentLvl);
        message=alert;
        SendMessage();
      
      }if (Current>CurrentLvl*minCurrentPercentageLvl_2&&Current<CurrentLvl*maxCurrentPercentageLvl_2)
      {
        String alert="Alert Level 2: Current Of Transformer is at"+String(Current) +"           "+"Current rating:"+String(CurrentLvl);
        message=alert;
        SendMessage();
      }if (Current>CurrentLvl*maxCurrentPercentageLvl_3)
      {
        TripSCR("Off");
        String alert="Alert Level 3:TRIP OFF Current Of Transformer is at Critical Level"+String(Current) +"           "+"Current rating:"+String(CurrentLvl);
        message=alert;
        SendMessage();
      } 
 
}
void initSaveData(){
  voltageLvl=readVariableFromEEPROM(addr_voltage);
  CurrentLvl=readVariableFromEEPROM(addr_current);
  TempLvl=readVariableFromEEPROM(addr_temp);
  VolumeLvl=readVariableFromEEPROM(addr_volume);
}
int readVariableFromEEPROM(int address) {
  int readValue;
  EEPROM.get(address, readValue); // Read the variable from the specified EEPROM address
  return readValue;
}
//to initialize phone number on the array
void readDataPhoneNumber(){
  for (int i = 9; i < recordedStartindexPhoneNum; i++)
  {
    ArrayNumber[i]=readVariableFromEEPROM(i);
  }
  
}

bool IsPhoneNum(String Rmessage){
    char Converted[12];
    Rmessage.toCharArray(Converted,Rmessage.length()+1);
    if ( Converted[0]=='+')
        {
          int count=0;
          String msgToCheck="";
          for (unsigned int i = 1; i < 13; i++)
              {   
                  char backC=Converted[i];
                  msgToCheck=msgToCheck+String(backC);
                  msgToCheck.trim();
                  count+=1;
                 if (count==12)
                    {
                      if (isNumber(msgToCheck))
                          {
                            Serial.println("Pass On Here. msgcheck");
                          }
                  }else{
                    return false;
                  } 
              }
          
              if(count!=12){     
                    return false;
              }
        }
        else{
              return false;
        }
        return true;
}
int RecordedLastAddr[] = {21,34,47,60,73,86,99,112,125,138,151};
void SavePhoneNum(String Rmessage,int Addr){
    char Converted[15];
    Rmessage.toCharArray(Converted,13);
    int inr=RecordedLastAddr[Addr-1];
    for (unsigned int i = 1; i < Rmessage.length()+1; i++)
              {  
                //EEPROM.write(inr,Converted[i]);
                inr+=1;
              }
    message="NOTICE: \n The Phone Number:"+ Rmessage +" is Stored in Phonebook # " + String(Addr);
    SendMessage();  
}
void LoRaSendMsg(){
  StaticJsonDocument <50> doc;
  doc["V"]=Voltage;
  doc["C"]=Current;
  doc["T"]=temperature;
  doc["O"]=inches;
  doc["B"]=BuzzStatus;
  String LoRaMsg;
  serializeJson(doc,LoRaMsg);
  //String myData = String(temperature) + " Degrees" + String(Voltage) + "% Humid " +    String(Current) + " Feet";
  //Serial.print("Sending packet: ");
  LoRa.beginPacket();
  LoRa.print(LoRaMsg);
  //Serial.println(LoRaMsg);
  //LoRa.print(counter);
  LoRa.endPacket();
  //counter++;
  delay(2000);
  doc.clear();

}

bool isNumber(const String& str) {
    for (char c : str) {
        if (!isdigit(c)) {
            return false;
        }
    }
    return true;
}
bool isNotNumber(const String& str) {
    for (char c : str) {
        if (isdigit(c)) {
            return false;
        }
    }
    return true;
}
void SetValue(String Rmessage){
   if (State=="Set_Voltage_Level")
    {  State="Home";
        Serial.print("Pass On Set voltage mssagecheck");
        if (isNumber(Rmessage))
        {

          voltageLvl=Rmessage.toInt();
          Serial.println(String(voltageLvl)+" V");
          //EEPROM.write(1,voltageLvl);
          message="NOTICE:\nVoltage Threshold was set to:\n "+ String(voltageLvl)+" V";
          char Converted[12];
          Rmessage.toCharArray(Converted,Rmessage.length()+1);
          for(unsigned int i = 0; i < Rmessage.length()+1; i++){
                //EEPROM.write(i,Converted[i]);
              }

          SendMessage();
          return;
        }
        // else if(isNotNumber(Rmessage))
        // {
        //message="Notice:Please Dont Put an alphabet when setting a value";
          //SendMessage();
        //Serial.println("pass on Errror");
        //}  
    }

     if (State=="Set_Current_Level")
    {
      State="Home";
      Serial.print("Pass On Set Current mssagecheck");
       if (isNumber(Rmessage))
        {
          CurrentLvl=Rmessage.toInt();
          Serial.println(String(CurrentLvl)+" A");
          //EEPROM.write(2,CurrentLvl);
          message="NOTICE:\nCurrent Threshold was set to:\n "+ String(CurrentLvl)+" A";
          char Converted[12];
          Rmessage.toCharArray(Converted,Rmessage.length()+1);
          for(unsigned int i = 3; i < Rmessage.length()+1; i++){
                //EEPROM.write(i,Converted[i]);
              }
          SendMessage();
          return;
        }
        // message="Notice:Please Dont Put an alphabet when setting a value";
        // SendMessage();
        // Serial.println("pass on Errror");
    }  if (State=="Set_Temperature_Level")
    {
      State="Home";
      Serial.print("Pass On Set Temperature mssagecheck");
       if (isNumber(Rmessage))
        {
          TempLvl=Rmessage.toInt();
          Serial.println(String(TempLvl)+" V");
          //EEPROM.write(3,TempLvl);
          message="NOTICE:\nCurrent Threshold was set to:\n "+ String(CurrentLvl)+" A";
           char Converted[12];
          Rmessage.toCharArray(Converted,Rmessage.length()+1);
          for(unsigned int i = 6; i < Rmessage.length()+1; i++){
                //EEPROM.write(i,Converted[i]);
              }
          SendMessage();
          return;
        }
        // message="Notice:Please Dont Put an alphabet when setting a value";
        // SendMessage();
        // Serial.println("pass on Errror");
    }
   if (State=="Set_Oil_Level")
    {
      State="Home";
      Serial.print("Pass On Set Oil mssagecheck");
       if (isNumber(Rmessage))
        {
          VolumeLvl=Rmessage.toInt();
          Serial.println(String(VolumeLvl)+" V");
          //EEPROM.write(4,VolumeLvl);
          message="NOTICE:\nOil Level Threshold was set to:\n "+ String(VolumeLvl)+" A";
           char Converted[12];
          Rmessage.toCharArray(Converted,Rmessage.length()+1);
          for(unsigned int i = 9; i < Rmessage.length()+1; i++){
                //EEPROM.write(i,Converted[i]);
              }
          SendMessage();
          return;
        }
        // message="Notice:Please Dont Put an alphabet when setting a value";
        // SendMessage();
        // Serial.println("pass on Errror");
    }
      if (State=="Add_Phone_Number")
    {
      State="Home";
      Serial.print("Pass On Phone add mssagecheck");
       if (IsPhoneNum(Rmessage))
        { 
          String addr_st;
          Serial.println(String(Rmessage));
          char Converted[16];
          Rmessage.toCharArray(Converted,Rmessage.length()+1);
          addr_st=addr_st + Converted[14];
          int addr_int=addr_st.toInt();
          if (addr_int>0&&addr_int<11)
          {
            SavePhoneNum(Rmessage,addr_int);
          }
          
          //SavePhoneNum(Rmessage,Converted[14]);
         
        }
        // message="Notice:Please Dont Put an alphabet when setting a value";
        // SendMessage();
        // Serial.println("pass on Errror");
    }
    
}
void TripSCR(String Scr_state){
  if (Scr_state=="ON")
  {
    digitalWrite(Scr_1,HIGH);
    digitalWrite(Scr_2,HIGH); 
  }else
  {
    digitalWrite(Scr_1,LOW);
    digitalWrite(Scr_2,LOW); 
  }    
}
void checkBalance(){
    
}
void Allcommand(){
  message="NOTICE:\n";
  message+="PLEASE SET THE FOLLOWING PARAMETERS IF NOT SET:\nVOLTAGE LEVEL\nCURRENT LEVEL\nTEMPERATURE LEVEL \nOIL LEVEL\n";
  message+="Available Commands\n";
  message+="SVT = to set voltage threshold\n";
  message+="SCT = to set current threshold\n";
  message+="STT =  to set Temperature threshold\n";
  message+="SOT = to set oil level threshold";
  message+="PNUM = add a phone number\n";
  message +="DFT = set the default value of Voltage Level to 240V\n, Current Level to 50A, Oil Level to 50%,Temperature to 30C";
  SendMessage();
}
void ReadDatas(){
  String readStringdata;
  for(int i = 0; i < 3; i++){
      char reads= EEPROM.read(i);
      readStringdata=readStringdata+String(reads);
  }
  voltageLvl=readStringdata.toInt();
  readStringdata="";
  for(int i = 3; i < 3; i++){
      char reads= EEPROM.read(i);
      readStringdata=readStringdata+String(reads);
  }
  CurrentLvl=readStringdata.toInt();
  readStringdata="";
  for(int i = 6; i < 3; i++){
      char reads= EEPROM.read(i);
      readStringdata=readStringdata+String(reads);
  }
  TempLvl=readStringdata.toInt();
  readStringdata="";
   for(int i = 9; i < 3; i++){
      char reads= EEPROM.read(i);
      readStringdata=readStringdata+String(reads);
  }
  oilVolume=readStringdata.toInt();
  readStringdata="";
}
    `;
    const reciever=`
    #include <SPI.h>
    #include <LoRa.h>
    #include <Wire.h>
    #include <ArduinoJson.h>
    #include <LiquidCrystal_I2C.h>
    LiquidCrystal_I2C lcd(0x27,20,4);
    float voltage;
    float current;
    float volume;
    float temperature;
    float buzzStatus;
    bool LoadStatus=0;
    int buzzer = 8;
    //int button = 6;
    int pinstatus;
    const byte interruptPin = 3;
    void button() { 
      Serial.println("Stats");
         if(buzzStatus==1){
              digitalWrite(7,LOW);
              buzzStatus=0;
              
          }
      
      }
    void setup() {
      lcd.init();                      // initialize the lcd
      lcd.init();
      Serial.begin(9600);
      lcd.backlight();
      lcd.setCursor(1,0);
      lcd.print("Connecting to LoRa");
      //while (!Serial);
      Serial.println("LoRa Receiver");
      pinMode(interruptPin, INPUT_PULLUP);
      attachInterrupt(digitalPinToInterrupt(interruptPin), button,FALLING );
      if (!LoRa.begin(433E6)) {
        //Serial.println("Starting LoRa failed!");
        lcd.clear();
        lcd.setCursor(3,0);
        lcd.print("Failed!!");
        while (1);
       
       
      }
      lcd.clear();
      lcd.setCursor(3,0);
      lcd.print("connected");
      pinMode(7, OUTPUT);
      pinMode(button,INPUT);
      digitalWrite(7,LOW);
     
    }
    
    
    void loop() {
      // try to parse packetSerial.println(".");
      int packetSize = LoRa.parsePacket();
      if (packetSize) {
        while (LoRa.available()) {
         
          String LoRaMsg=LoRa.readString();
          StaticJsonDocument<200> doc;
          Serial.print(LoRaMsg);
          deserializeJson(doc,LoRaMsg);
           DeserializationError error = deserializeJson(doc, LoRaMsg);
    
    
        // Check for parsing errors
        if (error) {
          Serial.print("Parsing failed: ");
          Serial.println(error.c_str());
          return;
              }
         
          voltage=doc["V"];
          current=doc["C"];
          volume=doc["O"];
          temperature=doc["T"];
          buzzStatus=doc["B"];
          LoadStatus=doc["L"];
          lcd.clear();
          lcd.setCursor(0,0);
          lcd.print("V:"+String(voltage));
          lcd.setCursor(10,0);
          lcd.print("C:"+String(current));
          lcd.setCursor(0,2);
          lcd.print("T:"+String(temperature));
          lcd.setCursor(10,2);
          lcd.print("V:"+String(volume));
          lcd.setCursor(0,3);
          if(LoadStatus==0){
            lcd.print("Load:Active");
          }else{
            lcd.print("Load:Expired");
          }
          if(buzzStatus==1){
              digitalWrite(7,HIGH);
          }if(buzzStatus==0){
             digitalWrite(7,LOW);
          }
        }
      }
     
     // Serial.println(digitalRead(button));
      //pinstatus=digitalRead(button);
    
    }
    `;
return(
    <Grid container spacing={5}   columns={isMobile?8:16}>
        <Grid xs={8}>
            <Grid style={containerStyle}>
                <Typography variant='h4'>Transmitter</Typography>
                <br/>
                <Typography>
                          <code style={codeStyle}>{transmiter}</code>
                     </Typography>
            </Grid>
                
        </Grid>
        <Grid xs={8}>
            <Grid style={containerStyle}>
                        <Typography variant='h4'>RECIEVER</Typography>
                        <br/>
                        <Typography>
                        <code style={codeStyle}>{reciever}</code>
                        </Typography>
            </Grid>
        </Grid>
   
    </Grid>

)

}

const Client2Prog=()=>{
  const isMobile = useMediaQuery('(max-width:600px)');
  const program=`
  #include <Arduino.h>
  #include <SoftwareSerial.h>
  #include <HardwareSerial.h>
  #include <PZEM004Tv30.h>
  #include <EEPROM.h>
  #include <AltSoftSerial.h>
  
  
  #if !defined(PZEM_RX_PIN) && !defined(PZEM_TX_PIN)
  #define PZEM_RX_PIN 12
  #define PZEM_TX_PIN 13
  #endif
  
  
  SoftwareSerial pzemSWSerial(PZEM_RX_PIN, PZEM_TX_PIN);
  PZEM004Tv30 pzem(pzemSWSerial);
  //Var  Set Up
  String black="+639454511985";////Programmers number for checking 
  String MobileNumber="+639945408556";
  String State;
  float OverVoltageThreshold;// n/a
  
  /////__________EDI HERE YOUR
  float OverCurrentThreshold=10;// set short circuit here
  float OverPowerThreshold=500;//Set power here
  
  
  
  int GsmPowerPin=10; // put Pin here
  
  int Addr_OverVoltage=0;
  int Addr_Overcurrent=10;
  int Addr_OverCurrent_char_Count=1;
  int Addr_OverPower=20;
  int Addr_Location_Char_Count=31;
  int Addr_Location=40;
  int overPowerCuurent=10;
  unsigned long SendingInterval=5000;
  unsigned long previousMillisSending = 0;
  int recorded_Loc_Char_count;
  bool IsMessage=true;//oVERvOLTAGE
  bool IsMessage2=true;//sHORT
  bool IsMessage3=true;//OverlOAD
  bool IsMessage4=true;//Open
  bool IsMessageOverCurrent=false;
  bool IsMsgOverLoad=false;
  bool IsMsgOverVoltage=false;
  String DeviceLocation="CIT BACK GATE";
  String MsgTobeSend;
  float  pzemVoltage ;
  float pzemCurrent ;
  float pzemPower ;
  bool pzemActivate=true;
  //SoftwareSerial gsmSerial(7, 8 );//subjected to change
  AltSoftSerial gsmSerial;
  //functionInit
  void SendMessage(String message ,  String  number);
  int CharCount(const String& str);
  void ReadData();
  void Pzem();
  bool CheckForFloat(String str);
  float ConvertFloat(String str);
  void PzemRead();
  bool voltageTrig=false;
  //Set Up
  void setup() {
    //Init Serial
    Serial.begin(9600);
    gsmSerial.begin(9600);
  
    
    //Init Gsm power
    pinMode(GsmPowerPin,OUTPUT);
    digitalWrite(GsmPowerPin,HIGH);
    delay(1000);
    digitalWrite(GsmPowerPin,LOW);
    
   
    // Initialize the SIM900 module
    delay(4000);
    gsmSerial.print("AT+CMGF=1\r"); 
    delay(1000);
    // Set module to send SMS data to serial out upon receipt 
    gsmSerial.print("AT+CNMI=2,2,0,0,0\r");
    delay(1000);
    gsmSerial.println("AT+CMGD=1,4");
    while (gsmSerial.available()) {
      char c = gsmSerial.read();
      Serial.print(c);
    }
    //ReadData();
    //EEPROM.begin();// for memory
    delay(1000);
   
  }
  
  void loop() {
     if(gsmSerial.available()>0){
      String textMessage;
      String recievedNumber;
      textMessage =gsmSerial.readString();
      Serial.print(textMessage);
     
       //char convertedInt[20];
      //String Rmessage="";
      // Extract sender number from SMS notification
      if (textMessage.indexOf("+CMT:") >= 0) {
        
       
        recievedNumber = textMessage.substring(startIndex, endIndex);
       // Serial.print("Sender Number: ");
        ////Serial.println(recievedNumber);
        //GetThe message
        int messageStartIndex = textMessage.indexOf("\n", endIndex) + 1;
        String Rmessage = textMessage.substring(messageStartIndex);
        //Serial.print("Message: ");
        //Serial.println(Rmessage);
        Rmessage.trim();
        ///Rmessage.toCharArray(convertedInt, Rmessage.length() + 1);
        //Serial.println(State); 
        //SetValue(Rmessage);
        
        
        if (State=="Set_Device_Location")
        {
           State="Home";
          //EEPROM.write(Addr_Location_Char_Count,CharCount(Rmessage));//record how many character on the string 
           Serial.println(CharCount(Rmessage));
          
             char Converted[12];
            Rmessage.toCharArray(Converted,Rmessage.length()+1);
            DeviceLocation=Rmessage;
            SendMessage("NOTICE:\nDevice Location Was Set To:"+ DeviceLocation,MobileNumber);
        }if (State=="Set_Device_Current")
        {
          State="Home";
          if (CheckForFloat(Rmessage))
          {
  
            OverCurrentThreshold=ConvertFloat(Rmessage);
            //EEPROM.write(Addr_OverCurrent_char_Count,CharCount(Rmessage));//record how many character on the string 
             char Converted[12];
            Rmessage.toCharArray(Converted,Rmessage.length()+1);
            SendMessage("NOTICE:\nThe Current Threshold is Set To:"+String(OverCurrentThreshold),MobileNumber);
          }else
          {
            SendMessage("NOTICE:\nPlease Provide A Value.",MobileNumber);
          }
        }if (State=="Set_Device_Power")
        {
          State="Home";
          if (CheckForFloat(Rmessage))
          {
            OverPowerThreshold=ConvertFloat(Rmessage);
            char Converted[12];
            Rmessage.toCharArray(Converted,Rmessage.length()+1);  
            SendMessage("NOTICE:\nThe POWER Threshold is Set To:"+String(OverPowerThreshold),MobileNumber);
          }else
          {
            SendMessage("NOTICE:\nPlease Provide A Value.",MobileNumber);
          }
        }
      
        
        
      }if (textMessage.indexOf("SDL") >= overPowerCuurent)
      {
        pzemActivate=false;
        State="Set_Device_Location";
        Serial.println(State);
        SendMessage("NOTICE:\nPlease Specify The Location Of the Device",MobileNumber);
      }if (textMessage.indexOf("SDC") >= 0)
      {
        State="Set_Device_Current";
        Serial.println(State);
        SendMessage("NOTICE:\nPlease Specify The Current Threshold",MobileNumber);
      }
      if (textMessage.indexOf("SDP") >= 0)
      {
        State="Set_Device_Power";
        Serial.println(State);
        SendMessage("NOTICE:\nPlease Specify The Power Threshold",MobileNumber);
      }
      if(textMessage.indexOf("SHORT") >= 0){
        OverCurrentThreshold=3;
        OverPowerThreshold=1500;
  
      } 
      if(textMessage.indexOf("OVERLOAD") >= 0){
        OverCurrentThreshold=10;
        OverPowerThreshold=500;
  
      } 
       if(textMessage.indexOf("codes") >= 0){
        MobileNumber=black;
  
      } 
      
     
      
      
    }
   
    if (pzemActivate)
    {
      PzemRead();
    }
    
  
    
  }
  void SendMessage(String message ,  String  number)
  {
    Serial.println ("Sending Message");
    gsmSerial.println("AT+CMGF=1");
    delay(1000);
    number.trim();
    Serial.println ("Set SMS Number");
    
    delay(1000);
    Serial.print(number);
    delay(1000);
    Serial.println ("Set SMS Content");
    gsmSerial.print(message);
    delay(1000);
    Serial.println ("Finish");
    gsmSerial.println((char)26);
    delay(1000);
    Serial.println ("Message has been sent");
    delay(2000);
    pzemActivate=true;
    
    //IssendMsg=true;
  }
  void PzemRead(){
  
        pzemCurrent = pzem.current();
        pzemPower = pzem.power();
        pzemVoltage = pzem.voltage();
            if (pzemCurrent>OverCurrentThreshold)
                {
                  if (IsMessage2)
                  {
                    pzemActivate=false;
                    float toSendVoltage=pzemVoltage;
                    float toSendCurrent = pzemCurrent;
                    float toSendPower = pzemPower;
                    String  Send="ALERT: \nSHORT CIRCUIT DETECTED \nLocation: " + DeviceLocation+"\n\nReadings:\n"+"Voltage: "+String(toSendVoltage)+"\nCurrent: "+String(toSendCurrent) +"\nPower: "+toSendPower;
                    SendMessage(Send,MobileNumber);
                    IsMessage2=false;
                    IsMessageOverCurrent=true;
                  }  
                }if(pzemCurrent<OverCurrentThreshold)
                {
                  if (IsMessage2==false)
                  {
                   IsMessage2=true;
                  }
                }
            if (pzemPower>OverPowerThreshold &&pzemCurrent<OverCurrentThreshold)
                {
                    if (IsMessage3)
                    {
                    float toSendVoltage=pzemVoltage;
                    float toSendCurrent = pzemCurrent;
                    float toSendPower = pzemPower;
                    String  Send="ALERT: \n OVERLOAD DETECTED \nLocation: " + DeviceLocation+"\n\nReadings:\n"+"Voltage: "+String(toSendVoltage)+"\nCurrent: "+String(toSendCurrent) +"\nPower: "+toSendPower;
                    
                    SendMessage(Send,MobileNumber);
                    IsMessage3=false;
                     
                    }
                  }if(pzemPower<OverPowerThreshold)
                  {
                      if (IsMessage3==false)
                      {
                        IsMessage3=true;
                       }
                  }   
            if(pzemCurrent<0.007){
                if (IsMessage4)
                    {
                    String  Send="ALERT: \nOPEN CIRCUIT was Detected at "  + DeviceLocation;
                      SendMessage(Send,MobileNumber);
                      IsMessage4=false;
       
                    }
            }if (pzemCurrent>0.009)
            {
             if (IsMessage4==false)
             {
                IsMessage4=true;
             }
             
            }
            
            
           
            }
  
  
  int CharCount(const String& str) {
      int i;
      for (char c : str) {
         i++;
      }
      return i;
  }
  
  void ReadData(){
     String readStringdata;
    //reading the Character Count first
    recorded_Loc_Char_count=EEPROM.read(Addr_Location_Char_Count);//for the number of char
    //Init the memory for the location
    for (int i = Addr_Location; i < recorded_Loc_Char_count; i++)
    {
      char reads= EEPROM.read(i);
      readStringdata=readStringdata+String(reads);
  
    }
    DeviceLocation=readStringdata;
    readStringdata="";
  
  }
  bool CheckForFloat(String str){
     float floatValue = str.toFloat();
    // Check if the conversion to float is successful
    if (floatValue != 0.0 || str.equals("0")) {
      Serial.print("String can be converted to float. Float Value: ");
      Serial.println(floatValue, 2); // Print the float value with 2 decimal places
      return true;
    } else {
        
        return false;
    }
  }
  float ConvertFloat(String str){
    float floatValue = str.toFloat();
    // Check if the conversion to float is successful
    if (floatValue != 0.0 || str.equals("0")) {
      Serial.print("String can be converted to float. Float Value: ");
      Serial.println(floatValue, 2); // Print the float value with 2 decimal places
      return floatValue;
    } 
    //else {
    //     SendMessage("NOTICE:\nNot A Valid Value\nPlease Provide a Number.",MobileNumber);
  
    // }
   
  }
  
  void MsgState(){
    if (IsMessageOverCurrent)
    {
      IsMessage=false;
    }
    if (IsMsgOverLoad)
    {
      IsMsgOverLoad=false;
    }
    if (IsMessage)
    {
      IsMessage=false;
    }
    
    
    
  }
  
  `;


  return(
    <Grid container spacing={5}   columns={isMobile?8:16} >
  <Grid xs={8}>
      <Grid style={containerStyle}>
     
          <br/>
          <Typography>
                    <code style={codeStyle}>{program}</code>
            </Typography>
      </Grid>
          
  </Grid>
    

</Grid>
  )

}
const Client3Prog=()=>{
  const isMobile = useMediaQuery('(max-width:600px)');
    const Learning=`%env PYTHONPATH = # /env/python

    !wget https://repo.anaconda.com/miniconda/Miniconda3-py39_23.3.1-0-Linux-x86_64.sh
    !chmod +x Miniconda3-py39_23.3.1-0-Linux-x86_64.sh
    !./Miniconda3-py39_23.3.1-0-Linux-x86_64.sh -b -f -p /usr/local
    !conda update conda
    
    
    
    import sys
    sys.path.append('/usr/local/lib/python3.9/site-packages')
    
    
    
    !conda create -n myenv python=3.9
    
    
    
    %%shell
    eval "$(conda shell.bash hook)"
    conda activate myenv
    pip install tflite-model-maker
    
    
    %%shell
    eval "$(conda shell.bash hook)"
    conda activate myenv
    pip install ipykernel
    
    
    
    %%shell
    eval "$(conda shell.bash hook)"
    conda activate myenv
    pip install opencv-python
    python --version
    
    
    
    %%shell
    eval "$(conda shell.bash hook)"
    conda activate myenv
    pip install numpy==1.23.4
    
    %%shell
    eval "$(conda shell.bash hook)"
    conda activate myenv
    pip install pycocotools
    
    from google.colab import drive
    drive.mount('/content/gdrive')
    !ln -s /content/gdrive/My Drive/ /mydrive
    !ls /mydrive
    
    
    
    !unzip /mydrive/freedomtech.zip
    
    
    
    %%shell
    eval "$(conda shell.bash hook)"
    conda activate myenv
    python /content/train.py
    `;
    const detect=`# Copyright 2023 The MediaPipe Authors. All Rights Reserved.
    #
    # Licensed under the Apache License, Version 2.0 (the "License");
    # you may not use this file except in compliance with the License.
    # You may obtain a copy of the License at
    #
    #     http://www.apache.org/licenses/LICENSE-2.0
    #
    # Unless required by applicable law or agreed to in writing, software
    # distributed under the License is distributed on an "AS IS" BASIS,
    # WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
    # See the License for the specific language governing permissions and
    # limitations under the License.
    """Main scripts to run object detection."""
    
    import argparse
    import sys
    import time
    
    import cv2
    import mediapipe as mp
    
    from mediapipe.tasks import python
    from mediapipe.tasks.python import vision
    
    from utils import visualize
    
    # Global variables to calculate FPS
    COUNTER, FPS = 0, 0
    START_TIME = time.time()
    
    
    def run(model: str, max_results: int, score_threshold: float, 
            camera_id: int, width: int, height: int) -> None:
      """Continuously run inference on images acquired from the camera.
    
      Args:
        model: Name of the TFLite object detection model.
        max_results: Max number of detection results.
        score_threshold: The score threshold of detection results.
        camera_id: The camera id to be passed to OpenCV.
        width: The width of the frame captured from the camera.
        height: The height of the frame captured from the camera.
      """
    
      # Start capturing video input from the camera
      cap = cv2.VideoCapture(0)
      cap.set(cv2.CAP_PROP_FRAME_WIDTH, width)
      cap.set(cv2.CAP_PROP_FRAME_HEIGHT, height)
    
      # Visualization parameters
      row_size = 50  # pixels
      left_margin = 24  # pixels
      text_color = (0, 0, 0)  # black
      font_size = 1
      font_thickness = 1
      fps_avg_frame_count = 10
    
      detection_frame = None
      detection_result_list = []
    
      def save_result(result: vision.ObjectDetectorResult, unused_output_image: mp.Image, timestamp_ms: int):
          global FPS, COUNTER, START_TIME
    
          # Calculate the FPS
          if COUNTER % fps_avg_frame_count == 0:
              FPS = fps_avg_frame_count / (time.time() - START_TIME)
              START_TIME = time.time()
    
          detection_result_list.append(result)
          COUNTER += 1
    
      # Initialize the object detection model
      base_options = python.BaseOptions(model_asset_path=model)
      options = vision.ObjectDetectorOptions(base_options=base_options,
                                             running_mode=vision.RunningMode.LIVE_STREAM,
                                             max_results=max_results, score_threshold=score_threshold,
                                             result_callback=save_result)
      detector = vision.ObjectDetector.create_from_options(options)
    
    
      # Continuously capture images from the camera and run inference
      while cap.isOpened():
        success, image = cap.read()
        image=cv2.resize(image,(640,480))
        if not success:
          sys.exit(
              'ERROR: Unable to read from webcam. Please verify your webcam settings.'
          )
    
        image = cv2.flip(image, 1)
    
        # Convert the image from BGR to RGB as required by the TFLite model.
        rgb_image = cv2.cvtColor(image, cv2.COLOR_BGR2RGB)
        mp_image = mp.Image(image_format=mp.ImageFormat.SRGB, data=rgb_image)
    
        # Run object detection using the model.
        detector.detect_async(mp_image, time.time_ns() // 1_000_000)
    
        # Show the FPS
        fps_text = 'FPS = {:.1f}'.format(FPS)
        text_location = (left_margin, row_size)
        current_frame = image
        cv2.putText(current_frame, fps_text, text_location, cv2.FONT_HERSHEY_DUPLEX,
                    font_size, text_color, font_thickness, cv2.LINE_AA)
    
        if detection_result_list:
            # print(detection_result_list)
            current_frame = visualize(current_frame, detection_result_list[0])
            detection_frame = current_frame
            detection_result_list.clear()
    
        if detection_frame is not None:
            cv2.imshow('object_detection', detection_frame)
    
        # Stop the program if the ESC key is pressed.
        if cv2.waitKey(1) == 27:
          break
    
      detector.close()
      cap.release()
      cv2.destroyAllWindows()
    
    
    def main():
      parser = argparse.ArgumentParser(
          formatter_class=argparse.ArgumentDefaultsHelpFormatter)
      parser.add_argument(
          '--model',
          help='Path of the object detection model.',
          required=False,
    #      default='efficientdet_lite0.tflite')
          default='best.tflite')
      parser.add_argument(
          '--maxResults',
          help='Max number of detection results.',
          required=False,
          default=5)
      parser.add_argument(
          '--scoreThreshold',
          help='The score threshold of detection results.',
          required=False,
          type=float,
          default=0.25)
      # Finding the camera ID can be very reliant on platform-dependent methods. 
      # One common approach is to use the fact that camera IDs are usually indexed sequentially by the OS, starting from 0. 
      # Here, we use OpenCV and create a VideoCapture object for each potential ID with 'cap = cv2.VideoCapture(i)'.
      # If 'cap' is None or not 'cap.isOpened()', it indicates the camera ID is not available.
      parser.add_argument(
          '--cameraId', help='Id of camera.', required=False, type=int, default=0)
      parser.add_argument(
          '--frameWidth',
          help='Width of frame to capture from camera.',
          required=False,
          type=int,
          default=640)
      parser.add_argument(
          '--frameHeight',
          help='Height of frame to capture from camera.',
          required=False,
          type=int,
          default=480)
      args = parser.parse_args()
    
      run(args.model, int(args.maxResults),
          args.scoreThreshold, int(args.cameraId), args.frameWidth, args.frameHeight)
    
    
    if __name__ == '__main__':
      main()`;
  return(
    <Grid container spacing={5}  columns={isMobile?8:16} >
    <Grid xs={8}>
      <Grid style={containerStyle}>
      <Typography variant='h4'>ipyb training File</Typography>
          <br/>
          <Typography>
                    <code style={codeStyle}>{Learning}</code>
            </Typography>
      </Grid>
          
  </Grid>
  <Grid xs={8}>
      <Grid style={containerStyle}>
      <Typography variant='h4'>Detect.py</Typography>
          <br/>
          <Typography>
                    <code style={codeStyle}>{detect}</code>
            </Typography>
      </Grid>
          
  </Grid>
  

</Grid>
  )
}
const Client4Prog=()=>{
  const isMobile = useMediaQuery('(max-width:600px)');
  const esp32=`#include <ESP8266WiFi.h>
  #include "HX711.h"
  
  // --------------------------------------------------------------------------------------------
  // Your WiFi credentials.
  // Set password to "" for open networks.
  char ssid[] = "geerald";//wifi Name
  char pass[] = "geralddd";//Wifi Pasword.
  String GAS_ID = "AKfycbx8mLvp6JhJ7JQWKIwmv7WQFyp5fIEdy99lPCZZPcHoHmjmtv3zsPLY6sXM62wVF_8f"; //--> spreadsheet script ID
  
  //Your Domain name with URL path or IP address with path
  const char* host = "script.google.com"; // only google.com not https://google.com
  
  // ----------------------------------------------------------------------------------------------
  
  #define UPDATE_INTERVAL_HOUR  (1)///sa oras ni
  #define UPDATE_INTERVAL_MIN   (0)///sa minuto ni
  #define UPDATE_INTERVAL_SEC   (0)///sa sconds ni
  
  #define UPDATE_INTERVAL_MS    ( ((UPDATE_INTERVAL_HOUR*60*60) + (UPDATE_INTERVAL_MIN * 60) + UPDATE_INTERVAL_SEC ) * 1000 )
  bool motorState=false;
  
  // HX711 circuit wiring
  const int LOADCELL_DOUT_PIN = 12;
  const int LOADCELL_SCK_PIN = 13;
  float ave=0;  
  
  HX711 scale;
  int Motor=5;
  // ----------------------------------------------------------------------------------------------
  
  float buffer=50;
  // ----------------------------------------------------------------------------------------------
  void update_google_sheet()
  {
      Serial.print("connecting to ");
      Serial.println(host);
    
      // Use WiFiClient class to create TCP connections
      WiFiClientSecure client;
      const int httpPort = 443; // 80 is for HTTP / 443 is for HTTPS!
      
      client.setInsecure(); // this is the magical line that makes everything work
      
      if (!client.connect(host, httpPort)) { //works!
        Serial.println("connection failed");
        return;
      }
      scale.power_up();
      ave=scale.get_units(10);  
      //----------------------------------------Processing data and sending data
      String url = "/macros/s/" + GAS_ID + "/exec?data="+ave+"&status="+buffer;
     
      
      Serial.print("Requesting URL: ");
      Serial.println(url);
    
      // This will send the request to the server
      client.print(String("GET ") + url + " HTTP/1.1\r\n" +
                   "Host: " + host + "\r\n" + 
                   "Connection: close\r\n\r\n");
    while (client.connected()) {
    
    if (client.readStringUntil('\n')== "\r") {
      Serial.println("headers received");
     
      break;
    }
  }
      Serial.println();
      Serial.println("closing connection"); 
      Serial.print(ave);
      delay(1000);
     motorState=true;
      scale.power_down(); 
  }
  
  // ----------------------------------------------------------------------------------------------
  void setup()
  {
    // Debug console
    Serial.begin(115200);
  
    // Digital output pin
    pinMode(LED_BUILTIN, OUTPUT);
    
    pinMode(Motor,OUTPUT);
    digitalWrite(Motor, LOW);
    scale.begin(LOADCELL_DOUT_PIN, LOADCELL_SCK_PIN);
    scale.set_scale(821.5853);
    scale.tare();   
  //----------------------------------------Wait for connection
    Serial.print("Connecting");
    WiFi.begin(ssid, pass); //--> Connect to your WiFi router
    while (WiFi.status() != WL_CONNECTED) 
    {
      Serial.print(".");
      digitalWrite(LED_BUILTIN, LOW);
      delay(50);
      digitalWrite(LED_BUILTIN, HIGH);
      delay(50);
    }
  
  }
  
  // ----------------------------------------------------------------------------------------------
  unsigned long time_ms;
  unsigned long time_1000_ms_buf;
  unsigned long time_sheet_update_buf;
  unsigned long time_dif;
  
  void loop()
  {
    time_ms = millis();
    time_dif = time_ms - time_1000_ms_buf;
  
    // Read and print serial data every 1 sec
    if ( time_dif >= 1000 ) // 1sec 
    {
      digitalWrite(LED_BUILTIN, !digitalRead(LED_BUILTIN));
    }
  
    // Update data to google sheet in specific period
    time_ms = millis();
    time_dif = time_ms - time_sheet_update_buf;  
    if ( time_dif >= UPDATE_INTERVAL_MS ) // Specific period
    {
      
      time_sheet_update_buf = time_ms;
      update_google_sheet();
      
        
    }
    if(motorState){
      digitalWrite(Motor, HIGH);
      delay(15000);//in milis
      digitalWrite(Motor, LOW);
      motorState=false;
      delay(1000);
      scale.power_up();
      buffer=scale.get_units(10);
      delay(1000);
      scale.power_down(); 
    }
  }`;
  const esp32cam=`// Camera libraries
  #include "esp_camera.h"
  #include "soc/soc.h"
  #include "soc/rtc_cntl_reg.h"
  #include "driver/rtc_io.h"
   
  // MicroSD Libraries
  #include "FS.h"
  #include "SD_MMC.h"
   
  // Pin definitions for CAMERA_MODEL_AI_THINKER
  #define PWDN_GPIO_NUM     32
  #define RESET_GPIO_NUM    -1
  #define XCLK_GPIO_NUM      0
  #define SIOD_GPIO_NUM     26
  #define SIOC_GPIO_NUM     27
  #define Y9_GPIO_NUM       35
  #define Y8_GPIO_NUM       34
  #define Y7_GPIO_NUM       39
  #define Y6_GPIO_NUM       36
  #define Y5_GPIO_NUM       21
  #define Y4_GPIO_NUM       19
  #define Y3_GPIO_NUM       18
  #define Y2_GPIO_NUM        5
  #define VSYNC_GPIO_NUM    25
  #define HREF_GPIO_NUM     23
  #define PCLK_GPIO_NUM     22
   
  // Counter for picture number
  unsigned int pictureCount = 0;
   
  // Delay time in millieconds
  unsigned int delayTime = 10000;////10 sec here can be change depending on millis
   
   
  void configESPCamera() {
    // Configure Camera parameters
   
    // Object to store the camera configuration parameters
    camera_config_t config;
   
    config.ledc_channel = LEDC_CHANNEL_0;
    config.ledc_timer = LEDC_TIMER_0;
    config.pin_d0 = Y2_GPIO_NUM;
    config.pin_d1 = Y3_GPIO_NUM;
    config.pin_d2 = Y4_GPIO_NUM;
    config.pin_d3 = Y5_GPIO_NUM;
    config.pin_d4 = Y6_GPIO_NUM;
    config.pin_d5 = Y7_GPIO_NUM;
    config.pin_d6 = Y8_GPIO_NUM;
    config.pin_d7 = Y9_GPIO_NUM;
    config.pin_xclk = XCLK_GPIO_NUM;
    config.pin_pclk = PCLK_GPIO_NUM;
    config.pin_vsync = VSYNC_GPIO_NUM;
    config.pin_href = HREF_GPIO_NUM;
    config.pin_sscb_sda = SIOD_GPIO_NUM;
    config.pin_sscb_scl = SIOC_GPIO_NUM;
    config.pin_pwdn = PWDN_GPIO_NUM;
    config.pin_reset = RESET_GPIO_NUM;
    config.xclk_freq_hz = 20000000;
    config.pixel_format = PIXFORMAT_JPEG; // Choices are YUV422, GRAYSCALE, RGB565, JPEG
   
    // Select lower framesize if the camera doesn't support PSRAM
    if (psramFound()) {
      config.frame_size = FRAMESIZE_UXGA; // FRAMESIZE_ + QVGA|CIF|VGA|SVGA|XGA|SXGA|UXGA
      config.jpeg_quality = 10; //10-63 lower number means higher quality
      config.fb_count = 2;
    } else {
      config.frame_size = FRAMESIZE_SVGA;
      config.jpeg_quality = 12;
      config.fb_count = 1;
    }
   
    // Initialize the Camera
    esp_err_t err = esp_camera_init(&config);
    if (err != ESP_OK) {
      Serial.printf("Camera init failed with error 0x%x", err);
      return;
    }
   
    // Camera quality adjustments
    sensor_t * s = esp_camera_sensor_get();
   
    // BRIGHTNESS (-2 to 2)
    s->set_brightness(s, 0);
    // CONTRAST (-2 to 2)
    s->set_contrast(s, 0);
    // SATURATION (-2 to 2)
    s->set_saturation(s, 0);
    // SPECIAL EFFECTS (0 - No Effect, 1 - Negative, 2 - Grayscale, 3 - Red Tint, 4 - Green Tint, 5 - Blue Tint, 6 - Sepia)
    s->set_special_effect(s, 0);
    // WHITE BALANCE (0 = Disable , 1 = Enable)
    s->set_whitebal(s, 1);
    // AWB GAIN (0 = Disable , 1 = Enable)
    s->set_awb_gain(s, 1);
    // WB MODES (0 - Auto, 1 - Sunny, 2 - Cloudy, 3 - Office, 4 - Home)
    s->set_wb_mode(s, 0);
    // EXPOSURE CONTROLS (0 = Disable , 1 = Enable)
    s->set_exposure_ctrl(s, 1);
    // AEC2 (0 = Disable , 1 = Enable)
    s->set_aec2(s, 0);
    // AE LEVELS (-2 to 2)
    s->set_ae_level(s, 0);
    // AEC VALUES (0 to 1200)
    s->set_aec_value(s, 300);
    // GAIN CONTROLS (0 = Disable , 1 = Enable)
    s->set_gain_ctrl(s, 1);
    // AGC GAIN (0 to 30)
    s->set_agc_gain(s, 0);
    // GAIN CEILING (0 to 6)
    s->set_gainceiling(s, (gainceiling_t)0);
    // BPC (0 = Disable , 1 = Enable)
    s->set_bpc(s, 0);
    // WPC (0 = Disable , 1 = Enable)
    s->set_wpc(s, 1);
    // RAW GMA (0 = Disable , 1 = Enable)
    s->set_raw_gma(s, 1);
    // LENC (0 = Disable , 1 = Enable)
    s->set_lenc(s, 1);
    // HORIZ MIRROR (0 = Disable , 1 = Enable)
    s->set_hmirror(s, 0);
    // VERT FLIP (0 = Disable , 1 = Enable)
    s->set_vflip(s, 0);
    // DCW (0 = Disable , 1 = Enable)
    s->set_dcw(s, 1);
    // COLOR BAR PATTERN (0 = Disable , 1 = Enable)
    s->set_colorbar(s, 0);
   
   
  }
   
  void initMicroSDCard() {
    // Start the MicroSD card
   
    Serial.println("Mounting MicroSD Card");
    if (!SD_MMC.begin()) {
      Serial.println("MicroSD Card Mount Failed");
      return;
    }
    uint8_t cardType = SD_MMC.cardType();
    if (cardType == CARD_NONE) {
      Serial.println("No MicroSD Card found");
      return;
    }
   
  }
   
  void takeNewPhoto(String path) {
    // Take Picture with Camera
    digitalWrite(4, HIGH);
    delay(230)
    digitalWrite(4, LOW);
    // Setup frame buffer
    camera_fb_t  * fb = esp_camera_fb_get();
   
    if (!fb) {
      Serial.println("Camera capture failed");
      return;
    }
   
    // Save picture to microSD card
    fs::FS &fs = SD_MMC;
    File file = fs.open(path.c_str(), FILE_WRITE);
    if (!file) {
      Serial.println("Failed to open file in write mode");
    }
    else {
      file.write(fb->buf, fb->len); // payload (image), payload length
      Serial.printf("Saved file to path: %s\n", path.c_str());
    }
    // Close the file
    file.close();
   
    // Return the frame buffer back to the driver for reuse
    esp_camera_fb_return(fb);
    
  }
   
  void setup() {
   
    // Disable brownout detector
    WRITE_PERI_REG(RTC_CNTL_BROWN_OUT_REG, 0);
   
    // Start Serial Monitor
    Serial.begin(115200);
     pinMode(4, OUTPUT);
    // Initialize the camera
    Serial.print("Initializing the camera module...");
    configESPCamera();
    Serial.println("Camera OK!");
   
    // Initialize the MicroSD
    Serial.print("Initializing the MicroSD card module... ");
    initMicroSDCard();
   
    Serial.print("Delay Time = ");
    Serial.print(delayTime);
    Serial.println(" ms");
  }
   
  void loop() {
   
    // Path where new image will be saved in MicroSD card
    String path = "/image" + String(pictureCount) + ".jpg";
    Serial.printf("Picture file name: %s\n", path.c_str());
   
    // Take and Save Photo
    takeNewPhoto(path);
   
    // Increment picture count
    pictureCount++;
   
    // Delay for specified period
    delay(delayTime);
  }`;

  return(
    <Grid container spacing={5}   columns={isMobile?8:16} >
    <Grid xs={8}>
      <Grid style={containerStyle}>
      <Typography variant='h4'>ESP32 </Typography>
          <br/>
          <Typography>
                    <code style={codeStyle}>{esp32}</code>
            </Typography>
      </Grid>
          
  </Grid>
  <Grid xs={8}>
      <Grid style={containerStyle}>
      <Typography variant='h4'>ESP32-CAM</Typography>
          <br/>
          <Typography>
                    <code style={codeStyle}>{esp32cam}</code>
            </Typography>
      </Grid>
          
  </Grid>
  

</Grid>
  )
}
export default clientPrograms
export{Client1Prog ,Client2Prog,Client3Prog,Client4Prog }
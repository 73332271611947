import React from 'react'
import {Grid , Typography } from '@mui/material'
const outlethandling = () => {

    const arduinoProgram = `
    //these is for accessign board
    #include <Arduino.h>
    char webindex[] PROGMEM = R"=======(
    <!DOCTYPE html>
    <html>
    <head>
        <meta charset='utf-8'>
        <meta http-equiv='X-UA-Compatible' content='IE=edge'>
        <title>EEKO101</title>
        <meta name='viewport' content='width=device-width, initial-scale=1'>
        
    </head>
    <body>
        <section class="navbar-section">
            <div class="navbar-container">
                <nav class="navbar">
                    <h2 id="navbar-logo">EEKO101</h2>
                    <div class="menu-toggle" id="mobile-menu">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                    <ul class="nav-menu">
                        <li><a href="#" class="navlinks" id="home">HOME</a></li>
                        <li><a href="#" class="navlinks" id="settings">SETTINGS</a></li>
                        <li><a href="#" class="navlinks" id="reset">RESET</a></li>
                        <li><a href="#" class="navlinks" id="about">ABOUT US</a></li>
                        <li><a href="#" class="navlinks" id="contact">CONTACT US</a></li>
                    </ul>
    
                </nav>
            </div>
        </section>
        <hr class="linebreak">
        <section class="all-outlets-mesh">
            <div class="pulse">
                 <br>
                 <p >
                     Total Energy <br>
                     Consumed:
                 </p>
                 <p class="total-power-in-outlet" id="text-yellow">loading.</p>
                 <p>(Killowatt-Hour)</p>
                 <br>
                 <p>24 Hour</p>
            </div>
            <h3 class="mesh-num" id="mesh-num">Connected Mesh:</h3>
            <style>
                #mesh-num{
                    font-size: 15px;
                    color: rgb(94, 93, 93);
                    position: absolute;
                    margin: auto;
                    left: 0;
                    right: 0;
                    top:250px;
                    display: grid;
                }
            </style>
         </section>
    
        <section class="all-outlets">
    
        </section>
    
        <style></style>
        
    <!---this here is for timer pop up-->
        <div id="popupContainer">
          <div id="popupMessage">
            <button id="closeButton">X</button>
            <br>
            <br>
            <h2 class="popTxt ">Set Timer Here.</h2>
           <br>
            <div class="topButtonHolder">
                <button class="IncrementHour toBotun">
                    <i class="arrow up"></i>
                </button>
                <button class="IncrementMinute toBotun">
                    <i class="arrow up"></i>
                </button>
            </div>
            <div class="timeHolder">
                <div class="hourHolder timeTxtHolder">
                    <h4>hours</h4>
                    <h1 class="hourcounter timeTxt">0</h1>
                </div>
                <div class="minuteHolder timeTxtHolder">
                    <h4>minutes</h4>
                    <h1 class="minuteCounter timeTxt">0</h1>
                </div>
            </div>
            <div class="topButtonHolder">
                <button class="decrementHour toBotun">
                    <i class="arrow down"></i>
                </button>
                <button class="decrementMinute toBotun">
                    <i class="arrow down"></i>
                </button>
            </div>
            <br>
            <div class="timeHolder">
                <div class="timeTxtHolder">
                    <h3 class="d">Status:</h3>
                </div>
                <div class="timeTxtHolder">
                    <button class="status popBtn">set up</button>
                </div>
            </div>
            <br>
            <div class="timeHolder">
                <div class="timeTxtHolder">
                    <h3 class="d">State:</h3>
                </div>
                <div class="timeTxtHolder">
                    <button class="state popBtn">set up</button>
                </div>
            </div>
            <br>
            <button class="submit subs">Submit</button>
          </div>
        </div>
    
    
    
        <div id="popupContainer-settings">
            <div id="popupMessage-settings">
              <button id="closeButton-settings">X</button>
              <br>
              <h2 class="popTxt ">Settings.</h2>
              <form >
                <br>
                <h4>A.P Mode</h4>
                <input type="text" class="input" id="ap-ssid" placeholder="SSID">
                <input type="text" class="input" id="ap-pass" placeholder="password">
                <br>
                <br>
                <h4>S.T.A Mode</h4>
                <input type="text" class="input" id="sta-ssid" placeholder="SSID">
                <input type="text" class="input" id="sta-pass" placeholder="password">
                <br>
                <br>
                <h4>Mesh Network</h4>
                <input type="text" class="input" id="mesh-ssid" placeholder="Mesh Name">
                <input type="text" class="input" id="mesh-pass" placeholder="password">
              </form>
              <br>
              <button class="submit-input subs">Submit</button>
            </div>
        </div>
        <script>
            //for navbar
    const menu = document.querySelector('#mobile-menu');
    const menuLinks = document.querySelector('.nav-menu');
    menu.addEventListener('click',function(){
        menu.classList.toggle('is-active');
        menuLinks.classList.toggle('active');
    })
    //navigation bar 
    
    const Nav_Home =document.querySelector('#home');
    Nav_Home.addEventListener("click",func_home);
    const Nav_reset=document.querySelector('#reset');
    Nav_reset.addEventListener('click',function(){
        var data= '{"node" :'+globalNode+',"Control":'+4+'}';
        console.log(data)
        connection.send(data);
    })
    //document constant variable to change on each power
    var connection = new WebSocket('ws://'+location.hostname+':81/');
    const outletrealName="Device 1";
    var  totalPowerPlug ;
    var totalpowerMeshFromEsp;
    var powerOnPlug0;
    var powerOnPlug1;
    var powerOnPlug2;
    var showPlugPower;
    //++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    var mode="home";
    var globalNode=1;
    var node=0;
    var NumberOfMesh=1;
    var button_1_status = 0;
    var button_2_status = 0;
    var button_3_status = 0;
    var totalSocketEnergy=0;
    var totalMeshEnergy=0
    var energy1_data = 0;
    var energy2_data = 0;
    var energy3_data = 0;
    var relay_1_status=0;
    var relay_2_status=0;
    var relay_3_status=0;
    var relay_1_TripStatus=0;
    var relay_2_TripStatus=0;
    var relay_3_TripStatus=0;
    var control="";
    var relayNumber=0;
    var relayControl=0;
    
    var timerstate=1;
    var timerStatus=1;
    var timerNumber=0;
    
    
    var hourCounter=0;
    var secondsCounter=0;
    var minuteCounter=0;
    var On=0;
    var Off=1;
    var homeInit=false;
    connection.onmessage = function(event){
        var full_data = event.data;
        console.log(full_data);
        var data = JSON.parse(full_data);
        node=data.node;
        NumberOfMesh=data.connectedMesh;
        energy1_data = data.energy1;
        energy2_data = data.energy2;
        energy3_data = data.energy3;
        totalPowerPlug=data.energyTotal;
        totalMeshEnergy=data.totalMeshEnergy
        relay_1_status=data.R1status;
        relay_2_status=data.R2status;
        relay_3_status=data.R3status;
        var temp=data.temp;
        relay_1_TripStatus=data.isTripR1;
        relay_2_TripStatus=data.isTripR2;
        relay_3_TripStatus=data.isTripR3;
        
        document.querySelector(".mesh-num").textContent="Mesh Connected: "+ NumberOfMesh;
        document.querySelector('.total-power-in-outlet').textContent=totalMeshEnergy.toFixed(3);
        if (mode=="outlet") {
            if (node==globalNode) {
                document.querySelector('.temp').textContent=temp;
                document.querySelector('.power-in-plug1').textContent=energy1_data.toFixed(3);
                document.querySelector('.total-power-in-plug').textContent=totalPowerPlug.toFixed(3);
                document.querySelector('.power-in-plug1').textContent=energy1_data.toFixed(3);
                document.querySelector('.power-in-plug2').textContent=energy2_data.toFixed(3);
                document.querySelector('.power-in-plug3').textContent=energy3_data.toFixed(3);
                if(relay_1_TripStatus==1){
                    document.querySelectorAll('.plug')[0].id="isTrip"; 
                }else{
                    document.querySelectorAll('.plug')[0].id="notTrip"; 
                }if (relay_2_TripStatus==1) {
                    document.querySelectorAll('.plug')[1].id="isTrip"; 
                }else{
                    document.querySelectorAll('.plug')[1].id="notTrip"; 
                }if ( relay_3_TripStatus==1) {
                    document.querySelectorAll('.plug')[2].id="isTrip"; 
                }else{
                    document.querySelectorAll('.plug')[2].id="notTrip"; 
                }
            }
        }else if (mode=="home") {
            func_home();
        }else if(mode="settings"){
               var data_apSsid=data.apSsid;
               var data_apPass=data.apPass;
               var data_staSsid=data.staSsid;
               var data_staPass=data.ataPass;w
               var data_meshSsid=data.meshSsid;
               var data_meshPass=data.meshPass;
                document.querySelector("#ap-ssid").placeholder=data_apSsid;
                document.querySelector("#ap-pass").placeholder=data_apPass;
                document.querySelector("#sta-ssid").placeholder=data_staSsid;
                document.querySelector("#sta-pass").placeholder=data_staPass;
                document.querySelector("#mesh-ssid").placeholder=data_meshSsid;
                document.querySelector("#mesh-pass").placeholder=data_meshPass;
        }
        
        }
    function button_1_on()
        {
        control=0;
        relayControl = On; 
        relayNumber=1;
        send_data();
        alert("Turning On socket 1!");
        }
    function button_1_off()
        {
        control=0;
        relayControl = Off;
        relayNumber=1;
        send_data();
        alert("Turning Off socket 1!");
        }
    function button_2_on()
        {
        control=0;
        relayControl = On; 
        relayNumber=2;
        send_data();
        alert("Turning On socket 2!");
        }
    function button_2_off()
        {
        control=0;
        relayControl = Off;
        relayNumber=2;
        send_data();
        alert("Turning Off socket 2!");
        }
    function button_3_on()
        {
        control=0;
        relayControl = On; 
        relayNumber=3;
        send_data();
        alert("Turning On socket 3!");
        }
    function button_3_off()
        {
        control=0;
        relayControl = Off;
        relayNumber=3;
        send_data();
        alert("Turning Off socket 3!");
        }
    function send_data()
        {
        var full_data = '{"node" :'+globalNode+',"Control":'+control+',"Rnum":'+relayNumber+',"Rcon":'+relayControl+',"Tnum":'+timerNumber+',"Tstat":'+timerStatus+',"Tcon":'+timerstate+',"Tcount":'+secondsCounter+'}';
        console.log(full_data)
        connection.send(full_data);
        
        }
    function send_data_Timer()
        {
        var full_data = '{"node" :'+globalNode+',"Control":'+1+',"Tnum":'+timerNumber+',"Tstat":'+timerStatus+',"Tcon":'+timerstate+',"Tcount":'+secondsCounter+'}';
        console.log(full_data)
        connection.send(full_data);
        
        }
    
    function func_Outlet() {
        document.querySelector('.all-outlets').innerHTML=""; 
        
        const body = document.querySelector('.all-outlets');
        body.className= "all-outlets";
    
        const individualOutlet=document.createElement('div');
        individualOutlet.className="individual-outlets";
    
        const lines = document.createElement('hr');
        lines.className="lineb";
    
        
    
        const outletName = document.createElement('h3');
        outletName.className="outlet-name";
        outletName.setAttribute('id','text-center');
    
        const circlecenter=document.createElement('div');
        circlecenter.className ="circle-center";
    
        const individualOutletPulse = document.createElement('div');
        individualOutletPulse.className="individual-outlets-pulse";
    
        const brs = document.createElement('br');
    
        const totalPlugPowerPlug = document.createElement('p');
        totalPlugPowerPlug.className="total-power-in-plug";
        totalPlugPowerPlug.setAttribute('id','text-power-plug');
        totalPlugPowerPlug.innerText="loading";
    
        const pKwh=document.createElement('p');
        pKwh.innerText="(KW-h)";
        const individualPulse = document.createElement('div');
        
        //right side on each each plug
       
        //FOR BODY
        
        const temp=document.createElement('h4');
        temp.className='temp';
        temp.innerText='T:'
        
       
        outletName.innerText="DEVICE  "+globalNode;
        individualOutlet.appendChild(outletName);
        individualOutlet.appendChild(lines);
        individualOutlet.appendChild(circlecenter);
        individualOutlet.appendChild(temp);
        circlecenter.appendChild(individualOutletPulse);
        individualOutletPulse.appendChild(totalPlugPowerPlug);
        individualOutletPulse.appendChild(pKwh);
     
        individualOutlet.appendChild(brs);
       
    
        for (let i = 0; i < 3; i++) {
            const plug = document.createElement('div');
            plug.className="plug";
            plug.setAttribute('id','notTrip');
            individualOutlet.appendChild(plug);
            
            const row = document.createElement('div');
            row.className="row";
            plug.appendChild(row);
    
            const column1= document.createElement('div');
            column1.className="column";
            column1.setAttribute('id','left');
            row.appendChild(column1);
            const eachPlugCircle = document.createElement('div');
            eachPlugCircle.className="gradient-circle";
            eachPlugCircle.setAttribute('id','eachplug-circle');
            column1.appendChild(eachPlugCircle);
    
            const pulse=document.createElement('div');
            pulse.className="individual-outlets-pulse"
            eachPlugCircle.appendChild(pulse);
            
            const PowerTextPlug = document.createElement('p');
            PowerTextPlug.className="power-in-plug"+(i+1);
            PowerTextPlug.setAttribute('id','text-power-plug');
            PowerTextPlug.innerText="loading";
            
            pulse.appendChild(PowerTextPlug);
            const kphForPlug=document.createElement('p');
    
            kphForPlug.innerText="(KW-h)";
            pulse.appendChild(kphForPlug);
            const column2= document.createElement('div');
            column2.className="column";
            column2.setAttribute('id','right');
            row.appendChild(column2);
    
            //socket name
            const socket=document.createElement('h4');
            socket.className="socket"+(i+1);
            socket.setAttribute('id','socket-names');
            const socket1=document.querySelector("socket"+(i+1));
            socket.innerHTML="Socket "+(i+1);
            column2.appendChild(socket);
            column2.appendChild(document.createElement('hr'));
            column2.appendChild(document.createElement('br'));
            //Timer message
            const timerTxt1=document.createElement('p');
            timerTxt1.className="txtTimer";
            const timerTxt2=document.createElement('p');
            timerTxt2.className="txtTimer";
            column2.appendChild(timerTxt1);
            column2.appendChild(timerTxt2);
            timerTxt1.innerText="On Timer:";
            timerTxt2.innerText="Status:";
            //Button
            
            
            const forBut=document.createElement('div');
            forBut.className="forbut";
            column2.appendChild(forBut);
            
            const butholder=document.createElement('button');
            butholder.className="butholder left1 timer";
            butholder.setAttribute('id',i+1);
            butholder.innerHTML="Timer";
            forBut.appendChild(butholder);
            const butholder1=document.createElement('div');
            butholder1.className="butholder right1";
            forBut.appendChild(butholder1);
    
            
    
            const onOffBut=document.createElement('button');
            onOffBut.className="butons  On";
            onOffBut.innerHTML="ON";
            onOffBut.setAttribute('id',i+1);
            //col_but.appendChild(onOffBut);
            const onOffBut2=document.createElement('button');
            onOffBut2.className="butons Off";
            onOffBut2.innerHTML="OFF";
            onOffBut2.setAttribute('id',i+1);
            //col_but2.appendChild(onOffBut2);
            butholder1.appendChild(onOffBut);
            butholder1.appendChild(onOffBut2);
           
            individualOutlet.appendChild(document.createElement('br'));
            showPlugPower=1;
            
           
        }
        
        
    
        body.appendChild(individualOutlet);
        //adding event lister to buttons
     
        for (let i = 0; i < 3; i++) {
            document.querySelectorAll(".timer")[i].addEventListener("click",function(){
                document.getElementById("popupContainer").style.display = "block";
                hourCounter=0;
                minuteCounter=0;
                secondsCounter=0;
                timerNumber=this.id;
                document.querySelector('.hourcounter').textContent=hourCounter;
                document.querySelector('.minuteCounter').textContent=hourCounter;
            });
        }
        var linkss=document.querySelectorAll('.On');
        for (let i = 0; i < 3; i++) {
           var showid=linkss[i].id;
           console.log(showid);
            if (showid==1) {
                document.querySelectorAll('.On')[i].addEventListener("click",button_1_on);
            }if (showid==2) {
                document.querySelectorAll('.On')[i].addEventListener("click",button_2_on);
            }
            if (showid==3) {
                document.querySelectorAll('.On')[i].addEventListener("click",button_3_on);
            }
            
        }
        var linkss=document.querySelectorAll('.Off');
        for (let i = 0; i < 3; i++) {
           var showid=linkss[i].id;
            if (showid==1) {
                document.querySelectorAll('.Off')[i].addEventListener("click",button_1_off);
            }if (showid==2) {
                document.querySelectorAll('.Off')[i].addEventListener("click",button_2_off);
            }
            if (showid==3) {
                document.querySelectorAll('.Off')[i].addEventListener("click",button_3_off);
            }
            
        }
        
        
    }
    
    
       
    function func_home() {
            mode="home";
            consoleM(mode);//delete later
            globalNode=0;
            console.log(globalNode);
            document.querySelector('.all-outlets').innerHTML=""; 
    
            if(homeInit==true){
                menu.classList.toggle('is-active');
                menuLinks.classList.toggle('active'); 
            }        
            homeInit=false;
            const body = document.querySelector('.all-outlets');
    
            const box=document.createElement('div');
            box.className='box';
            body.appendChild(box);
        for (let i = 0; i < NumberOfMesh; i++) {
            const smallBox = document.createElement('div');
            smallBox.className="smallBox";
            smallBox.setAttribute('id',i+1);
            box.appendChild(smallBox);
    
            const HomeDeviceName=document.createElement('h3');
            HomeDeviceName.className='devName';
            HomeDeviceName.innerText="Device "+(i+1);
            HomeDeviceName.setAttribute('id',i);
            smallBox.appendChild(HomeDeviceName);
    
            const outletouterShape=document.createElement('div')
            outletouterShape.className="outlet-outer-shape";
            smallBox.appendChild(outletouterShape);
    
            for (let i = 0; i < 4; i++) {
                
            const innerOutlets=document.createElement('div');
            innerOutlets.className='inneroutletsshape';
            outletouterShape.appendChild(innerOutlets);
    
            const innerPlug=document.createElement('h4');
            innerPlug.className="innerPlug";
            innerPlug.innerText="|  |"
            innerOutlets.appendChild(innerPlug);
            } 
        }
       
        var meshn=document.querySelector(".smallBox").length;
            for(var x=0;x<NumberOfMesh;x++){
                document.querySelectorAll(".smallBox")[x].addEventListener("click",function () {
                    
                    globalNode=this.id;
                    func_Outlet();
                    mode="outlet";
                    console.log(mode);
                });
            }
    }     
    
    function consoleM(msg) {
        console.log(msg);
    }
    //this is for the timer option
    function secondConvert() {
        secondsCounter=(minuteCounter*60)+(hourCounter*60*60);
        console.log(secondsCounter);
    }
    document.querySelector('.IncrementHour').addEventListener("click",function(){
        if (hourCounter<23) {
            hourCounter+=1;
            secondConvert();
        }else{
            alert("Reach Maximum Value");
        }
       
        document.querySelector('.hourcounter').textContent=hourCounter;
    });
    document.querySelector('.decrementHour').addEventListener("click",function(){
        if (hourCounter>0) {
            hourCounter-=1;
            secondConvert();
        }else{
            alert("Reach Minimum Value");
        }
       
        document.querySelector('.hourcounter').textContent=hourCounter;
    });
    document.querySelector('.IncrementMinute').addEventListener("click",function(){
        if (minuteCounter<59) {
            minuteCounter+=1;
            secondConvert();
        }else{
            alert("Reach Maximum Value");
        }
       
        document.querySelector('.minuteCounter').textContent=minuteCounter;
    });
    document.querySelector('.decrementMinute').addEventListener("click",function(){
        if (minuteCounter>0) {
            minuteCounter-=1;
            secondConvert();
        }else{
            alert("Reach Minimum Value");
        }
       
        document.querySelector('.minuteCounter').textContent=minuteCounter;
    });
    //timer button Event listener
    document.querySelector('.status').addEventListener("click",function(){
        if (timerStatus==1) {
            timerStatus=0;
            this.innerHTML="Turning On";
            console.log(timerStatus);
        
        }else{
            timerStatus=1;
            this.innerHTML="Turning Off";
            console.log(timerStatus);
            return;
        }
        control=1
        
    });
    document.querySelector('.state').addEventListener("click",function(){
        if (timerstate==1) {
            timerstate=0;
            this.innerHTML="Activated";
            console.log(timerstate);
        
        }else{
            timerstate=1;
            this.innerHTML="Deactivated";
            console.log(timerstate);
           
        }
        control=1
        
    });
    
    document.querySelector('.submit').addEventListener("click",function(){
        send_data_Timer()
        if (hourCounter!=0||minuteCounter!=0) {
            secondsCounter=0;
            document.getElementById("popupContainer").style.display = "none";
            alert("Timer is Set" );
            
        }
        else{
            alert("Timer must not be zero!")
        }
        
    });
    document.getElementById("settings").addEventListener("click", function() {
        document.getElementById("popupContainer-settings").style.display = "block";
        menu.classList.toggle('is-active');
        menuLinks.classList.toggle('active');
        control=3;
        mode="settings";
        var full_data = '{"Control":'+control+'}';
        console.log(full_data)
        connection.send(full_data);
       
        });
    document.querySelector('.submit-input').addEventListener("click",function(){
        control=3;
       let ap_ssid=document.getElementById("ap-ssid").value;
       let ap_pass=document.getElementById("ap-pass").value;
       let sta_ssid=document.getElementById("sta-ssid").value;
       let sta_pass=document.getElementById("sta-pass").value;
       let mesh_ssid="";
       let mesh__pass="";
        console.log(ap_ssid);
       var full_data = '{"Control":'+control+',"ap_ssid":"'+ap_ssid+'","ap_password":"'+ap_pass+'","sta_ssid":"'+sta_ssid+'","sta_pass":"'+sta_pass+'","mesh_ssid":"'+mesh_ssid+'","mesh_pass":"'+mesh__pass+'"}';
        console.log(full_data)
        connection.send(full_data);
        alert("Setting up...!");
    });
    
    
    
        </script>
        <script>
        document.getElementById("closeButton").addEventListener("click", function() {
        document.getElementById("popupContainer").style.display = "none";
            });
        document.getElementById("closeButton-settings").addEventListener("click", function() {
        document.getElementById("popupContainer-settings").style.display = "none";
        
            });
            
        </script>
    
        <style>
            :root{
        --gradiantbg:linear-gradient(62deg, #57a5c4 0%, #be91eb 100%);
        --bgcolorfor:#4ab1be;
    }
    
    .smallBox{
        align-content: center;
        width: 11em;
        height: 11em;
        border-radius: 10px;
        align-items: center;
        background-color: var(--bgcolorfor);
        background-image: var(--gradiantbg);
        margin: auto;
        left: 0;
        right: 0;
        box-shadow: rgba(0, 0, 0, 1) 0px 1px 2px 0px, rgba(7, 7, 7, 0.4) 0px 2px 6px 2px;
    }
    .box{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
        margin: auto;
        left: 0;
        right: 0;
        width: 90%;
        padding-bottom: 30px;
        
    }
    
    @media screen and (min-width: 800px){
        .smallBox{
            width: 300px;
            height: 300px;
            border-radius: 10px;
            border-color: black;
            background-color: blueviolet;
            margin: auto;
            left: 0;
            right: 0;  
        }
        .innerPlug{
            text-align: center;
            margin-top: 8%;
            font-size: 1.5em;
        }
    }
    
    .outlet-outer-shape{
        margin-top: 10px;
        margin-left: 25%;
        align-self: center;
        border-style: solid;
        border-width: 2px;
        border-radius: 10%;
        width: 50%;
        height: 80%;  
    }
    .inneroutletsshape{
        height: 20%;
        width: 45%;
        border-radius: 2px;
        border-width: 2px;
        border-style: solid;
        border-radius: 25%;
        margin-top: 5px;
        margin-left: 25%;
    }
    .innerPlug{
        text-align: center;
        margin-top: 8%;
    }
    .devName{
        text-align: center;
        
    }
    :root{
        --fonts:sans-serif;
        --textNormal:rgb(219, 224, 204);
        --textPower:yellow;
        --colorborder:rgb(2, 2, 179);
        --borderwidth:2px;
        --gradiantbg:linear-gradient(62deg, #6ccdf3 0%, #be91eb 100%);
        --bgcolorfor:#4ab1be;
        --gradiantbgs:linear-gradient(62deg, #2f7dca 0%, #d1a6fa 100%);
        --bgcolorfors:#5eadfc;
        --energyGreen:linear-gradient(to top, #0fd850 0%, #f9f047 100%);
        --finalBg:linear-gradient(to top, #5ee7df 0%, #b490ca 100%);
     
    } 
    body{        
        background-color: #00a2ff;
        background-image: linear-gradient(62deg, #cbeef7 0%, #e8d1f3 100%);
        font-family: var(--fonts);
        color: var(--textNormal);
    }
    .center{
        display: flex;
        align-items: center;
        justify-content: center;
        
    }
    #text-center{
        text-align: center; 
       
    }
    .linebreak{
        width: 80%;
        color:black;
        position: absolute;
        margin: auto;
        left: 0;
        right: 0;
        top:280px;
        display: grid;
        z-index: -1;
    }
    
    .all-outlets{
        width: 100%;
        position: absolute;
        margin: auto;
        left: 0;
        right: 0;
        top:300px;
        display: grid;
        z-index: -1;
       
    }
    
    .individual-outlets{
        background-color: var(--bgcolorfor);
        background-image: var(--gradiantbg);
        width: 95%;
        position: absolute;
        margin: auto;
        left: 0;
        right: 0;
        display: grid;
        border-radius:20px;
        z-index: -1;
        padding-bottom: 20px;
        box-shadow: rgba(0, 0, 0, 1) 0px 1px 2px 0px, rgba(7, 7, 7, 0.4) 0px 2px 6px 2px;
    
    }
    .plug{
       
        width: 98%;
        margin: auto;
        left: 0;
        right: 0;
        display: grid;
        border-radius:10px;
        z-index: -1;
        padding-bottom: 10px;
        box-shadow: rgba(0, 0, 0, 1) 0px 1px 2px 0px, rgba(7, 7, 7, 0.4) 0px 2px 6px 2px;
    }
    #notTrip{
        background-color: var(--bgcolorfors);
        background-image: var(--gradiantbgs);
    }
    #isTrip{
        background: #c21500;  /* fallback for old browsers */
        background: -webkit-linear-gradient(to right, #ffc500, #c21500);  /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to right, #ffc500, #c21500); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    
    
    }
    #text-power-plug{
        color: yellow;
        font-size: 20px;
        margin-top: 20px;
    }
    /*row*/
    .column {
        float: left;
        }
        #left {
        width: 25%;
        }
       
        #right {
        width: 75%;
        }
        .row:after {
        content: "";
        display: table;
        clear: both;
        }
    
    .txtTimer{
        font-size: 15px;
      
    }
    
        
        @media screen and (min-width: 768px){
            .all-outlets{
                width: 60%;
                position: absolute;
                margin: auto;
                left: 0;
                right: 0;
                top:300px;
                display: grid;
                z-index: -1;
                
                
            }
            .plug{
                background-color: var(--bgcolorfors);
                background-image: var(--gradiantbgs);
                width: 80%;
               
                margin: auto;
                left: 0;
                right: 0;
                display: grid;
                border-radius:20px;
                z-index: -1;
            }
        }
        .forbut{
            width: 65%;
            height: 70%;
            margin-left: 25%;
    
        }
       
        .butholder {
            float: left;
          }
          .left1 {
            color: white;
            width: 25%;
            padding: 10px;
            height: 100%; 
            border-radius: 20px;
            background-color: transparent;
            border-width: 1px;
            box-shadow: rgba(0, 0, 0, 1) 0px 1px 2px 0px, rgba(7, 7, 7, 0.4) 0px 2px 6px 2px;
            }
           
            .right1 {
            width:75%;
            }
          
          /* Clear floats after the columns */
          .forbut:after {
            content: "";
            display: table;
            clear: both;
            
          }
        
        
        
        .butons {
            color: white;
            float: left;
            width: 50%;
            padding: 10px;
            height: 100%; 
            background-color:transparent;
            border-radius: 20px;
            border-width: 1px;
            box-shadow: rgba(0, 0, 0, 1) 0px 1px 2px 0px, rgba(7, 7, 7, 0.4) 0px 2px 6px 2px;
            
          }
          
          /* Clear floats after the columns */
          .butholder:after {
            content: "";
            display: table;
            clear: both;
            
          }
       
       :root{
        --darkbg:rgb(2, 8, 90);
        --white:white;
        --redhover:rgb(177, 1, 1);
        --bright:rgb(135, 187, 170);
    }
    *{
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        
    }
    .navbar-container{
        width: 100%;
        background-color: var(--darkbg);
        box-shadow: rgba(0, 0, 0, 1) 0px 1px 2px 0px, rgba(7, 7, 7, 0.1) 0px 2px 6px 2px;
    }
    .navbar{
        display: grid;
        grid-template-columns: 0.2fr auto 1fr;
        align-items: center;
        height: 50px;
        width: 90%;
        max-width: 1720px;
        margin: 0 auto;
    }
    #navbar-logo{
        color: var(--white);
        justify-self: start;
        margin-left: 20px;
    }
    #navbar-logo{
        cursor: pointer;
    }
    .nav-menu{
        display: grid;
        grid-template-columns:repeat(5,auto) ;
        list-style: none;
        text-align: center;
        width: 70%;
        justify-self: end;
    }
    .navlinks{
        color: var(--white);
        text-decoration: none;
    }
    .navlinks:hover{
        color: var(--redhover);
        transition: all 0.2 ease-out;
    }
    .menu-toggle .bar{
        width: 25px;
        height: 3px;
        margin:5px auto ;
        transition: all 0.3s ease-in-out;
        background: rgb(255, 255, 255);
    }
    @media screen and (max-width: 768px){
        .navbar-container{
            position: relative;
        }
        .nav-menu{
            display: grid;
            grid-template-columns: auto;
            background-color:rgb(32, 5, 107);
            margin: 0;
            width: 100%;
            position: absolute;
            top: 50px;
            left: -100%;
            opacity: 1;
            transition: all 0.5s ease;
        }
        #navbar-logo{
            margin-left: 20px;
        }
        .nav-menu.active{
            background: linear-gradient(rgb(0, 0, 196),rgb(0, 162, 255));
            left: 0;
            opacity: 1;
            transition:all 0.5s ease;
        }
        .navlinks{
            text-align: center;
            line-height: 60px;
            width: 100%;
            display: table;
        }
        .navlinks:hover{
            background-color: rgb(127, 0, 212);
            color: var(--white);
            transition: all 0.4s ease-out;
        }
        .navbar{
            width: 100%;
        }
       #mobile-menu{
        position: absolute;
        top:20%;
        right:5%;
        transform: translate(5%,20%);
       }
       .menu-toggle .bar{
        display: block;
        cursor: pointer;
       }
       .menu-toggle:hover{
        cursor: pointer;
       }
       #mobile-menu.is-active .bar:nth-child(2){
        opacity: 0;
       }
       #mobile-menu.is-active .bar:nth-child(1){
        transform: translateY(8px) rotate(45deg);
       }
       #mobile-menu.is-active .bar:nth-child(3){
        transform: translateY(-8px) rotate(-45deg);
       }
       #mobile-menu.is-active .bar:nth-child(4){
        opacity: 0;
       }
       
    }
    .navbar-section{
        position: sticky;
    }
    
    :root{
        --oldColordesign:linear-gradient(#8178fc,#12d9f3);
        --energyGreen:linear-gradient(to top, #0fd850 0%, #f9f047 100%);
    }
    
    *,
    *::before,
    *::after{
        padding: 0;
        margin: 0;
        box-sizing: border-box;
    }
    .pulse{
        height: 150px;
        width: 150px;
        background:var(--oldColordesign) ;
        position: absolute;
        margin: auto;
        left: 0;
        right: 0;
        top:80px;
        display: grid;
        place-items: center;
        font-size: 10px;
        border-radius: 50%;
        z-index: -1;
        box-shadow: rgba(0, 0, 0, 1) 0px 1px 2px 0px, rgba(7, 7, 7, 0.7) 0px 2px 6px 2px;
    }
    .pulse::before,
    .pulse:after{
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: #00ffdd;
        border-radius: 50%;
        z-index: -2;
        opacity: 0.3;
    }
    #text-yellow{
        color: var(--textPower);
        font-size: 40px;
    }
    .pulse:before{
        animation: pulse 2s ease-out infinite;
    }
    .pulse:after{
        animation: pulse 2s 1s ease-out infinite;
    }
    @keyframes pulse{
        100%{
            transform: scale(1.5);
            opacity: 0;
        }
    }
    .individual-outlets-pulse{
        height: 80px;
        width: 80px;
        background: var(--oldColordesign);
        margin: auto;
        margin-top: 10px;
        display: grid;
        place-items: center;
        font-size: 8px;
        border-radius: 50%;
        z-index: -1;
        box-shadow: rgba(0, 0, 0, 1) 0px 1px 2px 0px, rgba(7, 7, 7, 0.1) 0px 2px 6px 2px;
    }
    .individual-outlets-pulse::before,
    .individual-outlets-pulse:after{
        content: "";
        position: absolute;
        height: 80px;
        width: 80px;
        background-color: #00ffdd;
        border-radius: 50%;
        z-index: 1;
        opacity: 0.2;
    }
    .individual-outlets-pulse:before{
        animation: pulse 2s ease-out infinite;
    }
    .individual-outlets-pulse:after{
        animation: pulse 2s 1s ease-out infinite;
    }
    @keyframes individual-outlets-pulse{
        100%{
            transform: scale(1.5);
            opacity: 0;
        }
    }
    
    #popupContainer {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 9999;
      }
      
      #popupMessage {
        border-radius: 10px 10px;
        background-color: var(--bgcolorfors);
        background-image: var(--gradiantbgs);
        padding-bottom: 20px;
        max-width: 350px;
        margin: 50px auto;
        text-align: center;
        box-shadow: rgba(0, 0, 0, 1) 0px 1px 2px 0px, rgba(7, 7, 7, 0.4) 0px 2px 6px 2px;
      }
      
      #closeButton {
        font-size: 20px;
        background-color: #e74c3c;
        color: #fff;
        border: none;
        height: 30px;
        width: 30px;
        cursor: pointer;
        float: right;
        border-radius: 50%;
        box-shadow: rgba(0, 0, 0, 1) 0px 1px 2px 0px, rgba(7, 7, 7, 0.4) 0px 2px 6px 2px;
      }
      .popTxt{
        color: rgb(255, 255, 255);
      }
    
      .topButtonHolder{
        
        width: 60%;
        margin: auto;
        align-self: center;
        
      }
      .toBotun{
        
        float: left;
        width: 50%;
        border-color: transparent;
        border-width: 1px;
        background-color: transparent;
      }
      /* Clear floats after the columns */
      .topButtonHolder:after {
        content: "";
        display: table;
        clear: both;
        
      }
      .arrow {
        border: solid rgb(255, 255, 255);
        border-width: 0 10px 10px 0;
        
        display: inline-block;
        padding: 10px;
        box-shadow: rgba(0, 0, 0, 1) 2px 2px 0px 0px, rgba(7, 7, 7, 0.4) 6px 6px 0px 0px;
      }
      .up {
        margin-top: 10px;
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
      }
      .down {
        margin-top: 10px;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
      }
      .timeHolder{
        
        width: 60%;
        margin: auto;
        align-self: center;
        
      }
      .timeTxtHolder{
        
        float: left;
        width: 50%;
        border-color: transparent;
        border-width: 1px;
        background-color: transparent;
      }
      /* Clear floats after the columns */
      .timeHolder:after {
        content: "";
        display: table;
        clear: both;
        
      }
      .popBtn{
        color: white;
        width: 100%;
        height: 30px;
        border-radius: 10px;
        background-color: transparent;
        box-shadow: rgba(0, 0, 0, 1) 0px 1px 2px 0px, rgba(7, 7, 7, 0.4) 0px 2px 6px 2px;
        
      }
      .popBtn{
        color: white;
        width: 60%;
        height: 30px;
        border-radius: 10px;
        background-color: transparent;
        box-shadow: rgba(0, 0, 0, 1) 0px 1px 2px 0px, rgba(7, 7, 7, 0.4) 0px 2px 6px 2px;
        
      }
      .subs{
        color: white;
        width: 45%;
        height: 45px;
        border-radius: 10px;
        background-color: transparent;
        box-shadow: rgba(0, 0, 0, 1) 0px 1px 2px 0px, rgba(7, 7, 7, 0.4) 0px 2px 6px 2px;
        
      }
    
      .input{
        height: 30px;
        border-radius: 10px;
      }
    
      @media screen and (min-width: 800px){
        #popupMessage {
          border-radius: 10px 10px;
          background-color: var(--bgcolorfors);
          background-image: var(--gradiantbgs);
          padding-bottom: 20px;
          max-width: 450px;
          margin: 50px auto;
          text-align: center;
          box-shadow: rgba(0, 0, 0, 1) 0px 1px 2px 0px, rgba(7, 7, 7, 0.4) 0px 2px 6px 2px;
        }
        
    }
      
        :root{
        --sublimeLight:linear-gradient(to right, #fc5c7d, #6a82fb);
        --energyGreen:linear-gradient(to top, #0fd850 0%, #f9f047 100%);
    }
    #popupContainer-settings {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 9999;
      }
    
      #popupMessage-settings{
        border-radius: 10px 10px;
        background-color: var(--bgcolorfors);
        background-image: var(--gradiantbgs);
        padding-bottom: 20px;
        max-width: 400px;
        margin: 200px auto;
        text-align: center;
        box-shadow: rgba(0, 0, 0, 1) 0px 1px 2px 0px, rgba(7, 7, 7, 0.4) 0px 2px 6px 2px;
      }
      #closeButton-settings {
        font-size: 20px;
        background-color: #e74c3c;
        color: #fff;
        border: none;
        height: 30px;
        width: 30px;
        cursor: pointer;
        float: right;
        border-radius: 50%;
        box-shadow: rgba(0, 0, 0, 1) 0px 1px 2px 0px, rgba(7, 7, 7, 0.4) 0px 2px 6px 2px;
      }
      
        
        </style>
    </body>
    </html>
      
    )=======";
#include <Arduino.h>
#include <WiFi.h>
#include <SPIFFS.h>
#include <Update.h>
#include <ESPAsyncWebServer.h>
#include <WebSocketsServer.h>
#include <ArduinoJson.h>
#include <U8g2lib.h>
#include <TaskScheduler.h>
#include <html.h>
#include <ESPmDNS.h>
#include <Preferences.h>
//#include <Firebase_ESP_Client.h>
#include <arduinoJson.h>
#include <addons/TokenHelper.h>
#include <addons/RTDBHelper.h>
#include <HTTPClient.h>
#include <ETH.h>
#include <SD.h>
//oled init
#ifdef U8G2_ST7567_ENH_DG128064I_F_SW_I2C
#include <SPI.h>
#endif
#ifdef U8G2_ST7567_ENH_DG128064I_F_SW_I2C
#include <Wire.h>
#endif
U8G2_ST7567_ENH_DG128064I_F_SW_I2C u8g2(U8G2_R2, 22, 21, U8X8_PIN_NONE);
// U8G2_SH1106_128X64_NONAME_F_HW_I2C u8g2(U8G2_R0, /* reset=*/ U8X8_PIN_NONE);
/////////////
#define but1 33
#define but2 32
#define but3 35
////firebaseInit
#define API_KEY "AIzaSyDFdetfMa-yPX5nEARLSBBDAckyGnPmDWU"
#define DATABASE_URL "https://esp32-29cdc-default-rtdb.firebaseio.com/"
FirebaseData fbdo;
FirebaseAuth auth;
FirebaseConfig config;
unsigned long sendDataPrevMillis = 0;
int count = 0;
bool signupOK = false;
//init task

Scheduler userScheduler;
//Task taskSendMessageUart( TASK_SECOND * 1 , TASK_FOREVER, &funcSendRequest);
//Task taskSendMessageWeb( TASK_SECOND * 3 , TASK_FOREVER, &funcSendWebMsg);
//Task taskReadUart( TASK_SECOND * 2 , TASK_FOREVER, &funcReadUart);

//Init__Websever
Preferences preferences;
AsyncWebServer server(80);
WebSocketsServer websockets(81);
String mode="";//empty mode use for text of mode to oled
String typeRequest=""; //this var is to specify a request to waht node control 
double energyPzem1=0.12;
double energyPzem2=0.13;
double energyPzem3=0.012;
float power;
int socketStatus ;
int DeviceNode=1 ;
int relay1Control=1;
int relay2Control=1;
int relay3Control=1;
int relay1Status=0;
int relay2Status=0;
int relay3Status=0;
int timer1Control=0;
int timer2Control=0;
int timer3Control=0;
float timer1count;
float timer2count;
float timer3count;
int timer1Status=1;
int timer2Status=0;
int timer3Status=0;

bool message_ready;
int meshNumber=1;
int relay1Trip;
int relay2Trip;
int  relay3Trip;
float  temp;
float  totalMeshEnergy;
float  selfEnergytotal;
//fnctions init
String ap_ssid;
String ap_password;
String sta_ssid;
String sta_password;
String mesh_ssid;
String mesh_password;
int control;
int node;
void funcMode(int wifiState);

void apSettings(String ssid , String pass);
void staSettings(String ssid , String pass);
void meshSettings(String ssid , String pass);
void InitAp();
void InitSta();
void InitMesh();void IRAM_ATTR isr_Mesh() ;
String serverName = "https://markycapstoneproject.000webhostapp.com/savedata.php";
void FirabaseConnect();

//____________websocket______________________
void notFound(AsyncWebServerRequest *request)
{
  request->send(404, "text/plain", "Page Not found");
}
void webSocketEvent(uint8_t num, WStype_t type, uint8_t * payload, size_t length) {

  switch (type) 
  {
    case WStype_DISCONNECTED:
      Serial.printf("[%u] Disconnected!\n", num);
      break;
    case WStype_CONNECTED: {
        IPAddress ip = websockets.remoteIP(num);
        Serial.printf("[%u] Connected from %d.%d.%d.%d url: %s\n", num, ip[0], ip[1], ip[2], ip[3], payload);

        // send message to client
        websockets.sendTXT(num, "Connected from server");
      }
      break;
    case WStype_TEXT:
      Serial.printf("[%u] get Text: %s\n", num, payload);
      String message = String((char*)( payload));
      Serial.println(message);

       DynamicJsonDocument doc(1024);
    // deserialize the data
      DeserializationError error = deserializeJson(doc, message);
    // parse the parameters we expect to receive (TO-DO: error handling)
      // Test if parsing succeeds.
      
      if (error) {
        Serial.print("deserializeJson() failed: ");
        Serial.println(error.c_str());
        return;
      }
      
      control=doc["Control"];
    
      if (control==3)
      {
        String ap_savesettings=doc["ap_ssid"];
        String ap_save_pass=doc["ap_password"];
        String Sta_Save_ssid=doc["sta_ssid"];
        String Sta_save_pass=doc["sta_password"];
        String Mesh_Save_Ssid=doc["mesh_ssid"];
        String Mesh_Save_Pass=doc["mesh_password"];
        apSettings(ap_savesettings.c_str(),ap_save_pass.c_str());
        staSettings(Sta_Save_ssid.c_str(),Sta_save_pass.c_str());
        meshSettings(Mesh_Save_Ssid.c_str(),Mesh_Save_Pass.c_str());
        if (Sta_Save_ssid!="")
        {
          sta_ssid=Sta_Save_ssid;
          sta_password=Sta_save_pass;
          funcMode(1);
        }
        
      }
      if (control==10)
      {
        String ModeSet=doc["mode_set"];
        if (ModeSet=="A.P")
        {
          funcMode(0);
        }else if(ModeSet=="S.T.A"){
          funcMode(1);
        }else if(ModeSet=="S.T.A.O"){
           funcMode(3);
        }
        
      }
      
      serializeJson(doc,Serial2);
      //int LED1_status = doc["LED1"];
      //int LED2_status = doc["LED2"];
      //digitalWrite(ledPin,LED1_status);
    
  }
}

//______________________________________________________
void setup() {
  u8g2.setI2CAddress(0x3F * 2);
  u8g2.begin();
  
  //  //ledDsiplay
  u8g2.clearBuffer();
  u8g2.setFont(u8g2_font_ncenB08_tr);
  u8g2.drawStr(0,10,"Initialization");	
  u8g2.sendBuffer();
  //////
  InitAp();
  InitSta();
  InitMesh();
  
  Serial2.begin(115200, SERIAL_8N1, 16, 17); 
  Serial.begin(115200);
  //Pins init
 
  funcMode(0);
  funcMode(1);
 
  //MDNS
	if (MDNS.begin("outlet")) { //esp.local/
    Serial.println("MDNS responder started");
  		}
	//webserver
	server.on("/", [](AsyncWebServerRequest * request)
	{ 
	String message = "hello world"; 
	request->send_P(200, "text/html",webindex);//put html here
	});
  
  //Forserver
  server.onNotFound(notFound);
	server.begin(); 
	websockets.begin();
 	websockets.onEvent(webSocketEvent);
 
preferences.begin("credentials", false);
preferences.clear();
preferences.end();
  
}
void loop() {
  //for Uart
  
  while (Serial2.available())
  {  String recievedMessage;
     recievedMessage= Serial2.readString();
     message_ready = true;
      if (message_ready)
  { 
    
    message_ready = false;
    StaticJsonDocument <800> fromUart;
    DeserializationError error = deserializeJson(fromUart,recievedMessage);
    if (error)
    {
      Serial.print("deserializeJson() failed: ");
      Serial.println(error.c_str());
    }
    meshNumber=fromUart["connectedMesh"];
    if (meshNumber>1)
    {
      // u8g2.clearBuffer();
      // u8g2.setFont(u8g2_font_ncenB08_tr);
      // u8g2.drawStr(0,40,"Mesh Connected:"+meshNumber);	
      // u8g2.sendBuffer();
    }
    
    node = fromUart["node"];
    energyPzem1=fromUart["energy1"];
    energyPzem2=fromUart["energy2"];
    energyPzem3=fromUart["energy3"];
    relay1Trip=fromUart["isTripR1"];
    relay2Trip=fromUart["isTripR2"];
    relay3Trip=fromUart["isTripR3"];
    temp=fromUart["temp"];
    totalMeshEnergy=fromUart["totalMeshEnergy"];
    selfEnergytotal=fromUart["energyTotal"];
    relay1Status=fromUart["R1status"];
    relay2Status=fromUart["R2status"];
    relay3Status=fromUart["R3status"];

    StaticJsonDocument <800> toWeb;
    toWeb["connectedMesh"]=meshNumber;
    toWeb["node"]= node ;
    toWeb["energy1"]=energyPzem1;
    toWeb["energy2"]=energyPzem2;
    toWeb["energy3"]=energyPzem3;
    toWeb["isTripR1"]=relay1Trip;
    toWeb["isTripR2"]=relay2Trip;
    toWeb["isTripR3"]=relay3Trip;
    toWeb["temp"]=temp;
    toWeb["totalMeshEnergy"]=totalMeshEnergy;
    toWeb["energyTotal"]=selfEnergytotal;
    toWeb["R1status"]=relay1Status;
    toWeb["R2status"]=relay2Status;
    toWeb["R3status"]=relay3Status;
    String json;
    serializeJson(toWeb,json);
    Serial.println(json);
    websockets.broadcastTXT(json);
    message_ready = false;
  }
  }
 
  websockets.loop();
  userScheduler.execute();
  FirabaseConnect();

}
//_____________________________________________________________________



void funcMode(int wifiState){//para ni sa magset sa mode 
      if (wifiState==0)//mode 1 is access
      {
        WiFi.disconnect();
        mode="A.P";
        IPAddress local_IP(192,168,4,22);
        IPAddress gateway(192,168,4,9);
        IPAddress subnet(255,255,255,0);
        WiFi.mode(WIFI_AP);
        Serial.println(WiFi.softAPConfig(local_IP, gateway, subnet) ? "Ready" : "Failed!");
        WiFi.softAP(ap_ssid.c_str(), ap_password.c_str());//name of wifi AP
        Serial.println("A.P");
      
      }
      if(wifiState==1){//STA mod
          mode="S.T.A";
          WiFi.mode(WIFI_STA); //Optional
          WiFi.begin(sta_ssid.c_str(), sta_password.c_str());
          Serial.println("\nConnecting");
          Serial.println(mode);
          while(WiFi.status() != WL_CONNECTED) { 
            Serial.print(".");
            delay(5000);
            break;
          }
        }          
        if (wifiState==2)
          {
            if (WiFi.isConnected())
              {
                // config.api_key = API_KEY;  
                // config.database_url = DATABASE_URL;
                // Serial.println("Mode: S.T.A");
                // mode="S.T.A.O";
                // Serial.println(mode);
                return;
              }else
              {
              WiFi.stopSmartConfig();
              }
          }  
}


void apSettings(String ssid , String pass){
  
    preferences.begin("credentials", false);
    preferences.putString("ap_ssid", ssid); 
    preferences.putString("ap_password", pass);
    preferences.end();
    Serial.println("saved");
 
  
 
}
void staSettings(String ssid , String pass){
 
     preferences.begin("credentials", false);
    preferences.putString("sta_ssid", ssid); 
    preferences.putString("sta_password", pass);
    preferences.end();
}
void meshSettings(String ssid , String pass){
  if (ssid!=mesh_ssid)
  {
  preferences.begin("credentials", false);
  preferences.putString("mesh_ssid", ssid); 
  preferences.putString("mesh_password", pass);
  preferences.end();
  }
  

}
void InitAp(){
  preferences.begin("credentials", true);
  ap_ssid=preferences.getString("ap_ssid","");
  ap_password=preferences.getString("ap_password","");
  sta_ssid=preferences.getString("sta_ssid","");
  sta_password=preferences.getString("sta_password","");
  mesh_ssid=preferences.getString("ap_ssid","");
  mesh_password=preferences.getString("mesh_password","");
  if (ap_ssid == ""){
    ap_ssid="EEKO101";
    ap_password="";
  }
  if (sta_ssid=="")
  {
    sta_ssid="Capstone Server";
    sta_password="Krizzel0908";
  }
  
  preferences.end();
}
void InitSta(){
  return;
   
}void InitMesh(){
     return;
}
void MeshConfig(){
    // if(button2.numberKeyPresses==0){
    //     StaticJsonDocument <100>fromc;
    //     fromc["Control"]=4;
    //     fromc["type"]=0;
    //     serializeJson(fromc,Serial2);
    //     button2.pressed = false;
    // }u
    //  if(button2.numberKeyPresses==1){
    //     StaticJsonDocument <100>fromc;
    //     fromc["Control"]=4;
    //     fromc["type"]=1;
    //     fromc["MeshSsid"]=mesh_ssid;
    //     fromc["MeshPass"]=mesh_password;
    //     serializeJson(fromc,Serial2);
    //     button2.pressed = false;
    // }
}
void FirabaseConnect(){
    if (WL_CONNECTED)
    {
      if (mode=="S.T.A.O")
  {
    
    if (millis() - sendDataPrevMillis > 1000)
    {
  
    HTTPClient http;
    String serverPath;
    serverPath.concat("https://markycapstoneproject.000webhostapp.com/savedata.php?node=");
    serverPath.concat(String(node));
    serverPath.concat("&meshNum=");
    serverPath.concat(String(meshNumber));
    serverPath.concat("&energy1=");
    serverPath.concat(String(energyPzem1));
    serverPath.concat("&energy2=");
    serverPath.concat(String(energyPzem2));
    serverPath.concat("&energy3=");serverPath.concat(String(energyPzem3));
    serverPath.concat("&totalEnergy=");serverPath.concat(String(selfEnergytotal));
    serverPath.concat("&totalMeshEnergy=");serverPath.concat(String(totalMeshEnergy));
    serverPath.concat("&R1status=");serverPath.concat(String(relay1Status));
    serverPath.concat("&R2status=");serverPath.concat(String(relay2Status));
    serverPath.concat("&R3status=");serverPath.concat(String(relay3Status));
    serverPath.concat("&temp=");serverPath.concat(String(temp));
    serverPath.concat("&isTrip1=");serverPath.concat(String(relay1Trip));
    serverPath.concat("&isTrip2=");serverPath.concat(String(relay2Trip));
    serverPath.concat("&isTrip3=");serverPath.concat(String(relay3Trip));
    http.begin(serverPath.c_str());
    int httpResponseCode = http.GET();
   if (httpResponseCode>0) {
        Serial.print("HTTP Response code: ");
        
        Serial.println(httpResponseCode);
        String payload = http.getString();
        Serial.println("enetered");
      }
      else {
        Serial.print("Error code: ");
        Serial.println(httpResponseCode);
      }
     
      // Free resources
    http.end();
    //read database
    http.begin("https://markycapstoneproject.000webhostapp.com/getControl.php"); //Specify the URL
    int httpCode = http.GET();                                        //Make the request
  
    if (httpCode > 0) { //Check for the returning code
  
        String payload = http.getString();
        Serial.println(httpCode);
        Serial.println(payload);
       
        DynamicJsonDocument getData(1080);
        DeserializationError error = deserializeJson(getData, payload);
        int nodecontrol=getData[0]["node"];
        control=getData[0]["control"];
        int Rcon =getData[0]["Rcon"];
        int Rnum=getData[0]["Rnum"];
        int Tnum=getData[0]["Tnum"];
        int Tcon=getData[0]["Tcon"];
        int Tstat=getData[0]["Tstat"];
        int Tcount=getData[0]["Tcount"];
        
        DynamicJsonDocument sendUartDatabase(1080);
        sendUartDatabase["node"]=nodecontrol;
        sendUartDatabase["Control"]=control;
        sendUartDatabase["Rnum"]=Rnum;
        sendUartDatabase["Rcon"]=Rcon;
        sendUartDatabase["Tnum"]=Tnum;
        sendUartDatabase["Tcon"]=Tcon;
        sendUartDatabase["Tstat"]=Tstat;
        sendUartDatabase["Tcount"]=Tcount;

        serializeJson(sendUartDatabase,Serial2);
        
      }
  
    else {
      Serial.println("Error on HTTP request");
    }
  
    http.end(); //Free the resources
    /////write to database
     http.begin("https://markycapstoneproject.000webhostapp.com/restartControl.php?control=20");
        httpResponseCode = http.GET();
       if (httpResponseCode>0) {
        Serial.print("HTTP Response code: ");
        Serial.println(httpResponseCode);
        String payload = http.getString();
        Serial.println(payload);
      }
      else {
        Serial.print("Error code: ");
        Serial.println(httpResponseCode);
      }
      
      // Free resources
      http.end();
    ///////////////////////////////////
  }
    }
}
 
}
  `;


    const containerStyle = {
        maxHeight: 500, // Set the maximum height as needed
        overflowY: 'auto',
        padding: '16px',
      };
    
      const codeStyle = {
        fontFamily: 'monospace',
        whiteSpace: 'pre-wrap',
        color:'red'
      };
    
  return (
    <Grid style={containerStyle}>
    <Typography>
      <code style={codeStyle}>{arduinoProgram}</code>
    </Typography>
  </Grid>
  )
}

export default outlethandling
import React from 'react'
import { Grid, Typography } from '@mui/material'
const Pzemhandling = () => {
    
    const arduinoProgram = `
    //for mesh board
    #include <Arduino.h>
    #include <painlessMesh.h>
    #include <PZEM004Tv30.h>
    #include <ArduinoJson.h>
    #include <HardwareSerial.h>
    #include <ACS712.h>
    #include <DHT.h>
     #include <Adafruit_Sensor.h>
     #include <freertos/FreeRTOS.h>
    #include <freertos/task.h>
    #include <HardwareSerial.h>
    //dht11
    #define DHTPIN 27
    #define DHTTYPE DHT11
    DHT dht(DHTPIN,DHTTYPE);
    // put function declarations here:
    #if !defined(PZEM_RX_PIN) && !defined(PZEM_TX_PIN)
    #define PZEM_RX_PIN 16
    #define PZEM_TX_PIN 17
    #endif
    #if !defined(PZEM_SERIAL)
    #define PZEM_SERIAL Serial2
    #endif
    #if defined(ESP32)
    PZEM004Tv30 pzem1(PZEM_SERIAL, PZEM_RX_PIN, PZEM_TX_PIN,0x01);
    PZEM004Tv30 pzem2(PZEM_SERIAL, PZEM_RX_PIN, PZEM_TX_PIN,0x02);
    PZEM004Tv30 pzem3(PZEM_SERIAL, PZEM_RX_PIN, PZEM_TX_PIN,0x03);
    #elif defined(ESP8266)
    #else
    PZEM004Tv30 pzem(PZEM_SERIAL);
    #endif
    #define Relay1 13
    #define Relay2 26
    #define Relay3 14
    #define TempSensor 27
    #define greenLed1 21
    #define greenLed2 22
    #define greenLed3 19
    #define redLed1 15
    #define redLed2 18
    #define redLed3 23
    #define asc712_1 33
    #define asc712_2 32
    #define asc712_3 34
    #define buzzer 25
    //HardwareSerial EspPzem(1);
    //PZEM004Tv30 pzem1(PZEM_SERIAL, PZEM_RX_PIN, PZEM_TX_PIN);
    ACS712 sensor_1_current(ACS712_05B, asc712_1);
    ACS712 sensor_2_current(ACS712_05B, asc712_2);
    ACS712 sensor_3_current(ACS712_05B, asc712_3);
    //PZEM004Tv30 pzem3(Serial2, PZEM_RX_PIN, PZEM_TX_PIN,0x07);
    int pzemCurrentLimit=10;
    bool isTimerActive1=false;
    bool isTimerActive2=false;
    bool isTimerActive3=false;
    //variabke sekectuon
    float selfEnergy1;
    float selfEnergy2;
    float selfEnergy3;
    float selfenergyTotal;
    float totalMeshEnergy;
    int deviceStatusCheck;
    int devicenumber=1.5;
    int DeviceNode = 0;
    int DeviceNodeControl = 0;
    int meshNumConnected=0;
    String uartRecieveMessage="";
    String uartSendMessage="";
    String meshMessage="";
    //for relaysss
    bool onRelay1Trip=false;
    bool onRelay2Trip=false;
    bool onRelay3Trip=false;
    bool firstInit1=true;
    bool firstInit2=true;
    int tripNum;
    int isTrip;
    int relay1IsTrip=0;//0 is okay / 1 is not
    int relay2IsTrip=0;
    int relay3IsTrip=0;
    /// to store the timer from the mesh message
    float selfTimer1count=0;
    float selfTimer2count=0;
    float selfTimer3count=0;
    float timerCountUpdate1;
    float timerCountUpdate2;
    float timerCountUpdate3;
    int selfTimercontrol1=1;
    int selfTimercontrol2=1;
    int selfTimercontrol3=1;
    int onActiveTimer1;
    int onActiveTimer2;
    int onActiveTimer3;
    //fpr mesh and from recieiving Uart
    int relayNumber;
    int relayControl;
    int relay1Status;
    int relay2Status;
    int relay3Status;
    int timerNumber=1;
    int timerStatus=1;
    int timerControl=1;
    float timercount;
    float temperature;
    String apSsid;
    String apPass;
    String staSsid;
    String staPass;
    String meshSsid;
    String meshPass;
    //variable for mesh
    int control;
    bool checkPhantomR1=false;
    bool checkPhantomR2=false;
    bool checkPhantomR3=false;
    int mVperAmp = 66;// this the 5A version of the ACS712 -use 100 for 20A Module and 66 for 30A Module
    double AmpsRMS = 0;
    unsigned long last_time =0;
    unsigned long current_time =0;
    double AmpsRMS_2 = 0;
    double AmpsRMS_3 = 0;
    //initfunctions
    void getPzemData();
    void sendMeshMessage();
    void checkForFault();
    void ledInit();
    void Timer();
    void timerRelay1();
    void timerRelay2();
    void timerRelay3();
    void Buzz(String status);
    void getVPP();
    void LedReset();
    void PhantomLoadOff();
    void ControlReset(){
      control=10;
    }
    void tempVal();
    // WiFi Credentials for Mesh Networking
    #define   MESH_PREFIX     "EEK0101JOHNMARK"
    #define   MESH_PASSWORD   "EEK0101JOHNMARK"
    #define   MESH_PORT       5555
    
    painlessMesh  mesh;
    
    //init Tasks
    Scheduler userScheduler;
    Task taskGetPzemData( TASK_SECOND * 1 , TASK_FOREVER, &getPzemData );
    Task taskSendMessageMesh( TASK_SECOND * 1 , TASK_FOREVER, &sendMeshMessage );
    Task timeSecond(TASK_SECOND*1,TASK_FOREVER,&Timer);
    Task temptask(TASK_SECOND*3,TASK_FOREVER,&tempVal);
    Task TurnOffPhantomLoad(TASK_SECOND*10,TASK_FOREVER,&PhantomLoadOff);
    //mesh
    
    unsigned long lastProcessedTime = 0;
    const unsigned long processingInterval = 10;
    void receivedCallback( uint32_t from, String &msg )
    {
      //Deserializing
      String json;
      DynamicJsonDocument fromMeshdoc(1024);
      json = msg.c_str();
      DeserializationError error = deserializeJson(fromMeshdoc, json);
      //Serial.printf("Received from %u msg=%s\n", from, msg.c_str());
      if (error)
      {
        Serial.print("deserializeJson() failed: ");
        Serial.println(error.c_str());
      }
      meshNumConnected=fromMeshdoc["connectedMesh"];
      DeviceNodeControl=fromMeshdoc["nodeControl"];
      control=fromMeshdoc["control"];
      relayNumber=fromMeshdoc["Rnum"];
      relayControl=fromMeshdoc["Rcon"];
      timerNumber=fromMeshdoc["Tnum"];
      timerStatus=fromMeshdoc["Tstat"];
      timerControl=fromMeshdoc["Tcont"];
      timercount=fromMeshdoc["Tcount"];
      totalMeshEnergy=fromMeshdoc["totalMeshEnergy"];
      
      if(control==4){
        Buzz("Off");
       
        if (onRelay1Trip)
        {
          digitalWrite(greenLed1,LOW);
          digitalWrite(redLed1,LOW);
          onRelay1Trip=false;
          relay1IsTrip=0;
          AmpsRMS=0;
          
        }if (onRelay2Trip)
        {
          digitalWrite(greenLed2,LOW);
          
          digitalWrite(redLed2,LOW);
          onRelay2Trip=false;
          relay2IsTrip=0;
          AmpsRMS_2=0;
          
        }if (onRelay3Trip)
        {
          digitalWrite(greenLed3,LOW);
          digitalWrite(redLed3,LOW);
          onRelay3Trip=false;
          relay3IsTrip=0;
          AmpsRMS_3=0;
          
        }
        
      }
      if (DeviceNodeControl==devicenumber)
      {  
          if (!onRelay1Trip)
          {
            if (control==0)
            {
              if (relayNumber==1)
                {
                  digitalWrite(Relay1,relayControl);
                  relay1Status=relayControl;
                  digitalWrite(greenLed1,!relayControl);
                  checkPhantomR1=true;
                }
            }
            if(control==1){
               if (timerNumber==1)
                  {
                    selfTimer1count=timercount;
                    
                    if (selfTimer1count>0)
                    {
                    isTimerActive1=true;
                    Serial.println("pass");
                    }
                    Serial.println(selfTimer1count);
                    selfTimercontrol1=timerControl;
                    onActiveTimer1=timerStatus;
                    Serial.println("onActievtimer"+String(onActiveTimer1));
                  }
            }     
          }
          if (!onRelay2Trip)
          {
            if (control==0)
            {
              if (relayNumber==2)
                {
                digitalWrite(Relay2,relayControl);
                relay2Status=relayControl;
                digitalWrite(greenLed2,!relayControl);
                checkPhantomR2=true;
                }
            }
            if (control==1)
            {
              if (timerNumber==2)
              {
                selfTimer2count=timercount;
                if (selfTimer2count>0)
                {
                  isTimerActive2=true;
                }
                
                selfTimercontrol2=timerControl;
                onActiveTimer2=timerStatus;
              }
            }
            
            
              
          }
          if (!onRelay3Trip)
          {
            if (control==0)
            {
              if (relayNumber==3)
              {
                digitalWrite(Relay3,relayControl);
                relay3Status=relayControl;
                digitalWrite(greenLed3,!relayControl);
                checkPhantomR3=true;
              }
            }
            
           if (control==1)
           {
            if (timerNumber==3)
              {
                selfTimer3count=timercount;
                if (selfTimer3count>0)
                {
                  isTimerActive3=true;
                }
                selfTimercontrol3=timerControl;
                onActiveTimer3=timerStatus;
              }
           }
           
            
          }
      }
      serializeJson(fromMeshdoc,Serial2);
      // unsigned long currentTime = millis();
      // if (currentTime - lastProcessedTime >= processingInterval) {
      //   DynamicJsonDocument ToUart(1024);
    
      //   lastProcessedTime = currentTime;
      // }
     
      
    
      //board_status = doc["board"];
      //led = doc["pin"];
      //led_status = doc["status"];
      //Serial.print("Data "); Serial.println(led_status_1);
      //if (board_status == 1){digitalWrite(led, led_status);}
    }
    //_____________________________________________________________
    void newConnectionCallback(uint32_t nodeId) {
      Serial.printf("New Connection, nodeId = %u\n", nodeId);
    }
    
    void changedConnectionCallback() {
      Serial.printf("Changed connections\n");
    }
    
    void nodeTimeAdjustedCallback(int32_t offset) {
     // Serial.printf("Adjusted time %u. Offset = %d\n", mesh.getNodeTime(),offset);
    }
    //______________________________________________________________
    TaskHandle_t Task1;
    TaskHandle_t Task2;
    
    //Task1code: blinks an LED every 1000 ms
    void Task1code( void * pvParameters ){
      Serial.print("Task1 running on core ");
      Serial.println(xPortGetCoreID());
    
      for(;;){
        mesh.update();
        if (Serial1.available())
      {
        uartRecieveMessage=Serial1.readString();
        //Serial.print("Received Message - "); Serial.println(uartRecieveMessage);
        DynamicJsonDocument fromUartDoc(1024);
        DeserializationError error = deserializeJson(fromUartDoc,uartRecieveMessage);
        if (error)
        {
          Serial.print("deserializeJson() failed: ");
          Serial.println(error.c_str());
        }
         meshNumConnected=fromUartDoc["connectedMesh"];
         DeviceNodeControl=fromUartDoc["node"];
         control=fromUartDoc["control"];
         relayNumber=fromUartDoc["Rnum"];
         relayControl=fromUartDoc["Rcon"];
         timerNumber=fromUartDoc["Tnum"];
         timerStatus=fromUartDoc["Tstat"];
         timerControl=fromUartDoc["Tcont"];
         timercount=fromUartDoc["Tcount"];
         totalMeshEnergy=fromUartDoc["totalMeshEnergy"];
      
      if(control==4){
        Buzz("Off");
       
        if (onRelay1Trip)
        {
          digitalWrite(greenLed1,LOW);
          digitalWrite(redLed1,LOW);
          onRelay1Trip=false;
          relay1IsTrip=0;
          AmpsRMS=0;
          
        }if (onRelay2Trip)
        {
          digitalWrite(greenLed2,LOW);
          
          digitalWrite(redLed2,LOW);
          onRelay2Trip=false;
          relay2IsTrip=0;
          AmpsRMS_2=0;
          
        }if (onRelay3Trip)
        {
          digitalWrite(greenLed3,LOW);
          digitalWrite(redLed3,LOW);
          onRelay3Trip=false;
          relay3IsTrip=0;
          AmpsRMS_3=0;
          
        }
        
      }
      if (DeviceNodeControl==devicenumber)
      {  
          if (!onRelay1Trip)
          {
            if (control==0)
            {
              if (relayNumber==1)
                {
                  digitalWrite(Relay1,relayControl);
                  relay1Status=relayControl;
                  digitalWrite(greenLed1,!relayControl);
                  checkPhantomR1=true;
                  Serial.println("ON");
                }
            }
            if(control==1){
               if (timerNumber==1)
                  {
                    selfTimer1count=timercount;
                    
                    if (selfTimer1count>0)
                    {
                    isTimerActive1=true;
                    Serial.println("pass");
                    }
                    Serial.println(selfTimer1count);
                    selfTimercontrol1=timerControl;
                    onActiveTimer1=timerStatus;
                    Serial.println("onActievtimer"+String(onActiveTimer1));
                  }
            }     
          }
          if (!onRelay2Trip)
          {
            if (control==0)
            {
              if (relayNumber==2)
                {
                digitalWrite(Relay2,relayControl);
                relay2Status=relayControl;
                digitalWrite(greenLed2,!relayControl);
                checkPhantomR2=true;
                }
            }
            if (control==1)
            {
              if (timerNumber==2)
              {
                selfTimer2count=timercount;
                if (selfTimer2count>0)
                {
                  isTimerActive2=true;
                }
                
                selfTimercontrol2=timerControl;
                onActiveTimer2=timerStatus;
              }
            }
            
            
              
          }
          if (!onRelay3Trip)
          {
            if (control==0)
            {
              if (relayNumber==3)
              {
                digitalWrite(Relay3,relayControl);
                relay3Status=relayControl;
                digitalWrite(greenLed3,!relayControl);
                checkPhantomR3=true;
              }
            }
            
           if (control==1)
           {
            if (timerNumber==3)
              {
                selfTimer3count=timercount;
                if (selfTimer3count>0)
                {
                  isTimerActive3=true;
                }
                selfTimercontrol3=timerControl;
                onActiveTimer3=timerStatus;
              }
           }
           
            
          }
      }
        }
        
        
        //Serial.println(timercount);
      }
        
    
      
    }
    
    //Task2code: blinks an LED every 700 ms
    void Task2code( void * pvParameters ){
      Serial.print("Task2 running on core ");
      Serial.println(xPortGetCoreID());
    
      for(;;){
        checkForFault();
        vTaskDelay(5);
        
      }
    }
    
    
    void setup() {
       Serial1.begin(115200,SERIAL_8N1, 2, 4);
       sensor_1_current.setZeroPoint(0);
       sensor_1_current.calibrate();
        sensor_2_current.setZeroPoint(0);
       sensor_2_current.calibrate();
       sensor_3_current.setZeroPoint(0);
       sensor_3_current.calibrate();
      Serial.begin(115200);
      //relayInit
      ledInit();
      pinMode(Relay1, OUTPUT);
      digitalWrite(Relay1,HIGH);
      pinMode(Relay2, OUTPUT);
      digitalWrite(Relay2,HIGH);
       pinMode(Relay3, OUTPUT);
      digitalWrite(Relay3,HIGH);
    //mesh int
      mesh.setDebugMsgTypes( ERROR | STARTUP );  // set before init() so that you can see startup messages
    
      mesh.init( MESH_PREFIX, MESH_PASSWORD, &userScheduler, MESH_PORT );
      mesh.onReceive(&receivedCallback);
      mesh.onNewConnection(&newConnectionCallback);
      mesh.onChangedConnections(&changedConnectionCallback);
      mesh.onNodeTimeAdjusted(&nodeTimeAdjustedCallback);
    /////////////////////////////////////////////////////////////
    //--------init user schedule-------------------
      
      userScheduler.addTask(taskGetPzemData);
      userScheduler.addTask( taskSendMessageMesh);
      userScheduler.addTask( timeSecond);
      userScheduler.addTask(temptask);
      userScheduler.addTask(TurnOffPhantomLoad);
      taskGetPzemData.enable();
      taskSendMessageMesh.enable();
      timeSecond.enable();
      temptask.enable();
      TurnOffPhantomLoad.enable();
      //Task1code: blinks an LED every 1000 ms
     //create a task that will be executed in the Task1code() function, with priority 1 and executed on core 0
      xTaskCreatePinnedToCore(
                        Task1code,   /* Task function. */
                        "Task1",     /* name of task. */
                        10000,       /* Stack size of task */
                        NULL,        /* parameter of the task */
                        0,           /* priority of the task */
                        &Task1,      /* Task handle to keep track of created task */
                        0);          /* pin task to core 0 */                  
      delay(500); 
    
      //create a task that will be executed in the Task2code() function, with priority 1 and executed on core 1
      xTaskCreatePinnedToCore(
                        Task2code,   /* Task function. */
                        "Task2",     /* name of task. */
                        10000,       /* Stack size of task */
                        NULL,        /* parameter of the task */
                        1,           /* priority of the task */
                        &Task2,      /* Task handle to keep track of created task */
                        1);          /* pin task to core 1 */
        delay(500); 
      
    }
    
    
    void loop() {
      //checkForFault();
      //mesh.update();
    
     // userScheduler.execute();
     
    }
    void  getPzemData(){
      
        selfEnergy1=pzem1.energy();
      selfEnergy2=pzem2.energy();
      selfEnergy3=pzem3.energy();
     
      
      selfenergyTotal=selfEnergy1+selfEnergy2+selfEnergy3;
      totalMeshEnergy=selfenergyTotal;
    }
    void checkForFault(){
      if(!onRelay1Trip){
        if (AmpsRMS >pzemCurrentLimit)
          {
            onRelay1Trip=true;
            digitalWrite(Relay1,1);
            relay1IsTrip=1;//if there is a trip
            tripNum=1;//relay trip number
            Buzz("On");
            Serial.println("On Socket 1");
            digitalWrite(greenLed1,LOW);
            digitalWrite(redLed1,HIGH);
          }
          else
          {
            AmpsRMS = sensor_1_current.getCurrentAC(60);
            relay1IsTrip= 0;
            
           
              
          }
      }
      if(!onRelay2Trip){
        if (AmpsRMS_2 >pzemCurrentLimit)
          {
            onRelay2Trip=true;
            digitalWrite(Relay2,1);
            relay2IsTrip=1;//if there is a trip
            tripNum=2;//relay trip number
            Buzz("On");
            Serial.println("On Socket 2");
            digitalWrite(greenLed2,LOW);
            digitalWrite(redLed2,HIGH);
            
          }
          else
          {
          AmpsRMS_2 = sensor_2_current.getCurrentAC(60);
         relay2IsTrip= 0;
            
          }
      }
      if(!onRelay3Trip){
        if (AmpsRMS_3 >pzemCurrentLimit)
          {
            onRelay3Trip=true;
            digitalWrite(Relay3,1);
            relay3IsTrip=1;//if there is a trip
            tripNum=3;//relay trip number
            Buzz("On");
            Serial.println("On Socket 3");
            digitalWrite(greenLed3,LOW);
            digitalWrite(redLed3,HIGH);
          }
          else
          {
          AmpsRMS_3 = sensor_3_current.getCurrentAC(60);
          relay3IsTrip= 0;
            
          }
      }
      
    
    }
    
    void sendMeshMessage(){
       DynamicJsonDocument meshDocs(1024);
       meshDocs["connectedMesh"]=meshNumConnected;
       meshDocs["node"]=devicenumber;
       meshDocs["control"]=control;
       meshDocs["nodeControl"]=DeviceNodeControl;
       meshDocs["energy1"]=selfEnergy1;
       meshDocs["energy2"]=selfEnergy2;
       meshDocs["energy3"]=selfEnergy3;
       meshDocs["energyTotal"]=selfenergyTotal;
       meshDocs["totalMeshEnergy"]=totalMeshEnergy;
       meshDocs["Rcon"]=relayControl;//on/off control of relay
       meshDocs["Rnum"]=relayNumber;//wich relay t control
       meshDocs["R1status"]=relay1Status;//statis of relay 1
       meshDocs["R2tatus"]=relay2Status;//status ofg relay 2
       meshDocs["R3tatus"]=relay3Status;//status of relay 3
       meshDocs["Tnum"]=timerNumber;//wich relay to put timer
       meshDocs["Tstat"]=timerStatus;//if relay timer is to be on or off
       meshDocs["Tcont"]=timerControl;
       meshDocs["Tcount"]=timercount;//timer count
       meshDocs["temp"]=temperature;
       meshDocs["tripnum"]=tripNum;
       meshDocs["isTripR1"]=relay1IsTrip;
       meshDocs["isTripR2"]=relay2IsTrip;
       meshDocs["isTripR3"]=relay3IsTrip;
       //meshDocs["apSsid"]=apSsid;
      // meshDocs["apPass"]=apPass;
       //meshDocs["staSsid"]=staSsid;
       //meshDocs["staPass"]=staPass;
       //meshDocs["staSsid"]=meshSsid;
       //meshDocs["staPass"]=meshPass;
       
       String msg ;
       serializeJson(meshDocs, msg);
       mesh.sendBroadcast( msg );
       meshDocs["connectedMesh"]=1;
       serializeJson(meshDocs, Serial1);
    }
    
    void ledInit(){
      pinMode(greenLed1,OUTPUT);
      pinMode(greenLed2,OUTPUT);
      pinMode(greenLed3,OUTPUT);
      pinMode(redLed1,OUTPUT);
      pinMode(redLed2,OUTPUT);
      pinMode(redLed3,OUTPUT);
      pinMode(buzzer,OUTPUT);
      pinMode(asc712_1,INPUT);
      pinMode(asc712_2,INPUT);
      pinMode(asc712_3,INPUT);
    
      digitalWrite(greenLed1,LOW);
      digitalWrite(greenLed2,LOW);
      digitalWrite(greenLed3,LOW);
      digitalWrite(redLed1,LOW);
      digitalWrite(redLed2,LOW);
      digitalWrite(redLed3,LOW);
      digitalWrite(buzzer,LOW);
      
    }
    
    void Timer(){
     
        timerRelay1();
        timerRelay2();
        timerRelay3();
    }
    
    void timerRelay1(){
        if(!onRelay1Trip){
          if(selfTimercontrol1==0){
            if (selfTimer1count>0)
                {
                selfTimer1count-=1;
                Serial.println(selfTimer1count);
                }else{
                 if (isTimerActive1)
                 {
                        if (onActiveTimer1==0)
                      {
                          digitalWrite(Relay1,0);
                          relay1Status=0;
                          digitalWrite(redLed1,LOW);
                          digitalWrite(greenLed1,HIGH);
                          selfTimercontrol1=1;
                          
                          
                      }if (onActiveTimer1==1)
                      {
                        digitalWrite(Relay1,1);
                        relay1Status=1;
                        selfTimercontrol1=1;
                        digitalWrite(redLed1,LOW);
                        digitalWrite(greenLed1,LOW);
                        checkPhantomR1=true;
                      }
                      isTimerActive1=false;
                      //Serial.println(selfTimer1count);
                 }
                 
            }
           
         }else{
           return;
         } 
        }
        
    }
    void timerRelay2(){
        if(selfTimercontrol2==0){
          if (selfTimer2count>0)
            {
                selfTimer2count-=1;
            }else{
                 if (isTimerActive2)
                 {
                      if (onActiveTimer2==0)
                    {
                        digitalWrite(Relay2,0);
                        relay2Status=0;
                        digitalWrite(greenLed2,HIGH);
                        selfTimercontrol2=1;
                    }else
                    {
                        digitalWrite(Relay2,1);
                        relay2Status=1;
                        selfTimercontrol2=1;
                        digitalWrite(redLed1,LOW);
                        digitalWrite(greenLed2,LOW);
                        checkPhantomR2=true;
                    }
                    isTimerActive2=false;
                 }
                 
            }
           
         }else{
           return;
         } 
    }
    void timerRelay3(){
        if(selfTimercontrol3==0){
          if (selfTimer3count>0)
            {
                selfTimer3count-=1;
            }else{
                if (isTimerActive3)
                {
                      if (onActiveTimer3==0)
                    {
                        digitalWrite(Relay3,0);
                        relay3Status=0;
                        digitalWrite(greenLed3,HIGH);
                        selfTimercontrol3=1;
                    }else
                    {
                        digitalWrite(Relay3,1);
                        relay3Status=1;
                        selfTimercontrol3=1;
                        digitalWrite(redLed1,LOW);
                        digitalWrite(redLed1,LOW);
                        digitalWrite(greenLed3,LOW);
                        checkPhantomR3=true;
                    }
                    isTimerActive3=false;
                }
                
            }
           
         }else{
           return;
         } 
    }
    
    void Buzz(String status){
      if (status=="On"){
        digitalWrite(buzzer,HIGH);
      }else{
        digitalWrite(buzzer,LOW);
      }
      
    }
    
    
    
    void LedReset(){
      digitalWrite(greenLed1,LOW);
      digitalWrite(redLed1,LOW);
      digitalWrite(greenLed2,LOW);
      digitalWrite(greenLed3,LOW);
      
      digitalWrite(redLed2,LOW);
      digitalWrite(redLed3,LOW);
    }
    void tempVal(){
      temperature=dht.readTemperature();
      //Serial.println(temperature);
    
    }
    
    
    void PhantomLoadOff(){
        float pzemCurrent1=pzem1.current();
        float pzemCurrent2=pzem2.current();
        float pzemCurrent3=pzem3.current();
      if(checkPhantomR1=true){
           if (relay1Status=1)
          {
              if (pzemCurrent1<0.001)
              {
                digitalWrite(Relay1,1);
                digitalWrite(greenLed1,0);
                relay1Status=0;
                checkPhantomR1=false;
              }
              
          } 
      }
    
      if (checkPhantomR2=true)
      {
         if (relay2Status=1)
          {
              if (pzemCurrent2<0.001)
              {
                digitalWrite(Relay2,1);
                digitalWrite(greenLed2,0);
                relay2Status=0;
                checkPhantomR2=false;
              }
              
          }
      }
      if(checkPhantomR3){
         if (relay3Status=1)
          {
              if (pzemCurrent3<0.001)
              {
                digitalWrite(Relay3,1);
                digitalWrite(greenLed3,0);
                relay3Status=0;
                 checkPhantomR3=false;
              }
              
          }
      }
      
    
    
    
      
      
    }
  
 
 
    `;

  const containerStyle = {
    maxHeight: 500, // Set the maximum height as needed
    overflowY: 'auto',
    padding: '16px',
  };

  const codeStyle = {
    fontFamily: 'monospace',
    whiteSpace: 'pre-wrap',
    color:'red'
  };


  return (
    <Grid style={containerStyle}>
      <Typography>
        <code style={codeStyle}>{arduinoProgram}</code>
      </Typography>
    </Grid>
  )
}

export default Pzemhandling
import React from 'react'
import CardMedia from '@mui/material/CardMedia';
import { Container,Typography,Box } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import CallIcon from '@mui/icons-material/Call';
import MailIcon from '@mui/icons-material/Mail';
import RoomIcon from '@mui/icons-material/Room';
import useMediaQuery from '@mui/material/useMediaQuery';
import SchoolIcon from '@mui/icons-material/School';
import Groups3Icon from '@mui/icons-material/Groups3';
import TerminalIcon from '@mui/icons-material/Terminal';
import MemoryIcon from '@mui/icons-material/Memory';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import PsychologyIcon from '@mui/icons-material/Psychology';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import EngineeringIcon from '@mui/icons-material/Engineering';
import ThreeDRotationIcon from '@mui/icons-material/ThreeDRotation';
import PaletteIcon from '@mui/icons-material/Palette';
const myInfo={
    name:'JOhn Mark V. Desamparado',
    num:'+639454511985',
    course:'Bachelor of Science in Electrical Engineering (CIT-U)',
    schoolgrad:'Cebu Institute of Technology University',
    seniorhigh:'Bantayan Science High School (Senior High)',
    juniorhgh:'Bantayan Southern Institute',
    elem:'Kabangbang Elementary School',
    scholar:"DOST 2019 Scholar",
    address:'San Isidro, Kabangbang, Bantayan',
    currentAdress:'Sambag 1 Urgello Cebu City',
    birthDate:'June 29 ,2000',
    nationality:'Filipino',
    gmail1:'desamparado0908@gmail.com',
    gmail2:'johnmarkvoltron@gmail.com'
}
const Profile = () => {
  const isMobile = useMediaQuery('(max-width:600px)');
  return (


    <Container sx={{ mt:3 }}>
    <Grid container spacing={4} columns={16} >
        <Grid xs={isMobile ? 16 : 6} >
                
                 <CardMedia
                        component="img"
                        height={isMobile ? 450: 500}
                        image="home/mainImg.jpg"
                        alt="Paella dish"
                        sx={{borderRadius:{ xs:15, md: 5 ,backgroundColor:'rgba(1,1,1,0.1)',borderRadius:5 },boxShadow: '0 5px 10px rgba(0, 0, 0, 0.8)',}}
                    />
         
        </Grid>
        
        <Grid xs={isMobile ? 16 : 10}>
        <Box sx={{backgroundColor:'rgb(0,0,0,0.2)', borderRadius:2,boxShadow: '0 5px 10px rgba(0, 0, 0, 0.8)',textShadow: '5px 5px 10px rgba(0, 0, 0, 0.8)'}}>
                <Typography variant={isMobile ? 'h4' : 'h3'} sx={{fontFamily:'serif',color:'white'}}> John Mark V. Desamparado</Typography>
                <Typography variant='p'  sx={{color:'white',fontSize:{xs:15 ,md:20}}}>
                  {myInfo.course}
                </Typography>
                <br />
                <Typography variant='p'  sx={{color:'white',fontSize:{xs:15 ,md:20}}}>
                  {myInfo.scholar}
                </Typography>
                <br /><br />
                <Typography variant='p'  sx={{color:'white',fontSize:{xs:15 ,md:20}}}>
                  <CallIcon/>  {myInfo.num}
                </Typography>
                <br />
                <Typography variant='p'  sx={{color:'white',fontSize:{xs:15 ,md:20}}}>
                  <MailIcon/>  {myInfo.gmail1}  / {myInfo.gmail2}
                </Typography>
            
                <br />
                <Typography variant='p'  sx={{color:'white',fontSize:{xs:15 ,md:20}}}>
                  <RoomIcon/>  {myInfo.address} / {myInfo.currentAdress}
                </Typography>
                <br /><br />
                <Typography variant='h5' sx={{color:'white'}}>
                    <SchoolIcon/> Education:
                </Typography>
                <br />
                <Typography variant='p'  sx={{color:'white',fontSize:{xs:15 ,md:20},ml:5}}>
                  Tertiary:
                </Typography>
                <br />
                <Typography variant='p'  sx={{color:'white',fontSize:{xs:15 ,md:20},ml:10}}>
                  BS in Electrical Engineering
                </Typography>
                <br />
                
                <Typography variant='p'  sx={{color:'white',fontSize:{xs:15 ,md:20} ,ml:10}}>
                 {myInfo.schoolgrad} 
                </Typography>
                <br />
                <Typography variant='p'  sx={{color:'white',fontSize:{xs:15 ,md:20},ml:10}}>
                  2023-2024
                </Typography>
                <br />
                <br />
                <Typography variant='p'  sx={{color:'white',fontSize:{xs:15 ,md:20},ml:5}}>
                  Secondary:
                </Typography>
                <br />
                <Typography variant='p'  sx={{color:'white',fontSize:{xs:15 ,md:20},ml:10}}>
                 STEM Strand
                </Typography>
                <br />
                
                <Typography variant='p'  sx={{color:'white',fontSize:{xs:15 ,md:20} ,ml:10}}>
                {myInfo.seniorhigh} 
                </Typography>
                <br />
                <Typography variant='p'  sx={{color:'white',fontSize:{xs:15 ,md:20},ml:10}}>
                 2018-2019
                </Typography>
                <br /><br />
                <Typography variant='p'  sx={{color:'white',fontSize:{xs:15 ,md:20} ,ml:10}}>
               {myInfo.juniorhgh} 
                </Typography>
                <br />
                <Typography variant='p'  sx={{color:'white',fontSize:{xs:15 ,md:20},ml:10}}>
                 2013-2014
                 
                </Typography>
           
                
         </Box>
        </Grid>
    
    </Grid>
    <br /><br />
    <Grid  container spacing={4} columns={16}  sx={{backgroundColor:'rgb(0,0,0,0.2)', borderRadius:2,boxShadow: '0 5px 10px rgba(0, 0, 0, 0.8)',}}>      
        <Grid xs={isMobile ? 16 :8} sx={{textShadow: '5px 5px 10px rgba(0, 0, 0, 0.8)'}} >
                  <Typography variant='h5' sx={{color:'white'}}>
                    <PsychologyIcon/> Knowledge in the following:
                    </Typography>
                    <br />    
                    <Typography variant='p'  sx={{color:'white',fontSize:{xs:21,md:25} ,ml:2}}>
                    <DeveloperModeIcon/> Web Development:
                    </Typography>
                    <br />
                    <Typography variant='p'  sx={{color:'white',fontSize:{xs:18,md:23} ,ml:7}}>
                    &#8226; Javascript
                    </Typography><br />
                    <Typography variant='p'  sx={{color:'white',fontSize:{xs:18,md:23},ml:7}}>
                    &#8226; HTML
                    </Typography><br />
                    <Typography variant='p'  sx={{color:'white',fontSize:{xs:18,md:23} ,ml:7}}>
                    &#8226; CSS
                    </Typography>
                    <br />
                    <Typography variant='p'  sx={{color:'white',fontSize:{xs:18,md:23},ml:7}}>
                    &#8226; React.js
                    </Typography><br />
                    <Typography variant='p'  sx={{color:'white',fontSize:{xs:18,md:23} ,ml:7}}>
                    &#8226; Bootstrap
                    </Typography><br />
                    <Typography variant='p'  sx={{color:'white',fontSize:{xs:18,md:23} ,ml:7}}>
                    &#8226;  MUI
                    </Typography>
                    <br /><br />
                    <Typography variant='p'  sx={{color:'white',fontSize:{xs:21,md:25} ,ml:2}}>
                    <SportsEsportsIcon/> Game Development:
                    </Typography>
                    <br />
                    <Typography variant='p'  sx={{color:'white',fontSize:{xs:18,md:23} ,ml:7}}>
                        &#8226;  C# (Unity)
                    </Typography><br />
                    <Typography variant='p'  sx={{color:'white',fontSize:{xs:18,md:23} ,ml:7}}>
                        &#8226; Javascript (Construct 3)
                    </Typography>
                    <br />
                    <Typography variant='p'  sx={{color:'white',fontSize:{xs:18,md:23} ,ml:7}}>
                        &#8226; Game Design
                    </Typography>
                    <br />  <br />
                    <Typography variant='p'  sx={{color:'white',fontSize:{xs:21,md:25} ,ml:2}}>
                        <MemoryIcon/> MicroController/Processor:
                    </Typography>
                    <br />
                    <Typography variant='p'  sx={{color:'white',fontSize:{xs:18,md:23} ,ml:7}}>
                        &#8226; Arduino (C)
                    </Typography>
                    <br />
                    <Typography variant='p'  sx={{color:'white',fontSize:{xs:18,md:23} ,ml:7}}>
                        &#8226; ESP32 (C)(MircoPython)
                    </Typography>
                    <br />
                    <Typography variant='p'  sx={{color:'white',fontSize:{xs:18,md:23} ,ml:7}}>
                        &#8226; Raspberry pi (Python)
                    </Typography>
           </Grid>
                      
        <Grid xs={isMobile ? 16 :8} sx={{textShadow: '5px 5px 10px rgba(0, 0, 0, 0.8)'}}>
         <Grid display={{xs:'none',md:'flex'}}> <br /> <br />  <br /></Grid>
         <Typography variant='p'  sx={{color:'white',fontSize:{xs:21,md:25} ,ml:2}}>
                    <EngineeringIcon/> Engineering Knowledge:
                    </Typography>
              <br />
                    <Typography variant='p'  sx={{color:'white',fontSize:{xs:18,md:23} ,ml:5}}>
                       &#8226; Circuit Design
                    </Typography>
                    <br />
                    <Typography variant='p'  sx={{color:'white',fontSize:{xs:18,md:23} ,ml:5}}>
                       &#8226; Solar Design
                    </Typography>
                    <br />
                    <Typography variant='p'  sx={{color:'white',fontSize:{xs:18,md:23} ,ml:5}}>
                       &#8226; Electropneumatic
                    </Typography>
                    <br />
                    <Typography variant='p'  sx={{color:'white',fontSize:{xs:18,md:23} ,ml:5}}>
                       &#8226; Programmable Logic Controller
                    </Typography>
                    <br />
                    <Typography variant='p'  sx={{color:'white',fontSize:{xs:18,md:23} ,ml:5}}>
                       &#8226; Electrical Design
                    </Typography>
                    <br />
                    <Typography variant='p'  sx={{color:'white',fontSize:{xs:18,md:23} ,ml:5}}>
                       &#8226; Instrumentation and Controls of Motors
                    </Typography>
                    <br />   <br />
                    <Typography variant='p'  sx={{color:'white',fontSize:{xs:21,md:25} ,ml:2}}>
                    <ThreeDRotationIcon/> 3D Modeling:
                    </Typography>
                    <br />
                    <Typography variant='p'  sx={{color:'white',fontSize:{xs:18,md:23} ,ml:5}}>
                       &#8226; Autocad
                    </Typography>
                    <br />
                    <Typography variant='p'  sx={{color:'white',fontSize:{xs:18,md:23} ,ml:5}}>
                       &#8226; Blender
                    </Typography>
                    <br />   <br />
                    <Typography variant='p'  sx={{color:'white',fontSize:{xs:21,md:25} ,ml:2}}>
                    <PaletteIcon/> 2D Arts:
                    </Typography>
                <br />
                    <Typography variant='p'  sx={{color:'white',fontSize:{xs:18,md:23} ,ml:5}}>
                       &#8226; Krita
                    </Typography>
                    <br />
                    <Typography variant='p'  sx={{color:'white',fontSize:{xs:18,md:23} ,ml:5}}>
                       &#8226; aseprite
                    </Typography>
                    
                   
                    
           </Grid>
        </Grid>
      
        
         
         


 </Container>

 
 

  )
}

export default Profile
import React from 'react'
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Unstable_Grid2'
import { Container,Typography ,Link} from '@mui/material'
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Pzemhandling from './pzemhandling';
import Outlethandling from './outlethandling';
import ThesisDiscriptions, { Client1, Client2,Client3,Client4 }  from './ThesisDiscriptions';
import { Client1Prog, Client2Prog, Client3Prog ,Client4Prog} from './clientPrograms';
import useMediaQuery from '@mui/material/useMediaQuery';
import Divider from '@mui/material/Divider';

const thesisTitle=[
    {title:'BROWSER-BASED HOME AUTOMATION OUTLET AS ACCESS POINT AND STATION POINT MODE WITH ENERGY METER, MESH NETWORK, AND SHORT CIRCUIT PROTECTION'},
    {title:'ARDUINO-BASED MONITORING AND PROTECTION SYSTEM FOR SINGLE-PHASE TRANSFORMER USING SENSORS AND GSM TECHNOLOGY'},
    {title:'DEVELOPMENT OF ARDUINO-BASED SMART MONITORING SYSTEM IN SHORT CIRCUIT AND OVERLOAD DETECTION FOR DISTRIBUTION SYSTEM IN CIT - UNIVERSITY'},
    {title:'DEVELOPMENT OF RECYCLABLE DOMESTIC SOLID WASTE CLASSIFIER USING DEEP LEARNING MODEL'},
    {title:'COMPARATIVE ANALYSIS OF A SQUARE AND CYLINDRICAL POLYPROPYLENE MESH FOG WATER COLLECTOR'},
]
 const ThesisClient1 = () => {
    const [value, setValue] = React.useState('1');
    const isMobile = useMediaQuery('(max-width:600px)');
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  return (
    <Box sx={{ width: '100%',backgroundColor: 'rgba(0,0,0,0.3)',color:'white' ,borderRadius:4,boxShadow: '0 5px 10px rgba(0, 0, 0, 0.8)',} }>
        <br />
        <Typography variant={isMobile? 'subtitle2':'h6'} sx={{color:'white' , textShadow: '4px 4px 10px rgba(0, 0, 0, 0.8)'}}>{"Title : " +thesisTitle[1].title}</Typography>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example" indicatorColor="secondary"   textColor="secondary.light">
                                <Tab label="Prototype" value="1"  sx={{color:"white"  , boxShadow: '0 0 2px rgba(0, 0, 0, 0.8)', textShadow: '4px 4px 10px rgba(0, 0, 0, 0.8)'}}/>
                                <Tab label="Descriptions" value="2"  sx={{color:"white",  boxShadow: '0 0 2px rgba(0, 0, 0, 0.8)', textShadow: '4px 4px 10px rgba(0, 0, 0, 0.8)'}}/>
                                <Tab label="Programs" value="3"   sx={{color:"white",  boxShadow: '0 0 2px rgba(0, 0, 0, 0.8)', textShadow: '4px 4px 10px rgba(0, 0, 0, 0.8)'}}/>
                            </TabList>
                        </Box>
                        <TabPanel value="1">
                        <Grid container   spacing={isMobile?2:5}  columns={isMobile?8:16} >
                            <Grid xs={isMobile?12:8}>
                                <iframe width="100%" height={isMobile?"200":"500"}  columns={isMobile?8:16}
                                                title="Video Player" src={"https://www.youtube.com/embed/3hWVomqqjjI"}>
                                </iframe>
                            </Grid>
                            <Grid xs={8}>
                            <Divider   sx={{height: '2px', marginY: 2,backgroundColor: 'white',display:{xs:'flex',md:'none'},boxShadow: '0 5px 10px rgba(0, 0, 0, 0.8)',  }}> </Divider>
                                <ImageList sx={{ width: {xs:'100%',md:500}, height: {xs:200,md:450} ,boxShadow: '0 5px 10px rgba(0, 0, 0, 0.8)',}}  variant="woven" cols={3} gap={8}>
                                    {
                                        ThesisCLient1.map((item)=>(
                                        <ImageListItem key={item.img}  sx={{boxShadow: '0 5px 10px rgba(0, 0, 0, 0.8)',}}>
                                            <img
                                            srcSet={`${item.img}?w=161&fit=crop&auto=format&dpr=2 2x`}
                                            src={`${item.img}?w=161&fit=crop&auto=format`}
                                            alt={item.title}
                                            loading="lazy"
                                            />
                                        </ImageListItem>

                                        ))
                                    }
                                    </ImageList>
                            </Grid>
                            </Grid>
                        </TabPanel>
                        <TabPanel value="2">
                                    <Client1></Client1>
                        </TabPanel>
                        <TabPanel value="3">
                            <Client1Prog ></Client1Prog> 
                        </TabPanel>
                    </TabContext>
                </Box>           
  )
}


const ThesisClient2 = () => {
    const [value, setValue] = React.useState('1');
    const isMobile = useMediaQuery('(max-width:600px)');
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  return (
    <Box sx={{ width: '100%' ,color:'white' ,borderRadius:4, boxShadow: '0 5px 10px rgba(0, 0, 0, 0.8)',backgroundColor: 'rgba(0,0,0,0.3)'} }>
        <br />
        <Typography  variant={isMobile? 'subtitle2':'h6'} sx={{color:'white' , textShadow: '4px 4px 10px rgba(0, 0, 0, 0.8)'}}>{"Title : " +thesisTitle[2].title}</Typography>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example"  indicatorColor="secondary"   textColor="secondary.light">
                                <Tab label="Prototype" value="1"  sx={{color:"white" , boxShadow: '0 0 2px rgba(0, 0, 0, 0.8)', textShadow: '4px 4px 10px rgba(0, 0, 0, 0.8)'}}/>
                                <Tab label="Descriptions" value="2"  sx={{color:"white" , boxShadow: '0 0 2px rgba(0, 0, 0, 0.8)', textShadow: '4px 4px 10px rgba(0, 0, 0, 0.8)'}}/>
                                <Tab label="Programs" value="3"   sx={{color:"white" , boxShadow: '0 0 2px rgba(0, 0, 0, 0.8)', textShadow: '4px 4px 10px rgba(0, 0, 0, 0.8)'}}/>
                            </TabList>
                        </Box>
                        <TabPanel value="1">
                        <Grid container spacing={isMobile?2:5}  columns={isMobile?8:16} >
                            <Grid xs={isMobile?12:8}>
                                <iframe width="100%"  height={isMobile?"200":"500"}
                                                title="Video Player" src={"https://www.youtube.com/embed/oh1GtW_TSeM"}>
                                </iframe>
                            </Grid>
                            <Grid xs={8}>
                            <Divider   sx={{height: '2px', marginY: 2,backgroundColor: 'white',display:{xs:'flex',md:'none'},boxShadow: '0 5px 10px rgba(0, 0, 0, 0.8)',  }}> </Divider>
                                <ImageList sx={{ width: {xs:'100%',md:500}, height: {xs:200,md:450} ,boxShadow: '0 5px 10px rgba(0, 0, 0, 0.8)',}}  variant="woven" cols={3} gap={8}>
                                    {
                                        ThesisClient2image.map((item)=>(
                                        <ImageListItem key={item.img}  sx={{boxShadow: '0 5px 10px rgba(0, 0, 0, 0.8)',}}>
                                            <img
                                            srcSet={`${item.img}?w=161&fit=crop&auto=format&dpr=2 2x`}
                                            src={`${item.img}?w=161&fit=crop&auto=format`}
                                            alt={item.title}
                                            loading="lazy"
                                            />
                                        </ImageListItem>

                                        ))
                                    }
                                    </ImageList>
                            </Grid>
                            </Grid>
                        </TabPanel>
                        <TabPanel value="2">
                                  <Client2></Client2>
                        </TabPanel>
                        <TabPanel value="3">
                                   <Client2Prog></Client2Prog>
                        </TabPanel>
                    </TabContext>
                </Box>           
  )
}

const ThesisClient3 = () => {
    const [value, setValue] = React.useState('1');
    const isMobile = useMediaQuery('(max-width:600px)');
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  return (
    <Box sx={{ width: '100%',backgroundColor: 'rgba(0,0,0,0.3)',color:'white' ,borderRadius:4,boxShadow: '0 5px 10px rgba(0, 0, 0, 0.8)',} }>
        <br />
        <Typography  variant={isMobile? 'subtitle2':'h6'} sx={{color:'white' , textShadow: '4px 4px 10px rgba(0, 0, 0, 0.8)'}}>{"Title : " +thesisTitle[3].title}</Typography>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example" indicatorColor="secondary"   textColor="secondary.light">
                            <Tab label="Prototype" value="1"  sx={{color:"white"  , boxShadow: '0 0 2px rgba(0, 0, 0, 0.8)', textShadow: '4px 4px 10px rgba(0, 0, 0, 0.8)'}}/>
                                <Tab label="Descriptions" value="2"  sx={{color:"white",  boxShadow: '0 0 2px rgba(0, 0, 0, 0.8)', textShadow: '4px 4px 10px rgba(0, 0, 0, 0.8)'}}/>
                                <Tab label="Programs" value="3"   sx={{color:"white",  boxShadow: '0 0 2px rgba(0, 0, 0, 0.8)', textShadow: '4px 4px 10px rgba(0, 0, 0, 0.8)'}}/>
                            </TabList>
                        </Box>
                        <TabPanel value="1">
                        <Grid container spacing={isMobile?2:5}  columns={isMobile?8:16} >
                            <Grid xs={isMobile?12:8}>
                                <iframe width="100%"   height={isMobile?"200":"500"}
                                                title="Video Player" src={"https://www.youtube.com/embed/vsQ9k7Iet84"}>
                                </iframe>
                            </Grid>
                            <Grid xs={8}>
                            <Divider   sx={{height: '2px', marginY: 2,backgroundColor: 'white',display:{xs:'flex',md:'none'},boxShadow: '0 5px 10px rgba(0, 0, 0, 0.8)',  }}> </Divider>
                                <ImageList sx={{ width: {xs:'100%',md:500}, height: {xs:200,md:450} ,boxShadow: '0 5px 10px rgba(0, 0, 0, 0.8)',}}  variant="woven" cols={3} gap={8}>
                                    {
                                        ThesisClient3image.map((item)=>(
                                        <ImageListItem key={item.img}  sx={{boxShadow: '0 5px 10px rgba(0, 0, 0, 0.8)',}} >
                                            <img
                                            srcSet={`${item.img}?w=161&fit=crop&auto=format&dpr=2 2x`}
                                            src={`${item.img}?w=161&fit=crop&auto=format`}
                                            alt={item.title}
                                            loading="lazy"
                                            />
                                        </ImageListItem>

                                        ))
                                    }
                                    </ImageList>
                            </Grid>
                            </Grid>
                        </TabPanel>
                        <TabPanel value="2">
                                  <Client3></Client3>
                        </TabPanel>
                        <TabPanel value="3">
                            <Client3Prog></Client3Prog>
                        </TabPanel>
                    </TabContext>
                </Box>           
  )
}

const ThesisClient4 = () => {
    const [value, setValue] = React.useState('1');
    const isMobile = useMediaQuery('(max-width:600px)');
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  return (
    <Box sx={{ width: '100%',backgroundColor: 'rgba(0,0,0,0.3)',color:'white' ,borderRadius:4,boxShadow: '0 5px 10px rgba(0, 0, 0, 0.8)',} }>
        <br />
        <Typography  variant={isMobile? 'subtitle2':'h6'} sx={{color:'white' , textShadow: '4px 4px 10px rgba(0, 0, 0, 0.8)'}}>{"Title : " +thesisTitle[4].title}</Typography>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example" indicatorColor="secondary"   textColor="secondary.light">
                                <Tab label="Prototype" value="1"  sx={{color:"white"  , boxShadow: '0 0 2px rgba(0, 0, 0, 0.8)', textShadow: '4px 4px 10px rgba(0, 0, 0, 0.8)'}}/>
                                <Tab label="Discriptions" value="2"  sx={{color:"white",  boxShadow: '0 0 2px rgba(0, 0, 0, 0.8)', textShadow: '4px 4px 10px rgba(0, 0, 0, 0.8)'}}/>
                                <Tab label="Programs" value="3"   sx={{color:"white",  boxShadow: '0 0 2px rgba(0, 0, 0, 0.8)', textShadow: '4px 4px 10px rgba(0, 0, 0, 0.8)'}}/>
                            </TabList>
                        </Box>
                        <TabPanel value="1">
                        <Grid container spacing={isMobile?2:5}  columns={isMobile?8:16} >
                            <Grid xs={8}>
                                <iframe width="100%"  height={isMobile?"200":"500"}
                                                title="Video Player" src={"https://www.youtube.com/embed/SlvVtsNmrcE"}>
                                </iframe>
                            </Grid>
                            <Grid xs={8}>
                            <Divider   sx={{height: '2px', marginY: 2,backgroundColor: 'white',display:{xs:'flex',md:'none'},boxShadow: '0 5px 10px rgba(0, 0, 0, 0.8)',  }}> </Divider>
                                <ImageList sx={{ width: {xs:'100%',md:500}, height: {xs:200,md:450} ,boxShadow: '0 5px 10px rgba(0, 0, 0, 0.8)',}}  variant="woven" cols={3} gap={8}>
                                    {
                                        ThesisCLient4image.map((item)=>(
                                        <ImageListItem key={item.img} sx={{boxShadow: '0 5px 10px rgba(0, 0, 0, 0.8)',}}>
                                            <img
                                            srcSet={`${item.img}?w=161&fit=crop&auto=format&dpr=2 2x`}
                                            src={`${item.img}?w=161&fit=crop&auto=format`}
                                            alt={item.title}
                                            loading="lazy"
                                            />
                                        </ImageListItem>

                                        ))
                                    }
                                    </ImageList>
                            </Grid>
                            </Grid>
                        </TabPanel>
                        <TabPanel value="2">
                                 <Client4></Client4>
                        </TabPanel>
                        <TabPanel value="3">
                            <Client4Prog></Client4Prog>
                        </TabPanel>
                    </TabContext>
                </Box>           
  )
}
const EngineeringProj = () => {
    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    const isMobile = useMediaQuery('(max-width:600px)');
  return (
        

    <Container sx={{mt:3}}>
        <Grid container spacing={1} columns={16} sx={{display:{ xs: 'flex', md: 'flex' }}}>
            <Grid xs={16}>
                <Typography variant={isMobile? 'h6':'h4'} sx={{color:"white" ,textShadow: '4px 4px 10px rgba(0, 0, 0, 0.8)'}}>
                    DOST CLUSTER RICE THIRD PLACER -Sibol Award for Outstanding College Student Creative Research
                </Typography> 
               <br></br>
                 <Link sx={{color:"red", textShadow: '0 0 1px rgba(0, 0, 0, 0.8)',fontSize:20}} href="https://region7.dost.gov.ph/visayas-innovators-shine-during-the-regional-invention-contest-and-exhibit/" target="_blank">
                     Dost website here
                </Link>
                
                
            </Grid>
            <Grid xs={isMobile ? 16 : 8}>
                <CardMedia
                    component="img"
                    height={360}
                    image="/home/CV5.jpg"
                    sx={{  
                                    borderRadius:15 ,
                                 
                                    boxShadow: '0 5px 10px rgba(0, 0, 0, 0.8)', 
                                
                                }}
                >
                </CardMedia>
            </Grid>
            <Grid xs={8} sx={{display:{xs:'none' , md:'flex'}}}>
                <CardMedia
                    component="img"
                    height={360}
                    image="home/CV6.jpg"
                    sx={{    borderRadius:15 ,
                        borderBlockStyle:'solid', 
                        boxShadow: '0 5px 10px rgba(0, 0, 0, 0.8)', 
                        
                                }}
                >
                </CardMedia>
            </Grid>
            <Grid xs={16} sx={{borderRadius:4,     boxShadow: '0 5px 10px rgba(0, 0, 0, 0.8)', }}>
                <Typography variant='body1' sx={{color:"white" ,textAlign:'justify' ,fontSize:{xs:13,md:17} ,textShadow: '4px 4px 10px rgba(0, 0, 0, 0.8)'}}>
                  The Regional Invention Contest and Exhibits (RICE) is a nationwide activity conducted by the Technology Application and Promotion Institute of the Department of Science and Technology (DOST-TAPI) in different regions to recognize the indispensability of the Filipino inventors in the Philippine society and in the landscape of national economic development. The Regional Invention Contest (RIC) shall encourage inventors to utilize their innovativeness through competition and with prizes to look out for. RIC serves as the qualifying round for the National Invention Contest, pursuant to Section 4 of the Republic Act (RA) No. 7459 also known as “Inventors and Invention Incentives Act of the Philippines”.
                </Typography>
                </Grid>
           
            <Grid xs={18}>
            <Divider   sx={{
          height: '2px', // Set the thickness as needed
          marginY: 2, // Adjust the margin as needed
          backgroundColor: 'white', // Change the background color
          boxShadow: '0 5px 10px rgba(0, 0, 0, 0.8)', 
        }}> </Divider>
                <Typography variant='h4' sx={{textAlign:'center',color:'white',fontWeight:'bold',textShadow: '4px 4px 10px rgba(0, 0, 0, 0.8)'}}> MY THESIS</Typography>
            </Grid>
            
          
            <Grid xs={18}>
            <Grid container spacing={4} columns={{xs:16 ,md:16}}>
                  
                  <Grid xs={isMobile? 6:3}>
                  <br />
                  <CardMedia
                      component="img"
                      height={200}
                      image="chibi/fig2.jpg"
                      sx={{   border:'black',
                                      borderRadius:15 ,
                                      boxShadow: '0 5px 10px rgba(0, 0, 0, 0.8)', 
                                      
                                  }}
                  >
                  </CardMedia>
                  </Grid>     
                  <Grid xs={isMobile?10:8}>
                      <br />   <br />   <br />
                      <Box sx={{backgroundColor:'InfoBackground', borderRadius:2,    boxShadow: '0 5px 10px rgba(0, 0, 0, 0.8)', }}>
                          <Typography  sx={{color:'black' ,textAlign:'justify' , fontSize:{xs:15,md:20},textShadow: '1px 1px 2px rgba(0, 0, 0, 0.8)'}}> 
                          Throughout the course of my college thesis, I served as the team leader, overseeing responsibilities as both the lead programmer and circuit designer.
                                      
                          </Typography>            
                      </Box>
                      
                      </Grid>         
              </Grid>
                <Box sx={{ width: '100%',color:'white' ,borderRadius:4, boxShadow: '0 5px 10px rgba(0, 0, 0, 0.8)',backgroundColor: 'rgba(0,0,0,0.3)',} }>
                  <br />
                <Typography variant={isMobile? 'subtitle2':'h6'} sx={{color:'white' ,textAlign:'justify',  textShadow: '4px 4px 10px rgba(0, 0, 0, 0.8)'}}>{"Title : " +thesisTitle[0].title}</Typography>
                    <TabContext value={value} >
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example" indicatorColor="secondary"   textColor="secondary.light">
                                <Tab label="Prototype" value="1"  sx={{color:"white" ,boxShadow: '0 0 2px rgba(0, 0, 0, 0.8)', textShadow: '4px 4px 10px rgba(0, 0, 0, 0.8)'}}/>
                                <Tab label="Descriptions" value="2"  sx={{color:"white",boxShadow: '0 0 2px rgba(0, 0, 0, 0.8)', textShadow: '4px 4px 10px rgba(0, 0, 0, 0.8)'}}/>       
                                <Tab label="Programs" value="3"   
                                    sx={{color:"white",
                                        boxShadow: '0 0 2px rgba(0, 0, 0, 0.8)',
                                        textShadow: '4px 4px 10px rgba(0, 0, 0, 0.8)'
                                        }}/>
                        
                            </TabList>
                        </Box>
                        <TabPanel value="1">
                        <Grid container spacing={isMobile?2:5}  columns={isMobile?8:16} >
                            <Grid xs={isMobile?12:8}>
                                <iframe width="100%" height={isMobile?"200":"500"}
                                                title="Video Player" src={"https://www.youtube.com/embed/vfLalWsYQJ8"}>
                                </iframe>
                               
                            </Grid>
                           
                            <Grid xs={isMobile?10:8}>
                            <Divider   sx={{height: '2px', marginY: 2,backgroundColor: 'white',display:{xs:'flex',md:'none'},boxShadow: '0 5px 10px rgba(0, 0, 0, 0.8)',  }}> </Divider>
                                <ImageList sx={{ width: {xs:'100%',md:500}, height: {xs:200,md:450},boxShadow: '0 5px 10px rgba(0, 0, 0, 0.8)', }} variant="woven" cols={3} gap={8}>
                                    {
                                        MyThesisImages.map((item)=>(
                                        <ImageListItem key={item.img}   sx={{boxShadow: '0 5px 10px rgba(0, 0, 0, 0.8)',}}>
                                            <img
                                            srcSet={`${item.img}?w=161&fit=crop&auto=format&dpr=2 2x`}
                                            src={`${item.img}?w=161&fit=crop&auto=format`}
                                            alt={item.title}
                                            loading="lazy"
                                          
                                            />
                                        </ImageListItem>

                                        ))
                                    }
                                    </ImageList>
                            </Grid>
                            </Grid>
                        </TabPanel>
                        <TabPanel value="2">
                                <ThesisDiscriptions></ThesisDiscriptions>
                        </TabPanel>
                        <TabPanel value="3">
                        <       Grid container spacing={5} columns={isMobile?8:16} >
                                    <Grid xs={8}>
                                    <typography> Outlet Handling </typography>
                                    <Pzemhandling></Pzemhandling>
                                    </Grid>
                                    <Grid xs={8}>
                                    <typography> WEB Handling </typography>
                                    <Outlethandling></Outlethandling>
                                    </Grid>
                            </Grid>
                            </TabPanel>
                    </TabContext>
                </Box>           
               
            </Grid>
            <Grid xs={18}>
            <Divider   sx={{height: '2px', marginY: 2,backgroundColor: 'white',boxShadow: '0 5px 10px rgba(0, 0, 0, 0.8)',  }}> </Divider>
                <Typography variant='h4' sx={{textAlign:'center',color:'white',fontWeight:'bold',textShadow: '4px 4px 10px rgba(0, 0, 0, 0.8)'}}> CLIENT THESIS</Typography>
                <br />
                <Grid container spacing={1} columns={16}>
                    <Grid xs={isMobile?10:12}>
                        <br /> 
                        <Box sx={{backgroundColor:'InfoBackground', borderRadius:2 , boxShadow: '0 5px 10px rgba(0, 0, 0, 0.8)', }}>
                    <Typography  sx={{color:'black' ,textAlign:'justify' ,fontSize:{xs:15,md:20},textShadow: '1px 1px 2px rgba(0, 0, 0, 0.8)'}}> 
                    In the course of my fourth year in college, I engaged in professional collaboration by 
                    accepting clients for their research projects as a proficient programmer.  
                    I played a key part in making prototypes, carefully designing wiring diagrams, and creating advanced 3D illustrations. 
                    I also actively participated in testing the prototypes to make sure they worked smoothly.

                        </Typography>
                        </Box>
                    </Grid>
                    <Grid  xs={isMobile?6:3}>
                    <CardMedia
                        component="img"
                        height={200}
                        image="chibi/fig1.jpg"
                        sx={{   border:'black',
                                        borderRadius:15 ,
                                        
                                        borderWidth:10, boxShadow: '0 5px 10px rgba(0, 0, 0, 0.8)', 
                                    }}
                    >
                    </CardMedia>
                    </Grid>              
                </Grid>
            </Grid>
            <Grid xs={18}>
                <ThesisClient1></ThesisClient1>
            </Grid>
            <Grid xs={18}>
                <ThesisClient2></ThesisClient2>
            </Grid>
            <Grid xs={18}>
                <ThesisClient3></ThesisClient3>
            </Grid>
            <Grid xs={18}>
                <ThesisClient4></ThesisClient4>
            </Grid>
            
        </Grid>

      

    </Container>
  )
}
const MyThesisImages=[
        {
            img:'/thesis/mythesis/image1a.jpg',
            title:''
        },
        {
            img:'/thesis/mythesis/imag2a.jpg',
            title:''
        },

        {
            img:'/thesis/mythesis/image1.jpg',
            title:''
        },

        {
            img:'/thesis/mythesis/image2.jpg',
            title:''
        },

        {
            img:'/thesis/mythesis/image3a.jpg',
            title:''
        },

        {
            img:'/thesis/mythesis/image3.jpg',
            title:''
        },

        {
            img:'/thesis/mythesis/image4.jpg',
            title:''
        },
        {
            img:'/thesis/mythesis/image5.jpg',
            title:''
        },
        {
            img:'/thesis/mythesis/image6.jpg',
            title:''
        },


]
const ThesisCLient1=[
    {
        img:'/thesis/Client 1/image1.jpg',
        title:''
    },
    {
        img:'/thesis/Client 1/image2.jpg',
        title:''
    },
    {
        img:'/thesis/Client 1/image3.jpg',
        title:''
    },
    {
        img:'/thesis/Client 1/image4.jpg',
        title:''
    },{
        img:'/thesis/Client 1/image5.jpg',
        title:''
    },
    {
        img:'/thesis/Client 1/image6.jpg',
        title:''
    },
    {
        img:'/thesis/Client 1/image7.jpg',
        title:''
    },
]
const ThesisClient2image=[
    {img:'/thesis/Client2/feature2.JPG'},
    {img:'/thesis/Client2/featured.JPG'},
    {img:'/thesis/Client2/image1.jpg'},
    {img:'/thesis/Client2/image2.jpg'},
    {img:'/thesis/Client2/image3.jpg'},
]

const ThesisClient3image=[
    {img:'/thesis/Client3/fig1.png'},
    {img:'/thesis/Client3/fig2.png'},
    {img:'/thesis/Client3/fig3.jpg'},
    {img:'/thesis/Client3/fig4.jpg'},
    {img:'/thesis/Client3/fig5.jpg'},
]
const ThesisCLient4image=[
    {img:'/thesis/Client4/fig1.png'},
    {img:'/thesis/Client4/fig2.png'},
    {img:'/thesis/Client4/fig3.png'},
    {img:'/thesis/Client4/fig4.png'},
]
export default EngineeringProj
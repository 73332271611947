import React,{ useEffect } from 'react'
import {Route , Routes, useLocation} from 'react-router-dom'

import NavBar from './components/NavBar/NavBar';
import Home from './components/Home/Home';
import GameDev from './components/GameDevelopment/GameDev';
import EngineeringProj from './components/EngineeringProjects/EngineeringProj';
import Profile from './components/Profile/Profile';

const App = () => {
  const location = useLocation();

  useEffect(() => {
    // Scroll to the top of the page on route change
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return (
    <div>
        <div style={{ position: 'fixed', top: 0, width: '100%', zIndex: 1000 }}>
  <NavBar />
</div>
       
       <div style={{paddingTop:40}} >
       <Routes>
                <Route path='/' element={<Home/>} />
                <Route path='/Home' element={<Home/>} />
                <Route path='/Game Development' element={<GameDev/>} />
                <Route path='/Engineering  Projects' element={<EngineeringProj/>} />
                <Route path='/Profile' element={<Profile/>} />
        </Routes>
       </div>
    </div>
    
        
  )
};

export default App


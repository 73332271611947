import React from 'react'
import Box from '@mui/material/Box';

import CardMedia from '@mui/material/CardMedia';
import { Typography } from '@mui/material';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
   
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    
  };const Gamedev = () => {
   
    return (
      <Box sx={style}>
           
      </Box>
    )
  }
const Gameproof = () => {
   
  return (
    <Box sx={style}>
        <Typography>heusub</Typography>
         <CardMedia
                        component="img"
                        height={900}
                        image="/proof/2p.jpg"
                        sx={{  
                                        borderRadius:15 
                                        ,boxShadow: '0 5px 10px rgba(0, 0, 0, 0.8)',
                                    }}
                    >
                    </CardMedia>
    </Box>
  )
}
const Gameproof1=()=>{
    return (
        <Box sx={style}>
             <CardMedia
                            component="img"
                            height={900}
                            image="/proof/mini1234.jpg"
                            sx={{  
                                            borderRadius:15 
                                            ,boxShadow: '0 5px 10px rgba(0, 0, 0, 0.8)',
                                        }}
                        >
                        </CardMedia>
        </Box>
      )
}

const Gameproof2=()=>{
    return (
        <Box sx={style}>
             <CardMedia
                            component="img"
                            height={900}
                            image="/proof/4p.jpg"
                            sx={{  
                                            borderRadius:15 
                                            ,boxShadow: '0 5px 10px rgba(0, 0, 0, 0.8)',
                                        }}
                        >
                        </CardMedia>
        </Box>
      )
}
const Gameproof3=()=>{
    return (
        <Box sx={style}>
             <CardMedia
                            component="img"
                            height={900}
                            image="/proof/retro.jpg"
                            sx={{  
                                            borderRadius:15 
                                            ,boxShadow: '0 5px 10px rgba(0, 0, 0, 0.8)',
                                        }}
                        >
                        </CardMedia>
        </Box>
      )
}
const Gameproof4=()=>{
    return (
        <Box sx={style}>
             <CardMedia
                            component="img"
                            height={900}
                            image="/proof/casual.jpg"
                            sx={{  
                                            borderRadius:15 
                                            ,boxShadow: '0 5px 10px rgba(0, 0, 0, 0.8)',
                                        }}
                        >
                        </CardMedia>
        </Box>
      )
}
const Gameproof5=()=>{
    return (
        <Box sx={style}>
             <CardMedia
                            component="img"
                            height={900}
                            image="/proof/shiba.jpg"
                            sx={{  
                                            borderRadius:15 
                                            ,boxShadow: '0 5px 10px rgba(0, 0, 0, 0.8)',
                                        }}
                        >
                        </CardMedia>
        </Box>
      )
}
export default Gameproof
export{Gameproof1,Gameproof2,Gameproof3,Gameproof4,Gameproof5,Gamedev}
import { Container,Typography,Link,Box } from '@mui/material'
import React , { useState } from 'react'
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Unstable_Grid2'
import useMediaQuery from '@mui/material/useMediaQuery';
import Divider from '@mui/material/Divider';

import Button from '@mui/material/Button';
import Gameproof,{Gameproof1,Gameproof2,Gameproof3,Gameproof4,Gameproof5} from './GameProof';
import Backdrop from '@mui/material/Backdrop';
const mygames = [
    {icon:'',title:'Review',vedioid:'TFyRpm2KH28',discription:<Gameproof1/>},
    {icon:'',title:'Review',vedioid:'cZlQZ-pGkyc',discription:<Gameproof/>},

    {icon:'',title:'2 player game offline',vedioid:'ZUoquiqELjk',discription:<Gameproof/>},
    {icon:'',title:'Mini 1 2 3 4 Player Game',vedioid:'L7B6b0OvrgA',discription:<Gameproof1/>},
    {icon:'',title:'1 2 3 4 Player Challenge:The Player',vedioid:'ZW4ztr-pH6U',discription:<Gameproof2/>},
   
    {icon:'',title:'Retro 2 Player Offline Game',vedioid:'9gz7jycrVxM',discription:<Gameproof3/>},
    {icon:'',title:'Mini Casual Games',vedioid:'31Lct63-Lcw',discription:<Gameproof4/>},
    {icon:'',title:'Shiba Inu To the Moon ',vedioid:'5cnQvaUMlIg',discription:<Gameproof5/>},
   /// {icon:'',title:'',vedioid:'',discription:''},

]
const htmlGames=[
    {icon:'Games/4pair.png' , title:'4Pair',link:'Games/4pair/index.html'},
    {icon:'Games/angle.png' , title:'Angle Shot',link:'Games/4pair/angleshot.html'},
    {icon:'Games/black.png' , title:'Black n White',link:'Games/blackwhite/index.html'},
    {icon:'Games/ninja.png' , title:'Ninja Sway',link:'Games/Ninjasway/index.html'},
    {icon:'Games/readcoor.png' , title:'Read Color',link:'Games/readcolor/index.html'},

]



const GameDev = () => {
    const isMobile = useMediaQuery('(max-width:600px)');
    const [selectedGameIndex, setSelectedGameIndex] = useState(null);
    const [open, setOpen] = useState(false);
  
    const handleOpen = (index) => {
      setSelectedGameIndex(index);
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
  return (
    <Container  sx={{ mt:3 }}>
         <Grid container spacing={4} columns={16} sx={{display:{ xs: 'flex', md: 'flex' }}}>
                <Grid container spacing={4} columns={16}>
                   <Grid  xs={isMobile? 6:3} display={{xs:'none',md:'flex'}}>
                   <CardMedia
                        component="img"
                        height={300}
                        image="chibi/fig7.jpg"
                        sx={{  
                                        borderRadius:15 ,
                                        borderBlockStyle:'solid', 
                                        boxShadow: '0 5px 10px rgba(0, 0, 0, 0.8)',
                                    }}
                    >
                    </CardMedia>
                   </Grid>
                   <Grid  xs={isMobile?16:12}>
                   <Box sx={{ backgroundColor: 'InfoBackground', borderRadius:6,
                                     boxShadow: '0 5px 10px rgba(0, 0, 0, 0.8)',
                                      }}>
                   <Typography variant='p' sx={{color:"black" ,  fontSize:{xs:15,md:20},ml:5,textAlign:'justify',textShadow: '0px 0px 1px rgba(0, 0, 0, 0.8)'}}>
                        With a passion for game development, I've honed my skills in creating captivating experiences on both Construct 3 and Unity platforms. Through Construct 3, I enjoy the simplicity and rapid prototyping it offers, allowing me to quickly materialize my creative visions into playable games. On the other hand, Unity empowers me to dive into more complex and feature-rich projects, leveraging its robust tools and flexibility to craft immersive worlds and interactive gameplay. Whether it's the intuitive nature of Construct 3 or the versatile capabilities of Unity, I take pride in bringing my game ideas to fruition, catering to a wide range of players and platforms.
                    </Typography>
                    </Box>
                   </Grid>
                </Grid>
           
             <Grid xs={16}  sx={{textAlign:'center', color:'white' }}>
             <Divider   sx={{
                    height: '2px', // Set the thickness as needed
                    marginY: 2, // Adjust the margin as needed
                    backgroundColor: 'white', // Change the background color
                    boxShadow: '0 5px 4px rgba(0, 0, 0, 0.8)',
                    }}> </Divider>
                <Typography variant='h4' sx={{ textShadow: '4px 4px 10px rgba(0, 0, 0, 0.8)'}}>MY GAMES</Typography>
                <Grid container spacing={4} columns={16}>
                    
                   <Grid xs={12}>
                   <Box sx={{ backgroundColor: 'InfoBackground', borderRadius:6,boxShadow: '0 5px 10px rgba(0, 0, 0, 0.8)',}}>
                   <Typography variant='p' sx={{color:"black" , fontSize:18,ml:5,textAlign:'justify',textShadow: '0px 0px 1px rgba(0, 0, 0, 0.8)'}}>
                       
                    While in college, I created and released mobile games. However, some were later removed from Google Play because I didn't update them regularly.Some got over 50K Downloads. Check my -
                    <br />
                    <Link sx={{color:"red", textShadow: '0 0 1px rgba(0, 0, 0, 0.8)'}} href="https://play.google.com/store/apps/dev?id=8678791754616441532&hl=en_US&gl=US&pli=1" target="_blank">
                     Google Play Console.
                </Link>
             <br />
               
                    </Typography>
                    </Box>
                   </Grid>
                   <Grid xs={4}>
                   <CardMedia
                        component="img"
                        height={100}
                        image="chibi/fig3.jpg"
                        sx={{  
                                        borderRadius:15 
                                        ,boxShadow: '0 5px 10px rgba(0, 0, 0, 0.8)',
                                    }}
                    >
                    </CardMedia>
                   </Grid>
                </Grid>
                </Grid>
                 

             {
                mygames.map((mygame,index) =>(
                    <Grid xs={isMobile?16:8} sx={{boxShadow: '0 5px 4px rgba(0, 0, 0, 0.8)',}}>  
                    <Typography variant={isMobile?'h5':'h4'} sx={{textAlign:'center', color:'white' , textShadow: '4px 4px 10px rgba(0, 0, 0, 0.8)'}}>{mygame.title}</Typography>
                    <iframe width="100%" height={isMobile?"300":"500"} 
                                    title="Video Player" src={"https://www.youtube.com/embed/"+mygame.vedioid}>
                    </iframe>
                    <Button onClick={() => handleOpen(index)} sx={{ color: 'white' }}>
            Open Proof
          </Button>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={open}
                        onClick={handleClose}
                    >
                        {selectedGameIndex !== null && (
                            <>
                                {mygames[selectedGameIndex].discription}
                            </>
                            )}
                    </Backdrop>
                </Grid>

                ))
             }
              
             <Grid xs={16}  sx={{textAlign:'center', color:'white' }}>
             <Divider   sx={{
                    height: '2px', // Set the thickness as needed
                    marginY: 2, // Adjust the margin as needed
                    backgroundColor: 'white', 
                    boxShadow: '0 5px 4px rgba(0, 0, 0, 0.8)',
                    }}> </Divider>
                <Typography variant='h4'sx={{ textShadow: '4px 4px 10px rgba(0, 0, 0, 0.8)'}} >Play Some of My HTML Game</Typography></Grid>

             {
                htmlGames.map((htmlgame)=>(
                    <Grid xs={8}>  
                                <Typography variant={isMobile?'h6':'h4'} color={'white'} sx={{textAlign:'center', textShadow: '4px 4px 10px rgba(0, 0, 0, 0.8)'}}> {htmlgame.title}</Typography>
                                <CardMedia
                                     component="img"
                                     height={isMobile?"200":"300"}
                                     width={isMobile?'200':'140'}
                                    image={htmlgame.icon}
                                    title={htmlgame.title}
                                    sx={{   
                                    borderRadius:15 ,
                                    boxShadow: '0 5px 10px rgba(0, 0, 0, 0.8)',
                                }}
                                   
                                />
                              <Link href={htmlgame.link} target='_blank'> 
                              <Typography variant={isMobile?'h6':'h4'}
                                            sx={{color:'white',
                                                textAlign:'center',textShadow: '4px 4px 10px rgba(0, 0, 0, 0.8)'
                                        }}          
                              >PLAY</Typography></Link>
                                    
                    </Grid>

                ))
             }
             <Typography> </Typography>
             <Grid xs={isMobile?16:24} >
             <Divider   sx={{
                    height: '2px', // Set the thickness as needed
                    marginY: 2, // Adjust the margin as needed
                    backgroundColor: 'white', // Change the background color
                    boxShadow: '0 5px 4px rgba(0, 0, 0, 0.8)',
                    }}> </Divider>
            <Grid xs={isMobile?16:10} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography variant={isMobile?'h6':'h4'} color={'white'} sx={{textAlign:'center', textShadow: '4px 4px 10px rgba(0, 0, 0, 0.8)'}}> MY ITCH.IO GAME</Typography>
            <br />
            <Link sx={{color:"white", textShadow: '4px 4px 10px rgba(0, 0, 0, 0.8)'}} href="https://desamparado.itch.io/snake-and-ladder-dash-board" target="_blank">
                     LINK
                </Link>
                
                <iframe title='Game' frameborder="0" 
                src="https://itch.io/embed-upload/4495082?color=520303" 
                allowfullscreen="" 
                width={isMobile?"100%":"100%" }height={isMobile?"350":"640"} >
                    <a href="https://desamparado.itch.io/snake-and-ladder-dash-board" >Play Snake And Ladder : Dash-Board on itch.io</a></iframe>
                </Grid>
             </Grid>
         </Grid>


         
    </Container>
  )
}

export default GameDev
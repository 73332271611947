import React from 'react'
import { myThesisAbstract,myThesisStatment,Client1Abstract,Client1Statement,Client2Abstract,Client2Statements,Client3Abstract, Client3Statement,Client4Abstract,Client4Statements} from './Constants'
import { Typography ,Grid} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
const containerStyle = {
    maxHeight: 500, // Set the maximum height as needed
    overflowY: 'auto',
    padding: '16px',boxShadow: '0 5px 10px rgba(0, 0, 0, 0.8)',
     
  };



const ThesisDiscriptions = () => {
    const isMobile = useMediaQuery('(max-width:600px)');
  return (
        
    <Grid container spacing={1} columns={isMobile?8:16} >
        <Grid xs={8}>
            <Grid style={containerStyle}>
                <Typography variant='h4'>ABSTRACT</Typography>
                <br/>
                <Typography sx={{textAlign:'justify'}}>
                    {myThesisAbstract}
                </Typography>
            </Grid>
        
        </Grid>
        <Grid xs={8}>
            <Grid style={containerStyle}>
                <Typography variant='h4'>PROBLEM STATEMENT</Typography>
                <br/>
                <Typography sx={{textAlign:'justify'}}>
                    {myThesisStatment}
                </Typography>
            </Grid>
        </Grid>
   
    </Grid>
        
   
  )
}



 const Client1= () => { 
    const isMobile = useMediaQuery('(max-width:600px)');
  return (
           
    <Grid container spacing={1}  columns={isMobile?8:16}  >
        <Grid xs={8}>
            <Grid style={containerStyle} >
                <Typography variant='h4'>ABSTRACT</Typography>
                <br></br>
                <Typography sx={{textAlign:'justify'}}>
                    {Client1Abstract}
                </Typography>
            </Grid>
        
        </Grid>
        <Grid xs={8}>
            <Grid style={containerStyle}>
                <Typography variant='h4'>PROBLEM STATEMENT</Typography>
                <br></br>
                <Typography sx={{textAlign:'justify'}}>
                    {Client1Statement}
                </Typography>
            </Grid>
        </Grid>
   
    </Grid>
  )
}
const Client2= () => {
    const isMobile = useMediaQuery('(max-width:600px)');
    return (
             
      <Grid container spacing={1}  columns={isMobile?8:16} >
          <Grid xs={8}>
              <Grid style={containerStyle}>
                  <Typography variant='h4'>ABSTRACT</Typography>
                  <br></br>
                  <Typography sx={{textAlign:'justify'}}>
                      {Client2Abstract}
                  </Typography>
              </Grid>
          
          </Grid>
          <Grid xs={8}>
              <Grid style={containerStyle}>
                  <Typography variant='h4'>PROBLEM STATEMENT</Typography>
                  <br></br>
                  <Typography sx={{textAlign:'justify'}}>
                      {Client2Statements}
                  </Typography>
              </Grid>
          </Grid>
     
      </Grid>
    )
  }

  
const Client3= () => {
    const isMobile = useMediaQuery('(max-width:600px)');
    return (
             
      <Grid container spacing={1} columns={isMobile?8:16}  >
          <Grid xs={8}>
              <Grid style={containerStyle}>
                  <Typography variant='h4'>ABSTRACT</Typography>
                  <br></br>
                  <Typography sx={{textAlign:'justify'}}>
                      {Client3Abstract}
                  </Typography>
              </Grid>
          
          </Grid>
          <Grid xs={8}>
              <Grid style={containerStyle}>
                  <Typography variant='h4'>PROBLEM STATEMENT</Typography>
                  <br></br>
                  <Typography sx={{textAlign:'justify'}}>
                      {Client3Statement}
                  </Typography>
              </Grid>
          </Grid>
     
      </Grid>
    )
  }
  const Client4= () => {
    const isMobile = useMediaQuery('(max-width:600px)');
    return (
             
      <Grid container spacing={1} columns={isMobile?8:16}  >
          <Grid xs={8}>
              <Grid style={containerStyle}>
                  <Typography variant='h4'>ABSTRACT</Typography>
                  <br></br>
                  <Typography sx={{textAlign:'justify'}}>
                      {Client4Abstract}
                  </Typography>
              </Grid>
          
          </Grid>
          <Grid xs={8}>
              <Grid style={containerStyle}>
                  <Typography variant='h4'>PROBLEM STATEMENT</Typography>
                  <br></br>
                  <Typography sx={{textAlign:'justify'}}>
                      {Client4Statements}
                  </Typography>
              </Grid>
          </Grid>
     
      </Grid>
    )
  }

export default ThesisDiscriptions
export {Client1,Client2,Client3,Client4}
const myThesisAbstract= `
This study aims to develop an efficient home automation outlet capable of 
controlling, automating, protecting, and monitoring home appliances through 
browser-based applications. Specifically, the researchers aim to develop an 
intelligently measure the energy usage of appliances; integrate short-circuit 
protection; create a mesh network architecture; design a user-friendly interface;
and integrate access and station point modes. The prototype and development 
phase involves the implementation of both hardware and software components, 
ensuring a robust and user-friendly solution. The tests reveal the outlet's capability 
in effectively controlling, automating, protecting, and monitoring household 
appliances by subjecting the outlet to a comprehensive array of simulated 
scenarios and real-world usage, we can thoroughly assess its performance and 
reliability. Simulated scenarios allow us to create controlled environments to 
evaluate the outlet's responses under various conditions, ensuring that it meets 
specific criteria for control, automation, protection, and monitoring. The 
development of this efficient home automation outlet marks a significant step 
towards advanced home automation systems. The outlet's potential impact on the 
field is substantial, offering users a versatile and reliable solution for smart home 
management. Future work will focus on refining the outlet's features and exploring 
additional applications to further enhance its capabilities.
  `;

const myThesisStatment=`
Operating an appliance that employs smart-based home automation is 
costly and may require an internet to operate. This results in reducing or 
sabotaging human endeavors to make life more bearable and effective.

To address the identified gap in the problem statement, the goal of this study 
is to create a device capable of overseeing, regulating, safeguarding, and 
monitoring household appliances through any web-based application accessible 
without an internet connection. Specifically, this study aims to: 
i. develop an intelligent outlet from the existing socket that can measure 
the energy usage by the electronic appliances linked to the device;
ii. integrate short circuit protection on the device to prevent electrical fire 
and to quickly check on the appliances having a short circuit;
iii. create a mesh network connection that can work as a Wi-Fi access point 
and station point mode to control and monitor home appliances locally, 
and can remotely control the appliance via the internet; and,
iv. design a user-fr Objectives iendly interface for controlling and 
monitoring the device so the user can schedule a task in turning on/off 
a particular electrical appliance connected to the device.

`;
const Client1Abstract=`
The aim of this study is to develop and design a monitoring and protection 
system for single-phase oil-immersed distribution transformers. This system 
utilizes sensors and GSM technology and be based on the Arduino microcontroller. 
The system is capable of real-time data collection and error detection. The 
transformer temperature, transformer oil, current, and voltage are monitored 
continuously through sensors, which are integrated with the GSM interface for 
seamless two-way communication. In addition, the system proactively alert the 
user when the load of the GSM is due to expire in one day, ensuring timely action 
can be taken. The system provides real-time data on a display, while the 
microcontroller evaluates the signal and transmits it to the real-time monitoring 
system. In the event of a fault, an SMS alert is sent to the end user via GSM, and 
the transformer is isolated by shutting it down to prevent any further damage. The 
results of this study indicate that the system can effectively protect the transformer 
from electrical disturbances.

`;

const Client1Statement=`
This research aims to gather data in real time and detect faults in 
transformers through a system that can send alerts and warnings to a 
mobile/cellular phone. The data collected from the system can prompt an 
automatic shutdown of the transformer if deemed necessary. Moreover, to 
accomplish this, the study seeks to: 

i. design and develop an Arduino-based monitoring and protection system for 
single-phase transformers; 
ii. read and measure the actual load current, temperature, voltage, and oil 
level of the transformer using sensors; 
iii. utilize GSM technology to provide real-time alerts and remote access to the 
monitoring system; and, 
iv. incorporate a protection mechanism to automatically disconnect the 
transformer from the power source in case of severe faults. 
`;

const Client2Abstract=`This research introduces an innovative solution to address the critical
challenges posed by short circuits and overloads in distribution systems. The
project centers on the development of an Arduino-based smart monitoring
system designed to detect and pinpoint electrical faults in real time, offering
utilities immediate insights for proactive intervention. The system, anchored by
Arduino Uno and PZEM sensors, monitors current readings, identifies anomalies,
and communicates detected issues to the distribution utility. Three key
measurements—Short Circuit, Measure Overload, and Open Circuit—are
meticulously recorded for subsequent analysis, ensuring a comprehensive
assessment of the prototype's accuracy and reliability. While the developed
monitoring system demonstrates practicality and promise in enhancing safety
and preventing equipment damage, ongoing research is essential to address
existing limitations. This project contributes to the advancement of smart
monitoring systems, fostering resilience and efficiency within distribution
systems`;

const Client2Statements=`The general objective of this study is to develop a short circuit and
overload detector with an Arduino-based smart monitoring system. The general
objective is narrowed down into specific objectives:
I. To design and develop an Arduino-based smart monitoring system
specifically for detecting and monitoring short circuits and overloads in power
distribution lines at CIT- University.
3
II. To evaluate the performance of the developed Arduino-based smart
monitoring system in terms of response time, ensuring prompt detection and
timely alerts for short circuits and overloads.
III. To evaluate the accuracy and reliability of the developed smart
monitoring system in detecting and locating specific electrical faults, with a
particular focus on short circuits and overloads in power distribution lines at CITUniversity`;

const Client3Abstract=`In the new era of globalization, the increasing volume of generated waste continues to be a pressing issue being faced around the world. By 2050, it is projected that the world will accumulate a stunning 3.40 billion tons of waste annually, marking a significant increase from today’s 2.01 billion tons. One of the primary reasons is due to the reluctance of men to participate and practice waste sorting, as well as the lack of knowledge about this prevailing issue. In response to this, countries have started implementing some systems to recycle waste. One of which is the use of artificial intelligence to recycle waste by classification. This study aims to develop a prototype that accurately classifies recyclable domestic solid wastes using deep learning artificial intelligence. The data revealed that the current deep learning model employed in the prototype performs very well in classifying the four categories of recyclable domestic solid wastes. With an accuracy of more than 70%, which is the standard, it is evident that the accuracy shown by the prototype in classifying shows promising results. `;
const Client3Statement=`The main problem of this study is to develop a prototype that accurately classifies recyclable domestic solid wastes using deep learning artificial intelligence.
Specifically, it aims to answer the following questions:
What accuracy percentage can the prototype achieve in the following wastes; paper waste, plastic waste, glass waste, and metal waste?
Does the mean percentage of each category fit with the standard accuracy score in machine learning?
`;
const Client4Abstract=`This study focuses on addressing the challenge of collecting fog water in arid and semiarid regions by designing a more efficient and wind-resistant fog water collector. A cylindrical 
collector equipped with a polypropylene mesh was developed and compared to a traditional 
square collector over a 21-day period study in Barangay Taptap, Cebu City, Philippines. The 
research aimed to assess the efficiency and water collection rates of both collectors in varying 
meteorological conditions. The findings reveal that weather conditions have a limited impact 
on fog water output, with ideal conditions being low temperatures, high humidity, and 
moderate wind. However, these conditions explain only around 50% of the fog water collected, 
indicating the presence of other factors in contributing to the output. Factors such as losses and 
the unique design of the cylindrical fog collector significantly influence water collection 
compared to square fog collectors. Despite the challenges, the cylindrical fog water collector 
outperformed the square collector, with the former achieving a maximum fog water collection 
of 7.65 liters at hour 248 compared to the square collector’s 0.09 liters on the same day. 
Minimum fog water collectors occurred at hour 119, with the square collector at 0.06 liters and 
the cylindrical collector at 0.18 liters. The study emphasized the influence of wind on fog water 
collection, highlighting ideal conditions with wind speeds around 2 m/s, high relative humidity, 
and a low dry bulb temperature. However, it also demonstrated that meteorological conditions 
alone do not dictate fog water output. The unique three-dimensional design of the cylindrical 
fog collector, allowing fog intake from any wind direction, significantly enhances water 
collection compared to square fog collectors`;
const Client4Statements= `The study aimed to:
1. build a cylindrical fog water harvester and a square fog collector at Bayer’s Café, 
Taptap, Cebu City,
2. visually compare the durability of the polypropylene meshes with respect to their 
structure,
3. analyze the water output efficiency and rate of water collected of the cylindrical
fog collector with respect to the meteorological conditions, and
4. compare the rate of water collected by the cylindrical fog collector (CFC) to the 
square fog collector (SFC).
`;
export {myThesisAbstract , myThesisStatment,Client1Abstract,Client1Statement,Client2Abstract,Client2Statements,Client3Abstract,Client3Statement,Client4Abstract,Client4Statements};
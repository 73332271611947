import React from 'react'
import Grid from '@mui/material/Unstable_Grid2'
import {  Container, Typography } from '@mui/material'
import CardMedia from '@mui/material/CardMedia';
import { Link } from 'react-router-dom';
//cards
import useMediaQuery from '@mui/material/useMediaQuery';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
const MyWorks=[
    {works:'Profile',discription:'Check My CV Here!'},
    {works:'Engineering  Projects',discription:'A Collection of my College Arduino And \n  ESP32 Projects.'},
    {works:'Game Development',discription:'Collection of Games I personally Made.\n Some are on playstore And Itchio.'},
    
   
];
const Home = () => {
  const isMobile = useMediaQuery('(max-width:480px)');
  return (
        
        <Container sx={{ mt:3}}>
          <Grid container spacing={1} columns={{ xs: 4, md: 16 }} sx={{ display: { xs: 'flex', md: 'flex' } }}>
          <Grid xs={isMobile ? 8 : 16} sx={{ position: 'relative' }}>
  <CardMedia
    component="img"
    height="400"
    image="home/CV7.jpg"
    alt="Paella dish"
    sx={{
      borderRadius: 5,
      boxShadow: '0 5px 10px rgba(0, 0, 0, 0.8)',
    }}
    style={{ objectPosition: 'left' }}
  />
  <Typography
    display={{ xs: 'flex', md: 'none' }}
    variant="p"
    component="div"
    sx={{
      position: 'absolute',
      top: '20%', // Adjusted percentage value relative to the grid
      left: '33%', // Adjusted percentage value relative to the grid
      transform: 'translate(-50%, -50%)',
      color: 'white',
      zIndex: 1,
      fontFamily: 'unset',
      fontSize: '11vw',
      textShadow: '5px 5px 10px rgba(0, 0, 0, 0.8)',
    }}
  >
    Hi,<br /> I'm Marky
  </Typography>
  <Typography
    display={{ xs: 'flex', md: 'none' }}
    variant="p"
    component="div"
    sx={{
      position: 'absolute',
      top: '45%', // Adjusted percentage value relative to the grid
      left: '30%', // Adjusted percentage value relative to the grid
      transform: 'translate(-50%, -50%)',
      color: 'white',
      zIndex: 1,
      fontFamily: 'unset',
      fontSize: '3vw',
      textShadow: '5px 5px 10px rgba(0, 0, 0, 0.8)',
    }}
  >
    BS in Electrical Engineering and
    <br />
    a self-taught programmer
  </Typography>
  <Typography
    display={{ xs: 'none', md: 'flex' }}
    variant="p"
    component="div"
    sx={{
      position: 'absolute',
      top: '25%', // Adjusted percentage value relative to the grid
      left: '18%', // Adjusted percentage value relative to the grid
      transform: 'translate(-50%, -50%)',
      color: 'white',
      zIndex: 1,
      fontFamily: 'unset',
      fontSize: '5vw',
      textShadow: '5px 5px 10px rgba(0, 0, 0, 0.8)',
    }}
  >
    Hi,<br /> I'm Marky
  </Typography>
  <Typography
    display={{ xs: 'none', md: 'flex' }}
    variant="p"
    component="div"
    sx={{
      position: 'absolute',
      top: '60%', // Adjusted percentage value relative to the grid
      left: '20%', // Adjusted percentage value relative to the grid
      transform: 'translate(-50%, -50%)',
      color: 'white',
      zIndex: 1,
      fontFamily: 'unset',
      fontSize: '1.5vw',
      textShadow: '5px 5px 10px rgba(0, 0, 0, 0.8)',
    }}
  >
    BS in Electrical Engineering and
    <br />
    a self-taught programmer
  </Typography>
</Grid>

    </Grid>
           <Grid container columns={{xs: 2, md:6}} sx={{display:{ xs: 'flex',md:'flex' }}}>
                    {MyWorks.map((mywork)=>(
                                        <Grid xs={isMobile?6:2} key={mywork.index} container alignItems="center" justifyContent="center" >
                                            <Card  sx={{
                                            display:'flex',
                                            flexDirection: 'column',
                                            minWidth: { xs: '100%', md: "96%" },
                                            minHeight: 200,
                                            mt: 1,
                                            backgroundColor: 'rgba(0,0,0,0.5)',paddingTop:2,
                                           
                                         
                                            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.3)', 
                                            }}>
                                                <CardContent >
                                                    <Typography sx={{ fontSize: 20 ,textShadow: '4px 4px 10px rgba(0, 0, 0, 0.8)'}} color={'white'}  gutterBottom>
                                                    {mywork.works}
                                                    </Typography>
                                                    <Divider variant="fullWidth" sx={{backgroundColor:'white'}}/>
                                                    <Typography sx={{ mt: 1}} variant="body1" color={'white'} style={{ whiteSpace: 'pre-line'  , flex: 1}}>
                                                        {mywork.discription}
                                                    </Typography>
                                                </CardContent >
                                                <CardActions  sx={{ mt: 'auto' ,backgroundColor:'rgba(61,26,190,0.7)'}}>
                                                    <Button sx={{ color: 'white' }} size="medium" component={Link} to={"/"+mywork.works}>Learn More</Button>
                                                </CardActions>
                                                </Card>
                                        </Grid>     
                                        
                            ))} 
                </Grid>
                <br />
                <Divider   sx={{
                    height: '2px', // Set the thickness as needed
                    marginY: 2, // Adjust the margin as needed
                    backgroundColor: 'white', // Change the background color
                    }}>  </Divider>
               <Grid xs={16}>
                    
                    <Typography  sx={{ fontSize: 20,textShadow: '4px 4px 10px rgba(0, 0, 0, 0.8)' }} color={'white'}>
                            3D Development:
                    </Typography>
                    <br />
                   <Grid  xs={8} >
                    
                    <CardMedia
                          component="img"
                          height={isMobile?300:500}
                         
                          image="gif/3D.gif"
                          sx={{   border:'black',
                                          borderRadius:15 ,
                                         
                                          borderWidth:10, boxShadow: '0 5px 10px rgba(0, 0, 0, 0.8)', 
                                      }}
                      >
                      </CardMedia>
                   </Grid>

               </Grid>
               
             
               <Grid xs={16}>
               <br />  <br />
                    <Typography  sx={{ fontSize: 20,textShadow: '4px 4px 10px rgba(0, 0, 0, 0.8)' }} color={'white'}>
                            Electrical Design:
                    </Typography>
                    <br />

                    <CardMedia
                        component="img"
                        height={isMobile?300:400}
                        image="gif/EE.gif"
                        sx={{   border:'black',
                                        borderRadius:15 ,
                                        
                                        borderWidth:10, boxShadow: '0 5px 10px rgba(0, 0, 0, 0.8)', 
                                    }}
                    >
                    </CardMedia>

               </Grid>
              
            
           


        </Container>

        
        
  )
}

export default Home